.detail__header {
  border-right: 1px solid #dcdde0;
  border-left: 1px solid #dcdde0;
  border-bottom: 1px solid #006ee8;
  border-top: 2px solid #dcdde0;
  border-radius: 3px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  margin-bottom: 15px;
  
}

.detail__header__container {
  padding: 28px 45px;
  margin-bottom: 15px;
}
.detail__header__container__a {
  padding: 28px 45px;
  margin-bottom: 15px;
}

.detail__container a {
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  align-items: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #333333;
  margin-bottom: 35px;
  width: 15px;
  height: 14.94px;
}

.detail__container img {
  margin-right: 10px;
}

.detail__container h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
}

.detail__arrow_blue {
  display: flex;
  justify-content: flex-start;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  /* or 162% */
  color: #0569d8;
}

.detail__arrow_blue img {
  margin-right: 11px;
  width: 70px;
}

.details__share {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
}

.details__share span {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-left: 11.27px;
}

.details__share .share__links i {
  color: #333333;
  margin-right: 20px;
}

.detail__header__container__alt {
  padding: 0 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.detail__header__container__alt hr {
  height: 45px;
  width: 5px;
  border-width: 0;
  margin-right: 15px;
  color: #000;
  background-color: #0569d8;
}

.detail__header__container__alt label {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

.detail__header__container__alt_block {
  padding: 0 175px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.detail__header__container__alt_block p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.detail__header__container__alt_block .detail__tags {
  background: #ddedfe;
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #0569d8;
  padding: 5px 10px;
  margin-right: 15px;
}

@media (max-width: 500px) {

  .detail__header {
    height: 800px;
    
  }

  .detail__header__container {
    padding: 15px 23px;
  }
  .detail__container a {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .detail__container h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }

  .detail__arrow_blue {
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
  }

  .detail__arrow_blue img {
    width: 30px;
  }

  .details__share span {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-right: 25px;
  }

  .detail__header__container__a {
    padding: 0px;
  }

  .detail__header__container__a .details__share {
    padding: 28px 0px;
    margin-bottom: 0;
  }

  .detail__header__container__alt {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    justify-content: flex-start;
    padding: 0 15px ;
  }

  .detail__header__container__alt_block {
    padding: 15px;
    margin-bottom: 25px;
  }
  .detail__header__container__alt_block .detail__tags{
    margin-left: 5px;
  }


  .detail__header__container__alt hr {
    height: 41px;
    margin-right: 5px;
  }
}

