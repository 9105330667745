section {
  border-bottom: 2px solid #006fe8;
  padding-bottom: 53px;
}

section.notifications {
  background: #f7f7f7;
  padding-bottom: 5px;
  height: 100vh;
}

.header__menu-mobile {
  display: none;
}

.notifications {
  display: block;
  text-align: right;
  margin: 0 auto;
  padding: 20px 20px 40px 20px;
  width: 100%;
}

.content__info_premium {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-weight: 500;
  font-size: 45px;
  line-height: 45px;
}

.content__info_premium img {
  margin-right: 15px;
}

.premium__banner {
  margin-top: 122px;
  top: 422px;
  background-color: #cce4fe;
  height: 400px;
  justify-content: center;
}

.premium__header {
  margin-top: -72px;
  width: 500px;
  height: 430px;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  padding-top: 40px;
  margin-bottom: 15px;
  background: #ffffff;
  border: 2px solid #006fe8;
  box-shadow: 2px 10px 8px rgba(0, 0, 0, 0.3);
  border-radius: 9px;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #06152e;
  font-family: "Nunito Sans";
  font-style: normal;
}

.premium__header h2 {
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  color: #06152e;
  font-family: "Nunito Sans";
  font-style: normal;
}

.premium__header h4 {
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  gap: 10px;
}

.premium__header button {
  border: 1px solid #006fe8;
  border-radius: 5px;
  width: 210px;
  height: 50px;
  background-color: transparent;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #006fe8;
  margin: 15px 0;

}

.premium__header_box {
  display: flex;
  justify-content: center;
  
}

.premium__header_box h2 {
  margin-right: 5px;
  font-size: 25px;
  font-weight: 700;
}

.premium__list_item ul {
  text-align: left;
  list-style: none;
  text-decoration: none;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #06152e;
}

.premium__list_item li {
  margin-bottom: 15px;
  line-height: 22px;
}

.premium__list_item img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

@media (max-width: 500px) {
  section.notifications {
    height: auto;
  }

  .header__menu-mobile {
    display: flex;
    margin-bottom: 40px;
  }

  .premium__container {
    border-top: 2px solid #006fe8;
  }

 

  .premium__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .premium__header {
    padding: 50px 20px;
    width: 270px;
    margin-top: -115px;
    font-size: 22px;
    height: 450px;
  }

  .premium__header h4 {
    font-size: 20px;
  }

  .premium__header button {
    width: 230px;
    height: 40px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 18px;
  }

  .premium__header ul {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
  }

  .premium__header_box {
    display: block;
    justify-content: center;
  }
  .premium__header_box h2 {
    line-height: 20px;
    font-size: 22px;
  }

  .premium__list_item img {
    width: 20px;
    height: 20px;
    margin-left: -35px;
  }
}


/* New Premium */
.StripeElement {
  margin: 0 43px;
  border: 1px solid #006fe8;
  border-radius: 5px;
  height: 50px;
  color: #06152e;
  padding: 15px;
}

