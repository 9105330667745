@charset "utf-8";
@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");

/* CSS Document */

/*--------------------------------------------------------------- GENERAL ---------------------------------------------------------------*/

body,
* {
  font-family: "Nunito Sans", sans-serif;
  color: #05142f;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #021431;
}

h1.bold,
h2.bold,
h3.bold,
h4.bold,
h5.bold,
h6.bold {
  font-weight: 800;
}

h1 {
  font-size: 30px;
}

h1.highlighted,
h2.highlighted,
h3.highlighted {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

h1.highlighted > span,
h2.highlighted > span,
h3.highlighted > span {
  color: #006ee8;
  background: #dcedfe;
  padding: 0 4px;
  margin-right: 5px;
}

.body__text hr {
  border-top: 3px solid #006ee8;
  margin: 50px 0;
}

article h2 > a,
article h2 > a:hover {
  color: #021431;
  text-decoration: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  article {
    flex: 1 1 auto !important;
  }
}

header {
  -webkit-box-shadow: 0px 2px 4px 2px rgba(2, 20, 49, 0.26);
  -moz-box-shadow: 0px 2px 4px 2px rgba(2, 20, 49, 0.26);
  box-shadow: 0px 2px 4px 2px rgba(2, 20, 49, 0.26);
  z-index: 5;
  position: fixed;
  width: 100%;
  top: 0;
}

main {
  margin-top: 68px;
}

.main__content h1 > a {
  color: #05142f;
  text-decoration: none;
  font-weight: 700;
  font-size: 38px;
  line-height: 45px;
}

section {
  padding: 30px 0;
}

section.no-bg {
  background: none !important;
}

.container {
  max-width: 1250px;
  height: 100%;
  padding: 0 3vw;
  margin: 0 auto;
}

section .category__title {
  margin-bottom: 20px;
  font-weight: 800;
  border-bottom: 1px solid #021431;
}

section.category__name__box {
  background: #006ee8;
  padding: 0;
}

section.category__name__box h3 {
  color: #ffffff;
  font-size: 23px;
  line-height: 26px;
  margin: 0;
  height: 52px;
  padding: 13px 0;
}

.big__column__articles {
  padding-left: 0;
}

section.three__columns .medium__column__articles {
  border-right: 1px solid #e7e7e7;
  padding: 0 30px;
}

section.three__columns .medium__column__articles:first-child {
  border-right: 1px solid #e7e7e7;
  padding: 0 30px 0 0;
}

section.three__columns .medium__column__articles:last-child {
  border-right: none;
  padding: 0 0 0 30px;
}

img {
  width: initial;
  max-width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img {
    max-width: 100%;
  }

  figure img {
    width: 100%;
  }
}

a {
  color: #006ee8;
  text-decoration: none;
}

a.secondary__hyper {
  color: #05142f;
}

a.secondary__hyper:hover {
  color: #006ee8;
}

a.secondary__hyper.underline {
  text-decoration: underline;
}

.header__primary.row,
.header__secondary.row {
  margin: 0;
}

.header__primary {
  padding: 0;
}

.header__secondary {
  align-items: center;
  padding: 0;
}

a.menu__hyper {
  font-size: 14px;
  /*color: #05142F;
     text-decoration: none;
     font-weight: 600;
     display: inline-block;
     -webkit-transition: all .3s ease-out;
     -moz-transition: all .3s ease-out;
     -o-transition: all .3s ease-out;
     transition: all .3s ease-out;
    */
  padding: 5px 15px;
  color: #ffffff;
  background: #006ee8 !important;
  border: 1px solid #006ee8;
}

a.menu__hyper:hover {
  color: #ffffff;
  background: #006ee8;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar {
  height: 68px;
  background: #ffffff !important;
}

nav > ul {
  display: flex;
  list-style: none;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

nav > ul > li {
  padding-right: 5px;
  display: block;
  height: 55px;
  top: 4px;
  position: relative;
}

nav > ul > li > a {
  font-size: 14px;
  color: #05142f;
  text-decoration: none !important;
  font-weight: 600;
  display: inline-block;
  padding: 12px 20px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

nav > ul > li:hover > a,
nav > ul > li > a.current {
  color: #006ee8;
}

nav > ul > li > a > svg {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin-left: 10px;
}

nav > ul > li:hover > a > svg > .icon,
nav > ul > li > a.current > svg > .icon {
  fill: #006ee8;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

nav > ul > li > a:after,
ul.dropdown-content a:hover:after,
ul.dropdown-content a:after,
ul.lista__user a:after {
  content: "";
  display: block;
  width: 0;
  border-bottom: 2px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

nav > ul > li > a.current:after,
nav > ul > li > a:hover:after,
ul.dropdown-content a:hover:after,
ul.lista__user a:hover:after {
  content: "";
  width: 100%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.big__menu__tittle {
  background: url("../_images/big-title-bg.svg") no-repeat;
  font-size: 19px;
  color: #ffffff;
  font-weight: 700;
  padding: 20px 0 20px 15px;
  background-position: center 12px;
}

ul.dropdown-content {
  position: absolute;
  background: #ffffff;
  -webkit-box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
  -moz-box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
  box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
  padding: 10px 25px 25px 25px;
  list-style: none;
  width: 270px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  top: 56px;
  z-index: 2;
}

li.main__dropdown:hover > ul.dropdown-content {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

ul.dropdown-content > li {
  display: table;
}

ul.dropdown-content a,
ul.lista__user a {
  color: #05142f;
  font-size: 15px;
  text-decoration: none;
  padding: 15px 0 0px 0;
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

ul.dropdown-content a:hover,
ul.lista__user a:hover {
  color: #007aff;
}
ul.lista__user a.no-border:hover:after {
  display: none;
}

input:focus,
textarea:focus,
button:focus,
a:focus {
  box-shadow: none;
  outline: none;
}

button.search__btn {
  background: none;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin-right: 0;
}

button.search__btn:hover {
  opacity: 0.6;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.btn {
  border-radius: 0 !important;
  text-decoration: none;
}

.btn-primary {
  font-size: 18px;
  font-weight: 700;
  padding: 14px 0 12px 0;
  display: block;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
}

.btn-primary {
  background: #006ee8 !important;
  border: 1px solid #006ee8;
}

.btn-primary:hover {
  background: #005fc6 !important;
  border: 1px solid #006ee8;
  color: #ffffff;
}

.btn-primary.disabled,
.btn-primary:disabled {
  border-radius: 0 !important;
  background: #006ee8 !important;
  border: 1px solid #006ee8 !important;
  color: #ffffff !important;
}

.btn-primary:disabled {
  opacity: 0.3;
}
.btn-outline-primary {
  font-size: 18px;
  font-weight: 700;
  padding: 14px 0 12px 0;
  display: block;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
}

.btn-outline-primary {
  border: 1px solid #006ee8;
}

.btn-outline-primary:hover {
  background: #005fc6 !important;
  border: 1px solid #006ee8;
  color: #ffffff;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  border-radius: 0 !important;
  background: #006ee8 !important;
  border: 1px solid #006ee8 !important;
  color: #ffffff !important;
}

.btn-outline-primary:disabled {
  opacity: 0.3;
}

.btn-secondary {
  background: #f5f9ff;
  border: 1px solid #021431;
  color: #021431;
}

.btn-complement {
  background: #f5f9ff !important;
  border: 1px solid #006ee8;
  color: #006ee8;
}

.btn-secondary:hover,
.btn-complement:hover {
  background: #006ee8 !important;
  border: 1px solid #006ee8;
  color: #ffffff;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  background: #ebf4ff !important;
  border: 1px solid #006ee8 !important;
  color: #006ee8 !important;
  border-color: #006ee8 !important;
}

.sign__header {
  font-weight: 400;
  width: 90px;
  font-size: 15px;
  margin: 0 4px;
  height: 30px;
  padding: 3px;
  background: #006ee8 !important;
  border: 1px solid #006ee8;
  color: #ffffff !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.sign__header:hover {
  background: #3090f9 !important;
  border: 1px solid #3090f9;
  color: #ffffff;
}

.login__header {
  font-weight: 400;
  width: 90px;
  font-size: 15px;
  margin: 0 4px;
  height: 30px;
  padding: 3px;
  background: #ffffff !important;
  border: 1px solid #021431;
  color: #021431;
}

.login__header:hover {
  border: 1px solid #006ee8;
  color: #006ee8;
  background: #ffffff !important;
}

.modal-content button.btn-block {
  width: 100%;
  max-width: 400px;
  padding: 8px;
  font-weight: 700;
}

.modal h1 {
  font-weight: 800;
}

a.profile__btn {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: block;
  background-size: cover;
  margin-left: 6px;
  margin-right: 15px;
  overflow: hidden;
}

a.profile__btn.user {
  background-size: cover;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 0;
  width: 50px;
  height: 50px;
  border: 3px solid #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  overflow: hidden;
}

a.profile__btn.user.girl {
  background: url(../_images/ico-profile-3.svg) no-repeat;
  background-size: contain;
}

a.profile__btn.user.guy2 {
  background: url(../_images/ico-profile-2.svg) no-repeat;
  background-size: contain;
}

a.profile__btn.user.guy3 {
  background: url(../_images/ico-profile-4.svg) no-repeat;
  background-size: contain;
}

a.profile__btn.user:hover {
  border: 3px solid #021431;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.profile__btn__content:hover a.profile__btn:after {
  content: "";
  width: 100%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.dropdown .dropdown-list {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin-left: -240px;
  position: absolute;
  background: #ffffff;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(16, 29, 49, 0.38);
  -moz-box-shadow: 0px 3px 10px 0px rgba(16, 29, 49, 0.38);
  box-shadow: 0px 3px 10px 0px rgba(16, 29, 49, 0.38);
  padding: 15px 25px;
  list-style: none;
  width: 293px;
  z-index: 2;
}

.dropdown:hover .dropdown-list {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

ul.dropdown-list > li {
  display: table;
}

ul.dropdown-list:before {
  content: "";
  display: block;
  width: 22px;
  position: absolute;
  top: -12px;
  right: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #ffffff;
  margin: 0 18px;
}

ul.lista__user.dropdown-list {
  margin-top: 12px;
  margin-left: -240px;
}

.logged,
.logout {
  display: none;
}

span.notification__counter {
  font-size: 11px;
  font-weight: 700;
  color: #ffffff;
  background: #ef2b2b;
  padding: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: block;
  float: right;
  right: -10px;
  position: relative;
  line-height: 11px;
  text-align: center;
}

.user__image {
  width: 54px;
  height: 54px;
  overflow: hidden;
  border-radius: 50%;
}

.user__image > img {
  width: 100%;
}

.user__info__container {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

a.logout__btn {
  font-weight: 700;
}

ul.lista__user.dropdown-list span.notification__counter {
  left: 5px;
}

span.user__name {
  font-size: 17px;
  font-weight: 700;
  line-height: 17px;
  display: block;
  position: relative;
  margin-bottom: 3px;
}

span.user__profile {
  color: #646769;
  font-size: 15px;
  line-height: 15px;
  display: block;
  margin-bottom: 3px;
}

.user__details {
  margin-top: 2px;
  padding-left: 10px;
}

.user__details > a.edit__profile {
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: none;
  color: #006ee8;
  text-decoration: underline;
}

.user__details > a.edit__profile:hover {
  background: none;
  color: #05142f;
}

.user__details > a.edit__profile:hover::after {
  width: 0 !important;
}

.no__floating {
  position: relative !important;
  clear: both !important;
  float: none !important;
}

.modal-dialog.modal-lg {
  max-width: 85vw;
}

.modal-content {
  background: none; /* KM: Shoud be 'none' in website. */
  box-shadow: none;
  border-radius: 0;
  border: none;
  -webkit-box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
  -moz-box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
  box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
  /* min-height: 80vh; */
}

.modal-dialog > .modal-content {
  background: #ffffff;
  box-shadow: -6px 6px 10px 0 rgba(16, 29, 49, 0.54);
}

.modal > .modal-dialog > .modal-content {
  background: none;
  box-shadow: none;
}

.modal-dialog > .modal-content > .modal-dialog > .modal-content {
  background: #ffffff;
  box-shadow: -6px 6px 10px 0 rgba(16, 29, 49, 0.54);
}

.modal-content > div {
  margin: 0;
  /* min-height: 80vh; */
}

.modal.fullsize > .modal-dialog {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  margin: 0;
}

.modal.fullsize > .modal-dialog .modal-content,
.modal.fullsize > .modal-dialog .modal-content > div {
  width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
}

.modal-content.auto {
  min-height: auto;
}

.modal-content.auto > div {
  min-height: auto;
}

button.close {
  width: auto;
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 40px;
  font-weight: 300;
  opacity: 1;
  color: #021431;
  background-color: transparent;
  text-shadow: none;
  z-index: 3;
}

.password__toggle {
  display: block;
  width: 50px;
  height: 30px;
  float: right;
  top: 12px;
  position: absolute;
  right: 0;
}

.password__toggle > a.enable {
  background: url("../_images/ico-eye.svg") no-repeat;
  display: block;
  width: 100%;
  height: 100%;
  background-position: 95% center;
  cursor: pointer;
}

.password__toggle > a.disable {
  background: url("../_images/ico-eye-slash.svg") no-repeat;
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
}

.password__content small {
  font-size: 11px;
}

.form-signin .checkbox {
  font-size: 14px;
}

.form-signin .checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.form-label-group small {
  font-size: 12px;
  line-height: 16px;
  color: #d0021b !important;
}

.profile__image__content.error small:first-of-type,
.form-label-group.error small:first-of-type,
.form__nofloating.error small:first-of-type {
  color: #d0021b !important;
}

.form-signin .checkbox.error span.check-mark {
  border: 1px solid #d0021b;
}

.form-signin .checkbox.error small {
  color: #d0021b !important;
}

/* Floating labels */

.form-signin {
  width: 100%;
  margin: 20px auto 0 auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label,
.form-label-group > select {
  height: 2.8rem;
  padding: 0.75rem 0 0 0;
  border-radius: 0;
  border-bottom: 1px solid #a1a1a1;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 15px;
}

.form-label-group > input:focus,
.form-label-group > label:focus,
.form-label-group > select:focus,
.form-label-group > textarea:focus {
  box-shadow: none;
  border-bottom: 2px solid #006ee8;
}

.form-label-group > input,
.form-label-group > label,
.form-label-group > select {
  height: 2.8rem;
  padding: 0.75rem 0 0 0;
  border-radius: 0;
  border-bottom: 1px solid #a1a1a1;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 16px;
}

.form-label-group.error > input,
.form-label-group.error > select {
  border-bottom: 1px solid #d0021b;
}

.form-label-group > select {
  -webkit-appearance: none;
  background: url(../_images/ico-dropdown-select.svg) no-repeat 98% center;
}

.form-label-group > textarea {
  height: 5rem;
  padding: 0;
  border-radius: 0;
  border-bottom: 1px solid #a1a1a1;
  border-top: none;
  border-left: none;
  border-right: none;
  resize: none;
  font-size: 14px;
  line-height: 19px;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.3rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0;
  padding-bottom: 0.25rem;
  font-size: 13px;
  color: #006ee8;
  font-weight: 600;
}

.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group input:-webkit-autofill ~ label,
.form-label-group input:-webkit-autofill:hover ~ label,
.form-label-group input:-webkit-autofill:focus ~ label {
  padding-top: 0;
  padding-bottom: 0.25rem;
  font-size: 13px;
  color: #006ee8;
  font-weight: 600;
  background: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* Fallback for Edge-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE-------------------------------------------------- */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }

  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}

.mobile,
.copyright__mobile,
.mobile__cat {
  display: none !important;
}

.desktop {
  display: block;
}

/*--------------------------------------------------------------- LOADER ---------------------------------------------------------------*/

.opacy{
  opacity: 0.3;
}

.lds-dual-ring {
  display: inline-block;
  width: 100%;
  height: 40px;
  justify-content: center;
}

.lds-dual-ring.small {
  display: inline-block;
  width: 100%;
  height: 18px;
  justify-content: center;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 35px;
  height: 35px;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid #05142f;
  border-color: #05142f transparent #05142f transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.small:after {
  content: " ";
  display: block;
  width: 18px;
  height: 18px;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid #05142f;
  border-color: #05142f transparent #05142f transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------- NAV TABS ---------------------------------------------------------------*/

.tabs__container {
  overflow: hidden;
  /* no selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;
  margin-bottom: 20px;
}

.nav-tabs {
  width: 9999999px;
  white-space: nowrap;
  /* all on same line */
  font-size: 0;
  /* fix inline block spacing */
}

.tabs__container > .nav-tabs {
  border: none;
  border-bottom: 1px solid #d2d2d2;
}

.draggable.nav > li > a {
  font-size: 14px;
  color: #586472; 
  padding: 0;
  display: block;
  text-decoration: none;
}
.groups__tabs .draggable.nav > li > a{
  padding: 10px 20px !important; 
}

.draggable.nav.subnav > li > a {
  padding: 10px 20px;
}

.draggable.nav > li > a:hover {
  color: #05142f;
}

.draggable.nav > li.active > a {
  color: #05142f;
  font-weight: 700;
}

.draggable.nav > li.active:after,
.draggable.nav > li:hover:after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: 1px solid #021431;
  position: relative;
  bottom: 0;
  right: 0;
  top: 1px;
}

.profile__menu .draggable.nav > li.active:after,
.profile__menu .draggable.nav > li:hover:after {
  display: none;
}

@media (max-width: 769px) {
  .tabs__container {
    margin-bottom: 20px;
  }

  .sticky.change {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #ffffff;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(210, 210, 210, 0.5);
    -moz-box-shadow: 0px 2px 4px 0px rgba(210, 210, 210, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(210, 210, 210, 0.5);
  }

  .draggable.nav > li > a {
    padding: 10px 20px;
  }
}

/*--------------------------------------------------------------- CATEGORY DROPDOWN BOX ---------------------------------------------------------------*/

.dropdown-menu.subcategories {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  left: 0 !important;
  right: 0 !important;
}

.dropdown-menu.show {
  position: relative;
}

/*--------------------------------------------------------------- SEARCH BOX ---------------------------------------------------------------*/

#search {
  padding: 0 !important;
  background: #ffffff;
}

#search .modal-dialog {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 6vh 12vw 6vh 16vw;
  height: 100%;
  pointer-events: all;
}

#search .modal-content {
  border: none;
  box-shadow: none;
}

#search button.close > span {
  color: #05142f !important;
}

#search button.close {
  right: 60px;
  top: 20px;
}

#search article h2 {
  font-size: 22px;
  line-height: 25px;
}

#search a.filter > img {
  max-width: 25px;
  margin-right: 10px;
}

#search a.filter {
  font-size: 18px;
  font-weight: 700;
}

#search a.filter {
  background: #f6f6f7;
  padding: 10px 15px;
  width: 150px;
  margin: 20px 0 0 0 !important;
  display: inline-block;
  text-decoration: none;
}

#search a.filter:hover {
  background: #eef6ff;
}

.search__filters__box {
  width: 100%;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(126, 129, 134, 0.24);
  -moz-box-shadow: 0px 2px 9px 0px rgba(126, 129, 134, 0.24);
  box-shadow: 0px 2px 9px 0px rgba(126, 129, 134, 0.24);
  border: 1px solid #c5d7eb;
  border-radius: 2px;
}

#search h4.search__results {
  margin-bottom: 20px;
  font-size: 22px;
  color: #006ee8;
  padding: 10px 20px 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

#search .search__results__box {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px !important;
  padding-bottom: 20px;
  min-height: auto;
}

#search .search__results__box article:nth-child(odd) {
  border-right: 1px solid #e0e0e0;
  padding-right: 30px;
  padding-left: 0;
}

#search .search__results__box article:nth-child(2n) {
  padding-left: 30px;
  padding-right: 0;
}

#search .modal-dialog > article {
  padding: 0 0 10px 0;
  margin: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}

#search .modal-dialog > article > .article__information {
  padding-left: 0;
}

#search .modal-dialog > article > figure.article__image__content {
  padding-right: 0;
}

#search .modal-dialog > .search__results__box:last-child {
  margin-bottom: 10px !important;
}

#search .modal-dialog > .search__results__box:empty {
  display: none !important;
}

#search article .article__cat {
  margin-top: 20px;
}

#search .search__form__container {
  display: flex;
}

#search button.close.clear {
  right: 40px;
  position: relative;
  top: 0;
}

.search__heading {
  display: flex;
  left: -114px;
  position: relative;
  min-height: auto !important;
}

.search__heading img {
  max-width: 90px;
}

.search__heading h2 {
  padding-top: 12px;
  padding-left: 30px;
}

#search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
}

.search__filter__tabs {
  margin-bottom: 15px;
}

.search__form__container input[type="search"] {
  width: 100%;
  font-size: 17px;
  font-weight: 300;
  text-align: left;
  border: 1px solid #021431;
  margin: 0px auto;
  padding: 16px 10px 16px 45px;
  outline: none;
  color: #006ee8;
  -webkit-appearance: none;
}

.search__form__container input[type="search"]:focus {
  border: 1px solid #006ee8;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 110, 232, 0.24);
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 110, 232, 0.24);
  box-shadow: 0px 2px 6px 0px rgba(0, 110, 232, 0.24);
}

.search__form__container .btn {
  background: url("../_images/ico-search-form.svg") no-repeat center !important;
  border: none;
  font-size: 0;
  position: absolute;
  width: 50px;
  height: 52px;
  padding: 0;
}

.search__form__container .btn.clear__search {
  background: url("../_images/ico-delete.svg") no-repeat center !important;
  right: 0;
  padding: 0;
  height: 52px;
  background-size: 30px !important;
}

.search__form__container input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

#search .btn:focus {
  box-shadow: none;
}

.search__filter__tabs > input[type="radio"] {
  opacity: 0;
  display: none;
}

.search__filter__tabs label {
  font-size: 16px;
  color: #353637;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.search__filter__tabs label:hover {
  color: #006ee8;
}

.search__filter__tabs > input[type="radio"]:checked + label {
  color: #ffffff;
  font-weight: 700;
  background: #021431;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

form.search__form {
  border-bottom: 2px solid #021431;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.search__form.searh-group{
  border-bottom: 0px;
  margin-bottom: 10px;
  max-width: 710px;
  margin: 0 auto;
}
.search__form.searh-group input{
  max-height: 40px;
  padding: 16px 10px 16px 60px;
}
.search__form.searh-group .search__form__container{
  position: relative;
}
.search__form.searh-group button.close.clear{
  right: 15px !important; 
  position: absolute !important;
  top: 6px !important;
  font-size: 27px;
} 
.searh-group .search__form__container .btn{
  margin: 0 auto 0 20px;
  width: 24px;
  height: 39px;
}

#search.search-groups h4.search__results{
  max-width: 850px;
  margin: 0 auto 20px;
}

.popular__s {
  font-size: 18px;
  color: #021431;
  font-weight: 600;
  margin-bottom: 15px !important;
  min-height: auto !important;
}

.quick__cats {
  display: flex;
  flex-flow: wrap;
  min-height: auto !important;
}

a.quick__cats__btn {
  font-size: 13px;
  border: 1px solid #ffffff;
  text-decoration: none;
  padding: 10px 20px 8px 20px;
  min-width: 135px;
  display: block;
  text-align: center;
  margin: 0 3px 15px 3px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background: #f0f7ff;
}

a.quick__cats__btn.active,
a.quick__cats__btn:hover {
  border: 1px solid #006ee8;
  background: #f0f7ff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.quick__cats__btn.topics {
  background: #f0f7ff;
}

span.search__title__box {
  background: #dcedfe;
  font-size: 20px;
  font-weight: 800;
  color: #006ee8;
  padding: 2px 8px;
  margin-bottom: 20px;
  display: inline-block;
}

.search__filters__box {
  padding: 25px;
}

.search__column > a {
  display: block;
  text-align: center;
  margin-top: 10px;
  text-decoration: none;
}

.search__column > a:hover > span {
  color: #006ee8;
}

/* CHECKLIST */

.checklist {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checklist input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 0;
}

.checklist input:checked ~ .check-mark {
  background-color: #006ee8;
  border: 1px solid #006ee8;
}

.check-mark:after {
  content: "";
  position: absolute;
  display: none;
}

.checklist input:checked ~ .check-mark:after {
  display: block;
}

.checklist .check-mark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.search__filters__box button.close {
  display: none;
}

@media (max-width: 770px) {
  #search .search__results__box article:nth-child(2n) {
    padding: 0;
  }

  #search .search__results__box article:nth-child(odd) {
    padding: 0;
    border-bottom: 1px solid #e0e0e0;
    border-right: none;
  }

  #search .modal-content > .search__results__box {
    padding-bottom: 0;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 630px) {
  #search .modal-dialog {
    padding: 5vh 8vw;
    height: auto;
  }

  .search__heading {
    display: block;
    left: 0;
  }

  .search__heading img {
    max-width: 50px;
  }

  .search__heading h2 {
    font-size: 18px;
    text-align: left;
    padding: 15px 0;
  }

  .search__filter__tabs label {
    font-size: 15px;
    padding: 5px 10px;
    margin: 0 2px;
  }

  #search {
    padding: 50px;
  }

  .search__filters__box button.close {
    display: block;
  }

  #search button.close {
    right: 30px;
  }

  a.quick__cats__btn {
    font-size: 13px;
    padding: 5px 10px;
    min-width: auto;
  }

  form.search__form {
    margin-bottom: 20px;
  }
}

/*--------------------------------------------------------------- FOOTER ---------------------------------------------------------------*/

footer {
  border-bottom: 4px solid #006ee8;
  height: 165px;
  border-top: 1px solid #d6d9de;
  position: relative;
  bottom: 0;
  width: 100%;
}

footer > .container {
  padding: 40px 25px;
  display: flex;
}

.left__footer,
.right__footer {
  margin: 0;
}

.footer__logo > img {
  width: 57px;
}

.quick__footer__links,
.legal__footer__links {
  margin: 0;
}

.footer__menus__container {
  margin: 0 20px;
}

.quick__footer__links .navbar-nav a {
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  padding-right: 20px;
}

.legal__footer__links ul.navbar-nav > li > a {
  font-size: 11px;
  text-decoration: none;
  color: #05142f;
  background: url("../_images/ico-dot.svg") no-repeat right center;
  padding-right: 15px;
  margin-right: 5px;
}

.legal__footer__links ul.navbar-nav > li:last-child > a {
  background: none;
  padding: 0 10px;
  margin: 0;
}

.legal__footer__links ul.navbar-nav > li > a:hover {
  color: #006ee8;
}

.languaje__desktop,
.languaje__mobile {
  border: 1px solid #d6d9de;
  font-size: 13px;
  padding: 10px 34px 10px 38px;
  background: url(../_images/select-language-bg.png) no-repeat center;
  position: relative;
  top: -5px;
  -webkit-appearance: none;
  background-size: contain;
}

select.languaje__mobile.menu {
  margin: 30px;
}

.social__container {
  display: flex;
}

.social__follow > span {
  font-size: 16px;
  font-weight: 600;
  padding-right: 20px;
}

.social__follow {
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding-bottom: 30px;
}

.social__follow:after {
  content: "";
  display: block;
  width: 65%;
  border-bottom: 2px solid #eaeaea;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 15px;
}

a.social__btn {
  width: 36px;
  height: 36px;
  background-size: 36px !important;
  background-position: top center !important;
  display: block;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin: 0 10px;
}

a.social__btn:last-child {
  margin: 0 0 0 10px;
}

a.social__btn circle {
  stroke: #05142f !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.social__btn path,
a.social__btn rect {
  fill: #05142f !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.social__btn:hover circle {
  stroke: #006ee8 !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.social__btn:hover path,
a.social__btn:hover rect {
  fill: #006ee8 !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.st0 {
  fill: none;
  stroke: #05142f;
  stroke-width: 0.4;
  stroke-miterlimit: 10;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #05142f;
}

.copyright__content {
  color: #64676b;
  font-size: 13px;
  justify-content: flex-end;
  margin: 0;
}

.copyright__content > p:first-child {
  padding: 0 10px;
}

.copyright__content > p > a {
  padding-left: 5px;
}

.copyright__content > p > a > img {
  max-width: 38px;
}

.others__links.dropdown.footer__dropdown {
  display: contents;
}

a.others__shares.footer__more.dropdown {
  background: url(../_images/footer-more.svg) 100% center no-repeat;
  padding-right: 15px;
  color: #616978;
}

.others__links.dropdown.footer__dropdown .share__options {
  width: 135px;
  margin-left: -80px;
  margin-top: 5px;
  padding: 0 10px;
}

.others__links.dropdown.footer__dropdown .share__options.bottom__dropdown {
  margin-top: -195px;
}

.others__links.dropdown.footer__dropdown .share__options .share__links {
  padding: 15px;
}

.others__links.dropdown.footer__dropdown .share__options::after {
  display: none;
}

/*--------------------------------------------------------------- HOME SLIDER ---------------------------------------------------------------*/

section.home__slider__box {
  background: url(../_images/home-slider-bg.jpg) no-repeat;
  padding: 36px 0 26px 0;
  background-size: cover;
}

section.home__slider__box img {
  max-width: 600px;
  margin-bottom: 20px;
}

section.home__slider__box h1 {
  font-size: 39px;
  color: #ffffff;
  line-height: 43px;
  max-width: 520px;
  margin-top: 0;
  margin-bottom: 40px;
}

section.home__slider__box h1 > span {
  color: #ffd600;
}

.btn-secondary.white,
.btn-secondary.white:focus {
  border-radius: 2px;
  background: none !important;
  border: 2px solid #ffffff !important;
  color: #ffffff !important;
  min-width: 160px;
  padding-left: 1.2em;
  padding-right: 1.2em;
  height: 50px;
  font-size: 22px;
  font-weight: 800;
  display: block;
  text-align: center;
  text-decoration: none;
  box-shadow: none;
}

.btn-secondary.white:hover {
  background: #ffffff !important;
  border: 2px solid #ffffff;
  color: #006ee8 !important;
}

.home__slider__box .btn-secondary.white {
  display: inline;
}

.nav__slider {
  display: flex;
  justify-content: center;
  height: 20px;
}

.nav__slider a.left__nav__slider,
.nav__slider a.right__nav__slider {
  padding: 3px 6px;
  width: 44px;
  height: 33px;
  border: 1px solid #fafafa00;
}

.nav__slider a.left__nav__slider:hover,
.nav__slider a.right__nav__slider:hover {
  border: 1px solid #fafafa;
}

.nav__slider a > img {
  width: 27px;
  height: 17px;
  margin: 0;
}

.nav__counter {
  font-size: 17px;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  margin: 0 10px;
}

.nav__counter > span {
  color: #ffffff;
  font-weight: 800;
}

span.remove-padding {
  padding-right: 0;
}

@media (max-width: 990px) {
  section.home__slider__box {
    padding: 50px 0 35px 0;
  }

  section.home__slider__box h1 {
    max-width: 100%;
    font-size: 33px;
    line-height: 37px;
  }

  .home__slider__txt {
    padding: 0 15px;
  }

  .home__slider__txt button.btn-secondary.white {
    margin-bottom: 35px;
    width: 100%;
    display: block;
  }

  .carousel {
    min-height: 400px;
  }

  .carousel-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
  }

  .carousel-item {
    margin-bottom: 20px;
  }

  section.home__slider__box h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 520px) {
  section.home__slider__box h1 {
    max-width: 290px;
  }
}

/*--------------------------------------------------------------- CARDS CATEGORY ---------------------------------------------------------------*/

section.category__cards {
  background: #fafbfc;
  padding: 45px 0;
}

.category__card > .category__card__box {
  background: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 6px;
  -webkit-box-shadow: 1px 7px 17px 0px rgba(217, 217, 217, 1);
  -moz-box-shadow: 1px 7px 17px 0px rgba(217, 217, 217, 1);
  box-shadow: 1px 7px 17px 0px rgba(217, 217, 217, 1);
  padding: 40px 38px 48px 38px;
  height: 100%;
}

.category__card > .category__card__box:hover {
  -webkit-box-shadow: 1px 7px 17px 0px rgba(165, 165, 165, 1);
  -moz-box-shadow: 1px 7px 17px 0px rgba(165, 165, 165, 1);
  box-shadow: 1px 7px 17px 0px rgba(165, 165, 165, 1);
  transform: scale(1.05);
}

.category__card h2 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 800;
}

.category__card h2 > span {
  background: #dcedfe;
  color: #006ee8;
  padding: 0 6px;
  position: relative;
  left: -6px;
  display: inline-block;
}

.category__card h2 > span.yellow {
  color: #021431;
  background: #ffcc36;
}

.category__card p {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  min-height: 35%;
}

a.card__url {
  font-weight: 600;
  color: #021431;
  font-size: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: 0;
  right: 0;
}

a.card__url > span {
  background: #006ee8;
  width: 35px;
  height: 35px;
  padding: 4px 10px 7px 10px;
  border-radius: 50%;
  margin-left: 13px;
}

a.card__url:hover {
  text-decoration: none;
}

a.card__url:hover > span {
  background: #1959bc;
}

a.player__btn.home {
  display: flex;
  max-width: 200px;
  align-items: center;
  width: 200px;
  top: 86%;
  left: 130px;
}

a.player__btn.home > span {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

a.player__btn.home > img {
  margin-right: 8px;
  width: initial;
}

a.player__btn.home:hover,
a.player__btn.home:hover > span {
  text-decoration: none;
  color: #006ee8;
}

.featured__content__home .article__information h2 {
  max-width: 90%;
  display: block;
  margin-top: 0;
}

.home__page .container h2 > a {
  font-size: 23px;
  line-height: 29px;
  font-weight: 600;
}

@media (max-width: 990px) {
  .category__card {
    margin: 15px 0;
  }
}

@media (max-width: 770px) {
  section.category__cards {
    padding: 25px 0;
  }

  section.category__cards > .container {
    padding: 0 10px;
  }
}

/*--------------------------------------------------------------- FEATURED HOME SLIDER ---------------------------------------------------------------*/

section.featured__content__home {
  padding: 55px 0;
}

section.featured__content__home > .container .featured__articles {
  padding-bottom: 30px;
}

section.featured__content__home > .container .featured__articles:last-child {
  border-bottom: 1px solid #cacaca;
}

.featured__heading h3.category__title {
  border-bottom: none;
  margin-bottom: 0;
  font-weight: 700;
}

.featured__heading.d-flex {
  border-bottom: 1px solid #021431;
  margin: 0 15px 40px 15px;
  padding-bottom: 10px;
}

.navigation__featured > a {
  text-decoration: none;
}

.featured__content__home p.article__cat__tag > a {
  color: #006ee8;
}

.featured__allitems {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.navigation__featured > a > span.explore__featured {
  font-size: 16px;
  font-weight: 600;
  border-right: 1px solid #d8d8d8;
  padding-right: 10px;
  margin-right: 10px;
}

.navigation__featured > a:hover > span.explore__featured {
  color: #006ee8;
}

.buttons__featured .nav__counter {
  font-size: 15px;
  color: #021431;
  padding-right: 5px;
  position: relative;
  top: 0;
}

.buttons__featured .nav__counter > span {
  color: #021431;
}

.buttons__featured > a {
  border: 1px solid #d1e2f4;
  width: 36px;
  height: 36px;
  padding: 5px 8px;
  border-radius: 50%;
  background: #ffffff;
  margin-left: 15px;
}

.buttons__featured > a.disable {
  opacity: 0.5;
  border: 1px solid #d1e2f4 !important;
  cursor: unset;
}

.buttons__featured > a:hover {
  border: 1px solid #006ee8;
}

@media (max-width: 770px) {
  section.featured__content__home > .container .featured__articles {
    border-bottom: none;
    padding-bottom: 0;
  }

  section.featured__content__home > .container .featured__articles:last-child {
    border-bottom: none;
  }

  .featured__content__home .small__column__articles > article {
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  .featured__content__home .small__column__articles {
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 25px;
    padding: 0;
  }

  .featured__content__home > .container {
    padding: 0 25px;
  }

  .featured__heading.d-flex {
    margin: 0 0 20px 0;
  }

  .featured__content__home .small__column__articles:last-child {
    border-bottom: 1px solid #021431;
    padding-bottom: 30px;
  }

  figure.article__image__content > a > img {
    width: 100%;
  }

  section.featured__content__home
  figure.article__image__content
  a.image__btn
  > img {
    width: 100%;
  }
}

@media (max-width: 570px) {
  .navigation__featured {
    display: none !important;
  }
}

/*--------------------------------------------------------------- RECENT CONTENT HOME ---------------------------------------------------------------*/

section.most__popular__box > .container {
  border-bottom: 1px solid #cacaca;
  padding-bottom: 60px;
  margin: 0 auto;
}

.most__popular__box h2 > a {
  font-size: 22px !important;
  line-height: 29px;
}

.content__frenetic > h3.title__home,
.most__popular__container > h3.title__home {
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 45px;
}

.most__popular__container {
  border-right: 1px solid #d8d8d8;
  padding-right: 60px;
}

.most__popular__box .article__cat,
.most__popular__box .article__cat * {
  height: auto;
  margin-bottom: 4px;
}

.most__popular__box p.article__cat__tag > a {
  color: #006ee8;
}

.content__frenetic {
  padding-left: 60px;
}

.content__frenetic .frenetic__img a {
  width: 98px;
  height: 98px;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}

.content__frenetic .frenetic__img a.frenetic__img__profile > img {
  width: 100%;
}

.content__frenetic .frenetic__img a.boy__profile {
  background: url(../_images/content-profile-img1.svg) no-repeat;
  background-size: contain;
  background-position: center 10px;
}

.content__frenetic .frenetic__img a.girl__profile {
  background: url(../_images/content-profile-img2.svg) no-repeat;
  background-size: contain;
  background-position: center 10px;
}

.most__popular__box .container ol {
  list-style: none;
  counter-reset: item;
  padding: 0;
}

.most__popular__box .container ol > li {
  counter-increment: item;
  margin: 20px 0 35px 0;
  padding-left: 40px;
}

.most__popular__box .container ol > li:before {
  margin-right: 10px;
  content: counter(item) ". ";
  color: #021431;
  text-align: center;
  display: inline-block;
  position: absolute;
  font-size: 23px;
  line-height: 32px;
  font-weight: 600;
  margin-top: 30px;
  margin-left: -40px;
}

.frenetic__img {
  margin: 0;
  padding: 0;
}

.frenetic__content {
  padding: 0;
}

.frenetic__content .autor__container {
  height: auto;
  margin-bottom: 15px;
}

.frenetic__box {
  margin-bottom: 45px;
}

a.all__work__btn {
  color: #021431;
  margin-top: 10px;
  display: block;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
}

a.all__work__btn img {
  margin-left: 10px;
}

a.all__work__btn:hover {
  color: #006ee8;
}

.content__frenetic a.btn-secondary {
  width: 210px;
  font-size: 16px;
  color: #006ee8;
  border: 1px solid #006ee8;
  height: 45px;
  background: #ffffff;
}

.content__frenetic a.btn-secondary:hover {
  background: #006ee8;
  color: #ffffff;
}

@media (max-width: 770px) {
  section.most__popular__box {
    padding: 0;
  }

  .most__popular__container {
    border-right: none;
    padding: 0 15px 20px 15px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 50px;
  }

  section.most__popular__box > .container {
    border-bottom: none;
  }

  .content__frenetic > h3.title__home,
  .most__popular__container > h3.title__home {
    margin: 0 auto 25px auto;
    text-align: center;
  }

  .content__frenetic > h3.title__home,
  .most__popular__container > h3.title__home {
    text-align: center;
  }

  .home__page .container ol > li:before {
    font-weight: 700;
  }

  .home__page .most__popular__box h2 > a {
    font-size: 21px;
    line-height: 27px;
  }

  .home__page .container ol > li {
    margin-bottom: 0;
  }

  .content__frenetic {
    padding: 0 10px;
  }

  .content__frenetic .frenetic__img a {
    margin: 0 auto;
  }

  .frenetic__content,
  .frenetic__content * {
    text-align: center;
    max-width: 320px;
    margin: 0 auto;
  }

  .frenetic__content p.article__autor {
    margin: 20px auto 0 auto;
  }

  .frenetic__img {
    margin: 0 auto;
  }

  .frenetic__box {
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 25px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .frenetic__box:nth-last-child(2) {
    border-bottom: none;
  }

  .content__frenetic a.btn-secondary {
    width: 95%;
    margin: 0 auto;
    display: block;
  }
}

/*--------------------------------------------------------------- BECOME A FRENETIC HOME ---------------------------------------------------------------*/

section.become__home {
  background: url(../_images/become-home-bg.png) no-repeat;
  background-size: cover;
  margin-bottom: 130px;
  padding-top: 100px;
}

.become__home__img {
  text-align: right;
}

.become__home__txt {
  padding-right: 50px;
}

.become__home__img > img {
  max-width: 80%;
}

.become__home__txt h2 {
  font-size: 47px;
  line-height: 50px;
  font-weight: 800;
  margin-bottom: 40px;
}

.become__home__txt h2:after {
  content: "";
  display: block;
  width: 100px;
  border-bottom: 4px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 20px;
  border-radius: 20px;
}

.become__home__txt p {
  font-size: 19px;
  line-height: 29px;
  margin-bottom: 40px;
}

.become__home__txt > a.btn {
  margin: 0;
  width: 120px;
  height: 50px;
  border-radius: 3px;
}

@media (max-width: 770px) {
  section.become__home {
    padding-bottom: 60px;
  }

  .become__home__img {
    text-align: center;
  }

  .become__home__txt h2 {
    font-size: 38px;
    line-height: 43px;
  }

  .become__home__txt {
    padding: 0 20px;
    margin-top: 30px;
  }

  .become__home__txt > a.btn {
    width: 100%;
    max-width: 100%;
  }
}

/*--------------------------------------------------------------- LOGIN ---------------------------------------------------------------*/

.login__content {
  padding: 10vh 10vw;
}

.login__content h1 {
  font-size: 43px;
  font-weight: 800;
  line-height: 45px;
}

.row.login__box {
  margin: 0;
  background: #ffffff;
}

span.title__highlight {
  color: #006ee8;
  background: #dcedfe;
  margin-right: 10px;
}

span.title__highlight.no__background {
  background: none;
}

.create__account__box {
  background: #f5f9ff;
  font-size: 15px;
  padding: 10px;
  font-weight: 600;
  display: inline-block;
  margin: 1vh 0 2vh 0;
}

.images__login {
  background: url("../_images/login-image.png");
  background-size: cover;
  border-right: 3px solid #021431;
  background-position: center;
}

.forgot__password {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  text-decoration: underline;
  padding: 1vh 0;
}

.form-label-group.email__content {
  background: url(../_images/ico-email-login.svg) no-repeat left 16px;
}

.form-label-group.password__content {
  background: url(../_images/ico-lock.svg) no-repeat left 15px;
}

.login__box input {
  padding-left: 30px;
  background: none !important;
}

label {
  padding-left: 30px;
}

.login__box label {
  padding-left: 30px;
}

.form-signin .checkbox > label {
  padding: 0;
}

.login__box label.checklist,
.form-signin .checkbox > label {
  padding-left: 25px !important;
}

.login__box label span.check-mark,
.registry__content label span.check-mark {
  width: 16px;
  height: 16px;
  top: 3px;
  background-color: #ffffff !important;
  border: 1px solid #5b5b5b;
}

.login__box label span.check-mark:after,
.registry__content label span.check-mark:after {
  top: 0;
  left: 5px;
  border: solid #021431;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 769px) {
  .images__login {
    display: none;
  }

  .login__content h1 {
    font-size: 28px;
    line-height: 30px;
  }

  .login__content {
    background: url(../_images/sign-mobile-bg.svg) #ffffff no-repeat top;
    background-position: center -2vw;
    padding: 16vh 8vw 8vh 8vw;
    height: 100vh;
  }

  .login__content button.close span {
    color: #ffffff;
  }
}

/*--------------------------------------------------------------- REGISTRY ---------------------------------------------------------------*/

.images__registry {
  background: url("../_images/registry-image.png");
  background-size: cover;
  background-position: center;
  border-right: 3px solid #021431;
}

.type__user > span {
  font-weight: 600;
}

.user__options {
  margin: 15px 0 0 0;
}

.form-signin .checkbox > label {
  padding-left: 25px;
  font-size: 14px;
}

.form-signin .checkbox > label > a.secondary__hyper {
  text-decoration: underline;
}

.new__password.confirmation1 {
  float: left;
  width: 48%;
}

.new__password.confirmation2 {
  float: right;
  width: 48%;
}

.password__validator {
  clear: both;
}

.condition__pass span {
  font-size: 12px;
  margin-left: 5px;
  top: -2px;
  position: relative;
}

.condition__pass {
  margin-right: 12px;
  float: left;
}

.condition__pass > i {
  color: #979797;
}

.condition__pass svg {
  width: 17px;
  height: 17px;
}

span.paas__instructions {
  color: #181818;
  font-size: 14px;
  font-weight: 600;
}

.password__conditions {
  margin: 10px 0 20px 0;
}

.d-flex.password__conditions {
  margin: 10px 0;
}

.registry__content {
  padding: 8vh 8vw;
  background: #ffffff;
}

.registry__content h1 {
  font-size: 43px;
  font-weight: 800;
  line-height: 52px;
  margin-bottom: 10px;
  margin-top: 0;
}

.registry__copy:after {
  content: "";
  display: block;
  width: 35px;
  border-bottom: 2px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 9px;
}

.user__options .bullet {
  border: 1px solid #ffffff;
  background: #ffffff;
  font-size: 18px;
  padding: 10px 20px 10px 50px;
}

.user__options .bullet:checked {
  border: 1px solid #006ee8;
  background: #f8fbff;
  font-size: 18px;
  padding: 10px 20px 10px 50px;
}

.form-check.user__type {
  margin-right: 15px;
  padding: 0;
}

.user__options .bullet span.checkmark {
  top: 10px;
  left: 10px;
}

.condition__pass.error > span {
  color: #d0021b;
}

.condition__pass.error > i {
  color: #d0021b;
}

.condition__pass.validate > span {
  color: #28a745;
}

.condition__pass.validate > i {
  color: #28a745;
}

@media (max-width: 1400px) {
  .registry__content {
    padding: 10vh 6vw;
  }
}

@media (max-width: 990px) {
  .registry__content h1 {
    font-size: 28px;
    line-height: 32px;
  }

  .new__password.confirmation1,
  .new__password.confirmation2 {
    float: none;
    width: 100%;
  }
}

@media (max-width: 770px) {
  .images__registry {
    display: none;
  }

  .registry__content {
    background: url(../_images/sign-mobile-bg.svg) #ffffff no-repeat top;
    background-position: center -2vw;
    padding: 15vh 8vw 5vh 8vw;
    height: auto;
  }

  .registry__content button.close span {
    color: #ffffff;
  }
}

@media (max-width: 660px) {
  .password__content.confirmation1,
  .password__content.confirmation2 {
    float: none;
    width: 100%;
  }
}

@media (max-width: 580px) {
  .modal-dialog {
    margin: 0 auto;
  }

  .modal.show .modal-dialog {
    max-width: 100%;
    min-height: auto;
    height: 100vh;
    margin: 0;
    padding: 0;
  }

  .modal.show .modal-dialog .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }

  .modal-content > div {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    width: 100%;
  }

  .modal-dialog > .modal-content > .modal-dialog > .modal-content {
    box-shadow: none;
  }
}

/*--------------------------------------------------------------- PROFILE ---------------------------------------------------------------*/

.profile__content {
  padding: 10vh 6vw;
}

.account_box form.form-signin {
  margin-top: 5vh;
}

.profile__content h1 {
  font-size: 43px;
  font-weight: 800;
  line-height: 52px;
  margin-bottom: 10px;
  margin-top: 0;
}

.profile__image__content {
  display: table;
  left: 0;
  right: 0;
  position: relative;
  margin: 0 auto;
}

.form-label-group.profession,
.half-l {
  float: left;
  width: 48%;
}

.form-label-group.country,
.half-r {
  float: right;
  width: 48%;
}

.form-label-group.full__input {
  width: 100%;
}

select.form-control {
  padding: 5px 0 0 0;
  background: url(../_images/select-arrow.svg) no-repeat;
  background-position: 100% center;
}

.submit__image {
  position: relative;
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.submit__image [type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.submit__image .profile_file {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border: 0px;
}

.submit__image .profile_file:focus{
  box-shadow: 0px 0px 0px;
}

.submit__image img {
  width: 100%;
  height: auto;
}
.submit__image .img-group img[src*="data"] {
  width: 100%;
  height: auto;
  min-height: 90px;
}

.submit__image #ico-add{
  display: none;
}

.submit__image .img-group {
  max-width: 90px;
  max-height: 90px;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50px;
}

.image__profile {
  width: 90px;
  height: 90px;
  border: 2px solid #bfdbf9;
  border-radius: 50%;
  background: url("../_images/ico-camera.svg") no-repeat center center;
  margin: 0 auto;
}

.image__profile.video {
  background: url("../_images/ico-video-creation.svg") no-repeat center center;
  border: none;
}

.profile__image__content > a.add {
  float: right;
  top: -30px;
  right: -5px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
}

.profile__image__content > a.add:hover {
  opacity: 0.8;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.description {
  margin-bottom: 40px;
}

@media (max-width: 770px) {
  .registry__content h1,
  .profile__content h1 {
    font-size: 28px;
    line-height: 32px;
  }

  .profile__content {
    background: url(../_images/sign-mobile-bg.svg) #ffffff no-repeat top;
    background-position: center -2vw;
    padding: 18vh 8vw 5vh 8vw;
    height: auto;
  }

  .profile__content button.close span {
    color: #ffffff;
  }
}

@media (max-width: 660px) {
  .form-label-group.profession,
  .form-label-group.country {
    float: none;
    width: 100%;
  }
}

/*--------------------------------------------------------------- AVATAR CHOICE ---------------------------------------------------------------*/

.all__avatars {
  padding: 20px 30px 0 30px;
  border: 1px solid #d6d9de;
  margin-bottom: 30px;
}

.other__avatar {
  margin: 10px 0 30px 0;
}

.avatar__container {
  text-align: center;
}

.other__avatar > span {
  font-size: 20px;
  font-weight: 600;
}

.other__avatar > a {
  font-size: 14px;
  text-decoration: none;
}

.other__avatar > a > span {
  margin-right: 8px;
}

.other__avatar > a:hover > span {
  color: #006ee8;
}

.other__avatar > a:hover > img {
  opacity: 0.7;
}

.avatar__container .form-check.user__type {
  float: left;
  margin: 0 calc(10% - 23px);
}

.avatar__container .form-check.user__type span.checkmark {
  top: 60%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.avatar__container .form-check.user__type label.form-check-label.bullet {
  margin: 0;
  padding: 0;
  height: 125px;
}

.form__avatar button {
  max-width: 50%;
  margin: 0 auto;
}

input.upload__avatar {
  color: transparent;
  width: 135px;
  height: 30px;
  display: none;
}

label.upload__avatar__l {
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

label.upload__avatar__l > span {
  margin-right: 8px;
}

label.upload__avatar__l:hover > span {
  color: #006ee8;
}

label.upload__avatar__l:hover > img {
  opacity: 0.7;
}

@media (max-width: 600px) {
  .avatar__container img {
    max-width: 88%;
  }

  .avatar__container .form-check.user__type label.form-check-label.bullet {
    height: 110px;
  }
}

/*--------------------------------------------------------------- EDIT PHOTO ---------------------------------------------------------------*/

.photo__form {
  border: 1px solid #d6d9de;
  padding: 10px 0px;
  text-align: center;
  margin-top: 30px;
}

.photo__form > span {
  font-size: 20px;
  font-weight: 600;
}

.photo__form > a.delete__photo {
  font-size: 15px;
}

.photo__form > img {
  display: block;
  margin: 0 auto 10px auto;
}

.photo__save {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.photo__save button {
  width: 35%;
  height: 40px;
  margin: 0 5px !important;
  font-size: 18px;
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

label.upload__photo__l {
  cursor: pointer;
  text-align: center;
  display: flex;
  width: 100%;
  padding: 19px;
  border: 1px dashed #006ee8;
  background: #f3f8ff;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  height: 20vw;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

[type="file"]:hover + label.upload__photo__l {
  text-decoration: none;
  opacity: 0.6;
}

label.upload__photo__l .image__profile {
  width: 70px;
  height: 70px;
  background-size: 50%;
}

label.upload__photo__l .profile__image__content > a.add {
  width: 25px;
  height: 25px;
  top: -20px;
}

label.upload__photo__l .profile__image__content {
  height: 70px;
  display: block;
}

.avatar__canvas {
  width: 100%;
  height: 100%;
}

.avatar__canvas > div {
  width: 100%;
  height: 100%;
}

.avatar__canvas > div > div {
  margin: 20px auto;
}

.avatar__canvas > div label {
  padding: 0;
}

@media (max-width: 880px) {
  .photo__save button {
    width: 45%;
  }
}

@media (max-width: 770px) {
  [type="file"] + label.upload__photo__l {
    height: 45vw;
  }
}

/*--------------------------------------------------------------- WELCOME ---------------------------------------------------------------*/

img.welcome__ico {
  max-width: 150px;
}

.modal-content.welcome {
  background: url("../_images/welcome-image.png") #ffffff no-repeat right center !important;
  background-size: contain !important;
  padding: 8vh 24vw 8vh 8vw;
}

.modal-content > .welcome__content {
  min-height: auto;
}

h1.welcome__title {
  font-size: 40px;
  font-weight: 800;
}

h1.welcome__title:after {
  content: "";
  display: block;
  width: 90px;
  border-bottom: 3px solid #021431;
  position: relative;
  margin-top: 20px;
}

span.you__can {
  font-size: 30px;
  color: #006ee8;
  font-weight: 800;
}

.highlights__box.row {
  display: -webkit-flex;
  -webkit-flex-direction: row;
  padding: 30px 20px;
  max-width: 800px;
}

.highlights__box img {
  min-width: 30px;
  max-width: 30px;
}

.highlight__01 {
  width: 50%;
  display: -webkit-flex;
  -webkit-flex-direction: row;
  font-size: 20px;
  line-height: 25px;
}

.highlight__02 {
  width: 50%;
  display: -webkit-flex;
  -webkit-flex-direction: row;
  font-size: 20px;
  line-height: 25px;
}

.highlight__01 > .highlight__txt,
.highlight__02 > .highlight__txt {
  max-width: 223px;
}

.highlight__ico {
  padding-right: 14px;
}

.welcome__content button {
  max-width: 250px;
}

@media (max-width: 770px) {
  h1.welcome__title {
    font-size: 27px;
    line-height: 32px;
  }

  span.you__can {
    font-size: 17px;
    line-height: 28px;
  }

  img.welcome__ico {
    display: none;
  }

  .welcome__content button.close > span {
    color: #05142f;
  }

  .welcome__content button.close {
    display: none;
  }
}

@media (max-width: 560px) {
  .welcome__content button.btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    left: 0;
  }

  .welcome__content .highlight__txt {
    font-size: 16px;
    line-height: 19px;
    max-width: 70%;
  }
}

/*--------------------------------------------------------------- SIDENAV ---------------------------------------------------------------*/

.sidenav {
  height: 100%;
  width: 100%;
  max-width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
  -webkit-box-shadow: 0px -2px 6px 0px rgba(92, 96, 102, 1) !important;
  -moz-box-shadow: 0px -2px 6px 0px rgba(92, 96, 102, 1) !important;
  box-shadow: 0px -2px 6px 0px rgba(92, 96, 102, 1) !important;
  padding: 20px 0;
}

.sidenav a {
  text-decoration: none;
  color: #021431 !important;
  display: block;
  transition: 0.3s;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
}

.sidenav a:hover {
  background: none;
  color: #021431 !important;
}

.sidenav a:after,
.sidenav ul.dropdown-content a:after {
  display: none;
}

.sidenav img {
  max-width: 40px;
}

.sidenav > a.tech__icon.mobile,
.sidenav > .mobile__options,
.sidenav > ul {
  padding: 0 30px;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  z-index: 2;
}

@media (max-width: 1300px) {
  .modal-content.welcome {
    padding: 6vh 26vw 10vh 5vw;
    background-position: 110% center;
  }
}

@media (max-width: 1050px) {
  .modal-content.welcome {
    background-position: 125% center;
  }
}

@media (max-width: 960px) {
  .highlight__txt {
    line-height: 19px;
    padding-right: 10px;
  }

  .modal-content.welcome {
    background-position: 125% bottom;
    background-size: 50%;
  }
}

@media (max-width: 660px) {
  .modal-content.welcome {
    padding: 4vh 8vw 0 8vw;
    background: url("../_images/welcome-image-mobile.png") #ffffff no-repeat right bottom;
    background-size: 100%;
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }

  .highlights__box > div {
    width: 100%;
    padding: 20px 0;
  }

  .highlights__box.row {
    display: block;
    padding: 0 20px;
  }

  .highlights__box img {
    max-width: 25px;
    display: block;
    min-width: 25px;
  }
}

@media (max-width: 415px) {
  h1.main__title > span.highlight__txt {
    display: inline-block;
    padding: 5px;
  }
}

@media screen and (max-width: 360px) {
  h1.main__title > span.highlight__txt {
    display: inline;
    padding: 0 5px;
  }
}

/*--------------------------------------------------------------- MAIN BODY HOME ---------------------------------------------------------------*/

.main__search__box {
  background: url("../_images/main-search-box-bg.png");
  height: 390px;
  padding: 45px 0px;
}

h1.main__title {
  padding: 0 0 20px 0;
  background-size: cover;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
  font-size: 35px;
  color: #ffffff;
}

span.highlight__txt {
  background: #fdd605;
  padding-left: 10px;
  position: relative;
  left: -10px;
  padding-right: 5px;
  margin-right: 5px;
}

span.highlight__txt.p2 {
  left: -18px;
  position: relative;
  padding: 0 5px;
}

span.highlight__txt.blue {
  background: #006ee8;
}

.main__search__box img {
  width: 100%;
}

.search__form__container.small input[type="search"] {
  height: 45px;
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  margin: 0;
}

.search__form__container.small .btn {
  height: 45px;
}

.search__form__container.small {
  padding: 0 25px 0 0;
  max-width: 530px;
  display: flex;
}

.search__filter__tabs.small {
  margin: 5px 10px 15px 0;
  max-width: 500px;
}

.search__filter__tabs.small a {
  color: #ffffff;
  background: #0062e4;
  border-radius: 0;
  font-size: 15px;
  margin: 3px;
  padding: 5px 10px;
  display: inline-block;
  text-decoration: none !important;
}

.search__filter__tabs.small a:hover {
  background: #0257c8;
}

span.form__title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  padding-left: 0;
}

span.form__title:after {
  content: "";
  display: block;
  width: 55px;
  border-bottom: 2px solid #ffcc36;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 5px;
  border-radius: 20px;
}

.search__box__contain h1.main__title {
  margin-left: -5px;
}

.column__trending__left {
  padding-left: 0;
}

.column__trending__left__inner .small__column__articles {
  padding-right: 0;
}

.column__trending__right {
  background: url("../_images/all-comunity-bg.jpg");
  margin-top: 32px;
  padding: 0;
  display: block;
}

.article__cat {
  height: 30px;
}

article .container p.article__cat__tag {
  line-height: 20px !important;
}

a.player__btn {
  max-width: 20%;
  border: 0;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}

figure.article__image__content {
  position: relative;
  display: inline-table;
  width: 100%;
}

figure.article__image__content > a {
  display: block;
  max-height: 170px;
  overflow: hidden;
}

figure.article__image__content > a > img {
  width: 100%;
}

figure.article__image__content.figure-caption {
  border-bottom: 1px solid #e7e7e7;
}

figure.article__image__content figcaption {
  font-size: 14px;
  line-height: 19px;
  padding: 15px 0;
  color: #4b4b4b;
}

.article__information h2 {
  display: block;
}

.article__information h2 > a {
  display: inline !important;
}

article h2 > a {
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 85%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.5s;
}

article h2 > a:focus,
article h2 > a:hover {
  background-size: 100% 2px;
}

a.premium__label {
  background: url("../_images/ico-premium.svg") no-repeat 90% center #006ee8;
  color: #ffffff;
  padding: 6px 40px 4px 10px;
  font-size: 15px;
  font-weight: 700;
  left: 0;
  position: absolute;
  top: 0;
  text-transform: uppercase;
  text-decoration: none;
}

span.player__counter {
  background: #05142f;
  color: #ffffff;
  padding: 5px 8px;
  font-size: 13px;
  font-weight: 700;
  right: 15px;
  position: absolute;
  bottom: 10px;
}

article.no__excerpt > .article__information > p {
  display: none;
}

article.no__figure > figure.article__image__content {
  display: none;
}

.category__title {
  border-bottom: 2px solid #021431;
}

p.article__cat__tag > a {
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #006ee8;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

span.article__cat__separator {
  padding: 0 5px;
  font-size: 10px;
  line-height: 32px;
  color: #006ee8;
  display: contents;
}

.article__cat.d-flex span.article__cat__separator {
  line-height: 25px;
  display: block;
}

time.article__hour__tag {
  font-size: 13px;
  color: #565656;
  line-height: 14px;
  top: 5px;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.big__column__articles .article__information h2 > a {
  font-size: 30px;
  text-decoration: none;
  color: #05142f;
  line-height: 35px;
  display: block;
}

.small__column__articles .article__information h2 > a {
  font-size: 20px;
  text-decoration: none;
  color: #05142f;
  line-height: 23px;
  display: block;
}

.article__autor > a {
  text-decoration: none;
  font-size: 14px !important;
  font-weight: 700;
  color: #05142f;
}

.article__autor > a:hover,
.article__autor > a:hover > span {
  color: #006ee8;
}

.autor__container {
  height: 40px;
  justify-content: space-between;
}

.article__information > p {
  font-size: 16px;
  line-height: 22px;
}

p.article__autor {
  margin-bottom: 0;
}

.autor__container > a {
  height: 17px;
}

.autor__container > a > svg {
  max-width: 12px;
  top: 0;
  position: relative;
  max-height: 17px;
}

.autor__container > a.selected > svg > path.mark__border,
.autor__container > a:hover > svg > path.mark__border {
  fill: #006ee8;
}

.autor__container > a.selected > svg > path.mark__body,
.autor__container > a:hover > svg > path.mark__body {
  fill: #006ee8;
}

.text__about__tfc {
  padding: 20px 40px;
}

span.number__tfcoll {
  font-size: 50px;
  font-weight: 800;
  color: #ffffff;
}

p.text__about__tfc__p1 {
  color: #ffffff;
  font-size: 26px;
  font-weight: 800;
  line-height: 25px;
  max-width: 180px;
  padding-bottom: 30px;
}

p.text__about__tfc__p1:after {
  content: "";
  display: block;
  width: 50%;
  border-bottom: 2px solid #ffffff;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 2px;
  padding-bottom: 30px;
}

p.text__about__tfc__p2 {
  font-size: 19px;
  line-height: 23px;
  font-weight: 700;
  color: #ffffff;
  max-width: 190px;
}

.text__about__tfc img {
  max-width: 125px;
}

a.community__tf__cta {
  background: #02143166;
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 20px 38px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

a.community__tf__cta > img {
  max-width: 37px;
}

a.community__tf__cta > span {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  max-width: 200px;
  line-height: 22px;
  padding-right: 20px;
}

/*--------------------------------------------------------------- FEATURED EVENTS MAIN ---------------------------------------------------------------*/

section.featured__events {
  background: #fafbfc;
  padding: 60px 0;
  margin-bottom: 25px;
}

section.featured__events .featured__carousel {
  position: relative;
}

section.featured__events .react-multi-carousel-list {
  position: initial;
}

section.featured__events .react-multiple-carousel__arrow {
  position: absolute;
  top: -68px;
  right: 15px;
  border: 1px solid #d1e2f4;
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  padding: 5px 8px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-left: 15px;
  background: url("/static/media/ico-hfeatured-arrow-right.205c2cfb.svg");
  background-repeat: no-repeat;
  background-position: center;
  font-size: 0;
}

section.featured__events .react-multiple-carousel__arrow:before {
  display: none;
}

section.featured__events .react-multiple-carousel__arrow--left {
  background: url("/static/media/ico-hfeatured-arrow-left.229053c3.svg");
  background-repeat: no-repeat;
  background-position: center;
  right: 66px;
  left: auto;
}

section.featured__events .react-multi-carousel-dot-list {
  position: absolute;
  top: -62px;
  right: 150px;
  left: auto;
  bottom: auto;
  font-weight: 800;
}

section.featured__events .featured__heading .navigation__featured > a {
  position: relative;
  right: 100px;
}

section.featured__events
.featured__heading
.navigation__featured
> .buttons__featured {
  right: 90px;
  position: relative;
}

section.featured__events .featured__articles {
  flex-wrap: wrap;
}

section.featured__events .featured__heading.d-flex {
  border-bottom: none;
  margin: 0 15px 20px 15px;
}

section.featured__content__home.main
> .container
.featured__articles:last-child {
  border-bottom: none;
}

.boton__date__cta a.card__url > span {
  min-width: 27px;
  min-height: 27px;
  width: 27px;
  height: 27px;
  padding: 1px 8px;
  display: block;
}

.boton__date__cta a.card__url > span > img {
  width: 11px;
}

.boton__date__cta a.card__url {
  font-size: 14px;
  display: flex;
}

.date__place {
  font-weight: 700;
  font-size: 18px;
}

.details__date > .date {
  color: #006ee8;
}

.featured__events article .article__information h2 > a {
  font-size: 25px;
  font-weight: 800;
  line-height: 32px;
}

.featured__events figure.article__image__content {
  margin-bottom: 0;
}

.featured__events .article__information h2 {
  min-height: 100px;
}

.featured__events .article__information {
  border-top: 1px solid #d6d9de;
  border-left: 1px solid #d6d9de;
  border-right: 1px solid #d6d9de;
  border-bottom: 1px solid #006ee8;
  padding: 15px 25px;
  min-height: 190px;
  background: #ffffff;
}

@media (max-width: 990px) {
  .featured__events .article__information {
    min-height: 250px;
  }
}

@media (max-width: 770px) {
  .featured__events .featured__articles > div {
    margin-bottom: 30px;
    padding: 0;
  }

  .featured__events .article__information h2,
  .featured__events .article__information {
    min-height: auto;
  }

  section.featured__events .featured__heading.d-flex {
    border-bottom: none;
    margin: 0 0 20px 0;
  }

  section.featured__events .react-multi-carousel-dot-list {
    right: 132px;
  }
}

@media (max-width: 570px) {
  section.two__columns__videos .article__information {
    padding: 0 !important;
  }

  section.featured__events .react-multi-carousel-dot-list,
  section.featured__events .react-multiple-carousel__arrow {
    display: none !important;
  }
}

/*--------------------------------------------------------------- LASTEST VIDEOS ---------------------------------------------------------------*/

.big__column__articles > article,
.medium__column__articles > article,
.small__column__articles > article {
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
}

.secondary__row > article {
  padding: 0;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
}

.secondary__row > article:last-child,
.big__column__articles > article:last-child,
.medium__column__articles > article:last-child,
.small__column__articles > article:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.secondary__row > article > figure.article__image__content {
  padding-right: 0;
}

.secondary__row > article > .article__information {
  padding-left: 0;
}

section.two__columns__videos .big__column__articles h2 > a {
  font-size: 26px;
  line-height: 30px;
}

section.two__columns__videos .small__column__articles .article__information a {
  font-size: 22px;
  line-height: 27px;
}

.two__columns__videos .small__column__articles {
  padding-right: 0;
  padding-left: 45px;
  flex: 0 0 38%;
  max-width: 38%;
}

.two__columns__videos .big__column__articles {
  border-right: 1px solid #d8d8d8;
  padding-right: 60px;
  flex: 0 0 62%;
  max-width: 62%;
}

section.two__columns__videos .big__column__articles .secondary__lvideo h2 > a {
  font-size: 23px;
  line-height: 28px;
}

@media (max-width: 1050px) {
  section.two__columns__videos
  .big__column__articles
  .secondary__lvideo
  h2
  > a {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 990px) {
  .secondary__row > article > figure.article__image__content,
  .secondary__row > article > .article__information {
    padding: 0;
  }

  .secondary__row > article > figure.article__image__content,
  .secondary__row > article > .article__information {
    padding: 0;
  }

  .secondary__row > article {
    padding: 0 10px;
  }
}

@media (max-width: 770px) {
  .two__columns__videos .big__column__articles {
    padding: 0;
    border: none;
  }

  .secondary__row > article {
    padding: 0;
  }

  .two__columns__videos .small__column__articles {
    padding: 0;
  }
}

/*--------------------------------------------------------------- LASTEST VIDEOS MAIN ---------------------------------------------------------------*/

section.two__columns__videos {
  padding: 40px 0;
}

section.two__columns__videos .container {
  border-bottom: 1px solid #cacaca;
  padding: 0 3.8vw 30px 3.8vw;
}

section.two__columns__videos figure.article__image__content {
  padding: 0;
}

section.two__columns__videos article {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 30px;
  padding-top: 25px;
}

section.two__columns__videos .article__information {
  padding-left: 30px;
}

section.two__columns__videos h3.category__title {
  margin-bottom: 50px;
  font-weight: 700;
  padding-bottom: 10px;
}

section.two__columns__videos article:first-child {
  padding-top: 0;
}

section.two__columns__videos .small__column__articles article {
  padding-bottom: 26px;
  padding-top: 20px;
}

section.two__columns__videos .small__column__articles article:first-child {
  padding-top: 0;
}

section.two__columns__videos.ads .big__column__articles {
  border-right: none;
  flex: 0 0 70%;
  max-width: 70%;
  padding-right: 40px;
}

section.two__columns__videos.ads .small__column__articles {
  flex: 0 0 30%;
  max-width: 30%;
  padding-left: 30px;
}

@media (max-width: 1220px) {
  section.two__columns__videos.ads .big__column__articles {
    flex: 0 0 65%;
    max-width: 65%;
  }

  section.two__columns__videos.ads .small__column__articles {
    flex: 0 0 35%;
    max-width: 35%;
  }
}

@media (max-width: 1020px) {
  section.two__columns__videos.ads .big__column__articles {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media (max-width: 990px) {
  .two__columns__videos .big__column__articles,
  section.two__columns__videos.ads .big__column__articles,
  .two__columns__videos .small__column__articles {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    border-right: none;
  }

  section.two__columns__videos article {
    padding-top: 0;
  }
}

@media (max-width: 560px) {
  section.featured__content__home,
  section.featured__events {
    padding: 20px 0;
  }
}

/*--------------------------------------------------------------- CATEGORY PAGE ---------------------------------------------------------------*/

h1.cat__title {
  font-size: 33px;
  color: #006ee8;
  font-weight: 800;
  background: #dcedfe;
  display: inline-block;
  padding: 0 5px;
}

section.category__tabs > .container {
  padding: 0 3.8vw;
}

.tab__content1 {
  flex: 0 0 47%;
  max-width: 47%;
  padding-left: 0;
  border-right: 1px solid #e4e4e4;
  padding-right: 25px;
}

.tab__content2 {
  flex: 0 0 53%;
  max-width: 53%;
  padding-right: 0;
  padding-left: 25px;
}

.tab__content2 article {
  padding-right: 0;
  padding-left: 5px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 25px;
  margin-bottom: 40px;
}

.tab__content2 article figure.article__image__content {
  padding-right: 0;
}

.tab__content2 article .article__information {
  padding-left: 0;
}

.tab__content2 article:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

section.two__columns__videos.category > .container {
  margin-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
  padding: 0 3.8vw 90px 3.8vw;
}

@media (max-width: 990px) {
  section.two__columns__videos.category article,
  section.two__columns__videos .small__column__articles article:first-child {
    border-bottom: 1px solid #dfdfdf;
    padding-top: 20px;
  }
}

@media (max-width: 769px) {
  section.category__tabs .container {
    padding: 0;
  }

  .tab__content1 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    border: none;
  }

  .tab__content2 {
    padding: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .tab__content2 article {
    border-top: 1px solid #dfdfdf;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 20px;
    margin-top: 10px;
  }

  h1.cat__title {
    margin-left: 20px;
  }

  section.category__tabs .container .tab__content {
    padding: 0 20px;
  }
}

@media (max-width: 575px) {
  .tab__content2 figure.article__image__content > a > img {
    width: 100%;
  }
}

/*--------------------------------------------------------------- SURVEY ---------------------------------------------------------------*/

.survey__box .form-check {
  border: 1px solid #e0e0e0;
  padding: 12px 20px;
  margin-bottom: 10px;
}

.survey__box .form-check.checked {
  border: 2px solid #006ee8;
}

.survey__box .form-check > input {
  margin-left: -10px;
}

.survey__box .form-check > label.form-check-label {
  margin-left: 12px;
  font-size: 17px;
  margin: 0;
}

h4.survey__title {
  margin: 20px 0;
}

/* RADIOS */

.bullet {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bullet input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(159, 159, 159, 0.61);
  -moz-box-shadow: 0px 1px 3px 0px rgba(159, 159, 159, 0.61);
  box-shadow: 0px 1px 3px 0px rgba(159, 159, 159, 0.61);
  border: 1px solid #d4d5d6;
}

.bullet input:checked ~ .checkmark {
  background-color: #ffffff;
  border: 1px solid #006ee8;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.bullet input:checked ~ .checkmark:after {
  display: block;
}

.bullet .checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #006ee8;
}

button.vote__survey {
  margin-top: 30px;
}

@media (max-width: 770px) {
  h4.survey__title,
  .survey__box {
    padding: 0 20px;
  }

  button.vote__survey {
    margin: 20px auto;
  }
}

/*--------------------------------------------------------------- SURVEY RESULTS ---------------------------------------------------------------*/

.result__survey .progress-bar {
  height: 100%;
  background: #006ee8;
  border-radius: 20px;
  border: 1px solid #ffffff;
}

.result__survey .progress {
  border-radius: 20px;
  height: 30px;
  border: 1px solid #dde3f1;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(235, 235, 236, 1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(235, 235, 236, 1);
  box-shadow: 0px 2px 4px 0px rgba(235, 235, 236, 1);
}

span.results__title {
  position: relative;
  left: -50px;
  top: -26px;
  font-size: 25px;
  color: #006ee8;
  font-weight: 700;
  background: #dcedfe;
  padding: 5px 10px;
}

.survey__box.results {
  border: 1px solid #c5d7eb;
  border-radius: 4px;
  padding: 30px 50px;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(126, 129, 134, 0.24);
  -moz-box-shadow: 0px 2px 9px 0px rgba(126, 129, 134, 0.24);
  box-shadow: 0px 2px 9px 0px rgba(126, 129, 134, 0.24);
}

.result__survey span {
  padding-right: 10px;
  font-size: 15px;
}

.result__survey > span {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.result__survey {
  margin-bottom: 20px;
}

/*--------------------------------------------------------------- TOP TRENDING ---------------------------------------------------------------*/

.column__trending__left__inner .small__column__articles > article {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
}

.column__trending__left__inner .small__column__articles > article:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

/*--------------------------------------------------------------- EVENTS & CAROUSEL ---------------------------------------------------------------*/

/* Slider */

/* Arrows */

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 0;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: 0;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  border: 1px solid #d5d5d5;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
  margin: 10px;
  height: auto;
}

.slick-initialized .slick-slide > div,
.slick-initialized .slick-slide > div > div {
  height: 100%;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.items {
  width: 100%;
  margin: 0px auto;
}

.slick-slide img {
  width: 100%;
  border: 0px solid #fff;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -50px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  line-height: 0;
  display: block;
  width: 8px;
  height: 8px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 1px solid #006ee8;
  background: transparent;
  border-radius: 50%;
}

.slick-dots li.slick-active button {
  line-height: 0;
  display: block;
  width: 12px;
  height: 12px;
  padding: 5px;
  border: 1px solid #006ee8;
  background: #006ee8;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

/* End Slider */

.event__information p {
  margin: 0;
}

article.event {
  padding: 15px 20px 20px 20px;
}

article.event p.article__cat__tag {
  display: block;
  margin-bottom: 0;
}

article.event p.article__cat__tag.mobile {
  display: none !important;
}

.article__place__tag {
  background: url(../_images/ico-location.svg) no-repeat left center;
  padding-left: 18px;
  background-size: 12px;
  margin: 0;
  height: 20px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article__date__tag {
  background: url(../_images/ico-date.svg) no-repeat left center;
  padding-left: 18px;
  background-size: 16px;
  margin: 0;
  height: 20px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location__date__container span.article__cat__separator {
  color: #8d8d8d;
  padding: 0 10px;
  font-size: 12px;
  line-height: 20px;
}

.event__information h2 {
  font-size: 18px;
  line-height: 22px;
  border-bottom: 1px solid #006ee8;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.event__information h2 > a {
  color: #021431;
  text-decoration: none;
}

.location__date__container.d-flex {
  margin-bottom: 10px;
}

article.event .article__cat {
  height: 25px;
}

article.event p.article__cat__tag > a {
  font-size: 13px;
}

figure.article__image__content.event {
  position: relative;
  flex: 0 0 40%;
  padding-right: 20px;
  margin: 0;
}

p.event_excerpt {
  font-size: 14px;
  line-height: 19px;
}

section.featured_events .category__title,
section.upcoming_events .category__title,
section.recent_events .category__title {
  border-bottom: 0;
}

section.featured_events .category__title:after,
section.upcoming_events .category__title:after,
section.recent_events .category__title:after {
  content: "";
  display: block;
  width: 100px;
  border-bottom: 2px solid #021431;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 6px;
}

@media (max-width: 1300px) {
  section.featured_events > .container {
    padding: 0 35px;
  }
}

@media (max-width: 770px) {
  section.featured_events > .container {
    padding: 0 10px;
  }

  section.featured_events .horizontal__boxed {
    padding: 0 3vw;
  }

  .event__information h2 {
    font-size: 20px;
    line-height: 24px;
  }

  article.event > .article__cat {
    display: none !important;
  }

  article.event p.article__cat__tag.mobile {
    display: block !important;
    margin: 5px 0;
  }

  article.event p.article__cat__tag > a {
    font-size: 11px;
  }

  figure.article__image__content {
    padding: 0;
  }
}

@media (max-width: 570px) {
  .column__trending__left,
  .big__column__articles,
  .column__trending__left__inner .small__column__articles {
    padding: 0;
  }
}

/*--------------------------------------------------------------- ADS SECTIONS ---------------------------------------------------------------*/

.hotizontal.ad a,
.boxed__ad a {
  display: block;
  text-decoration: none;
}

.hotizontal.ad {
  text-align: center;
  background: #f7f7f7;
  padding: 30px 0;
}

.boxed__ad {
  text-align: center;
  background: #f7f7f7;
  padding: 10px 10px;
}

.boxed__ad img {
  max-width: 300px;
}

.hotizontal.ad a:before,
.boxed__ad a:before {
  content: "ADVERTISEMENT";
  display: block;
  color: #1d1d1d;
  font-size: 0.625rem;
  text-decoration: none;
}

.hotizontal.ad img {
  width: initial;
  max-width: 100%;
}

/*--------------------------------------------------------------- CREATE A POST OVERLAY ---------------------------------------------------------------*/

.modal-content.auto.create__post .new__content {
  margin: 25px;
}

.modal-dialog.create__post {
  max-width: 560px;
}

.modal-content.auto.create__post > div {
  padding-left: 25px;
  padding-right: 25px;
}

.modal-dialog.create__post .profile__btn.user:hover {
  border: 3px solid #ffffff;
}

.modal-dialog.create__post button.close {
  margin-top: 10px;
}

@media (max-width: 659px) {
  .modal-content.auto.create__post .new__content,
  .modal-content.auto.create__post > div > .bt__post {
    display: none;
  }

  .modal-dialog.create__post button.close {
    left: 20px;
    margin-top: -3px;
    margin-left: 0;
    max-width: 24px;
    width: 100%;
  }

  .modal-content.auto.create__post > div {
    padding: 16px 20px;
  }

  .modal-dialog.create__post .mobile__bar .bt__post {
    position: absolute;
    right: 20px;
    display: flex !important;
  }

  .modal-dialog.create__post .mobile__bar .bt__post a.btn {
    width: 84px;
    height: 30px;
    font-size: 15px;
    line-height: 18px;
  }

  .modal-content.auto.create__post > .post__content__btn {
    position: fixed;
    bottom: 0;
  }
}

/*--------------------------------------------------------------- BECOME A FRENETIC ---------------------------------------------------------------*/

section.become__frenetic {
  background: url("../_images/become-frenetic.jpg") no-repeat;
  text-align: center;
  background-position: center 35%;
  background-size: cover;
  margin: 60px 0;
}

.become__frenetic img {
  max-width: 108px;
}

.numbers__tech__box {
  max-width: 600px;
  margin: 20px auto;
}

section.become__frenetic h3 {
  color: #ffffff;
  font-weight: 800;
  margin-bottom: 20px;
}

section.become__frenetic h3:after {
  content: "";
  display: block;
  width: 80px;
  border-bottom: 2px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 2px;
}

section.become__frenetic p {
  font-size: 18px;
  color: #ffffff;
  max-width: 710px;
  margin: 0 auto;
  line-height: 24px;
}

.copy__become {
  font-size: 20px;
  color: #ffffff;
  font-weight: 800;
  padding-left: 10px;
}

/*--------------------------------------------------------------- LATEST NEWS ---------------------------------------------------------------*/

.lastest__news .medium__column__articles {
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}

.row__article__left {
  padding-left: 0;
}

.row__article__right {
  padding: 0;
}

.lastest__news .big__column__articles h2 > a {
  font-size: 26px;
  line-height: 30px;
}

.medium__column__articles h2 {
  font-size: 21px;
  line-height: 26px;
}

.medium__column__articles.small__titles h2 > a {
  text-decoration: none;
  color: #05142f;
  font-size: 18px;
  line-height: 24px;
}

.medium__column__articles h2 > a {
  text-decoration: none;
  color: #05142f;
  font-size: 24px;
  line-height: 28px;
}

@media (max-width: 770px) {
  .lastest__news .big__column__articles {
    padding-right: 0;
  }

  .lastest__news .medium__column__articles,
  .lastest__news .small__column__articles {
    border: none;
    padding: 0;
  }
}

/*--------------------------------------------------------------- TECH PEOPLE ---------------------------------------------------------------*/

.tech__people .small__column__articles .article__information a {
  font-size: 22px;
}

button.load__more {
  position: relative;
  margin: 0 auto;
  display: block;
  border: none;
  background: none;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 60px;
  max-width: 340px;
  width: 100%;
}

button.load__more--reset {
  display: none;
}

button.disabled {
  display: none;
}

button.view {
  display: block;
}

button.load__more > svg {
  top: -2px;
  position: relative;
  margin-right: 10px;
}

button.load__more:hover {
  color: #007aff;
}

button.load__more:hover > svg path {
  fill: #007aff !important;
}

.tech__people > div > div {
  margin-bottom: 40px;
}

@media (max-width: 770px) {
  .tech__people .small__column__articles {
    padding: 0;
  }
}

/*--------------------------------------------------------------- SUSCRIBE ---------------------------------------------------------------*/

section.newsletter .container {
  max-width: 1090px;
  border-top: 2px solid #021431;
  padding: 50px 0;
  border-bottom: 2px solid #006ee8;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
}

.newsletter__box {
  padding: 0 20px;
}

.newsletter__txt > span.highlight__txt {
  font-size: 36px;
  color: #ffffff;
  font-weight: 800;
  display: table;
  line-height: 38px;
}

.newsletter__txt > span {
  font-size: 19px;
  font-weight: 700;
}

button.suscribe {
  width: 30px;
  height: 30px;
  background: url("../_images/ico-newsletter.svg") no-repeat;
  border: none;
  float: right;
  right: 0;
  position: absolute;
  top: 10px;
}

.form__newsletter label.suscribe__terms {
  font-size: 11px;
  padding: 0;
}

.form__newsletter {
  max-width: 480px;
  padding: 0 65px;
}

.newsletter__txt {
  max-width: 550px;
  padding: 15px 0 0 100px;
}

.form__newsletter .form-label-group {
  margin-bottom: 5px;
}

@media (max-width: 920px) {
  .newsletter__txt {
    padding: 15px 0 0 50px;
  }
}

@media (max-width: 815px) {
  .newsletter__txt > span.highlight__txt {
    font-size: 23px;
    margin: 0;
  }

  .newsletter__txt > span {
    font-size: 17px;
    line-height: 19px;
    display: block;
    margin-top: 20px;
  }
}

@media (max-width: 660px) {
  .newsletter__txt {
    display: block;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
  }

  .form__newsletter {
    display: block;
    width: 100%;
    padding: 20px;
    margin: 0 auto;
  }

  section.newsletter .container {
    flex-direction: column !important;
  }

  .newsletter__txt > span.highlight__txt {
    margin: 0 auto;
  }

  .newsletter__txt span {
    margin: 20px auto 0 auto;
  }

  section.newsletter .container {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  section.newsletter .container {
    padding: 20px 0;
  }

  .newsletter__txt {
    text-align: left;
    padding: 0 20px 10px 20px;
  }

  .newsletter__txt span {
    margin: 10px 0 0 0;
    text-align: left;
  }

  .newsletter__txt > span.highlight__txt {
    left: 0px;
    margin: 10px 0 0 0;
  }

  .form__newsletter {
    padding: 0 20px;
  }
}

/*--------------------------------------------------------------- CONTENT INNER LAYOUT ---------------------------------------------------------------*/

.contents__inner > article:last-child {
  margin-top: 90px;
}

.contents__inner > article:first-child {
  margin-top: 0;
}

.article__headline {
  margin-bottom: 15px;
  max-width: 1050px;
  margin: 0 auto 20px auto;
}

.article__headline h1 {
  font-family: "Nunito", sans-serif;
  color: #05142f;
  text-decoration: none;
  font-weight: 700;
  font-size: 38px;
  line-height: 45px;
}

.share__links .icon-links {
  font-size: 15px;
  color: #05142f;
  float: left;
  width: 50%;
  font-weight: 600;
  margin: 10px 0;
  display: flex;
  cursor: pointer;
}

.share__links .icon-links i {
  padding-right: 10px;
  font-size: 18px;
}

.dropdown .share__options span.share__title {
  font-size: 16px;
  border-bottom: 0.5px solid #05142f;
  display: block;
}

.dropdown .share__options {
  width: 460px;
  margin-left: -412px;
  padding: 30px 35px 15px 35px;
  margin-top: 12px;
}

.dropdown .share__options .header__share__box > span {
  margin-bottom: 10px;
  padding: 5px;
}

/* .dropdown .share__options .share__links {
  padding: 3px;
} */

.header__share__box button.close {
  display: none;
  right: 30px;
  top: 20px;
}

.current__article {
  display: inline;
  max-width: 1180px;
  position: fixed;
  z-index: 5;
  background: #ffffff;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 1px solid #021431;
}

.current__article img {
  max-width: 45px;
}

.current__article h2 {
  font-size: 19px;
  padding-left: 15px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Nunito", sans-serif;
}

.article__headline p.article__cat__tag > a {
  color: #006ee8;
}

.progress-bar {
  height: 8px;
}

.position-sticky {
  height: 8px;
  border-radius: 0;
}

.current__article > .current__article__inner {
  padding: 17px 0;
}

section.main__content .container {
  padding: 0;
  max-width: 1050px;
  margin: 30px auto;
}

.article__body {
  padding-left: 0;
}

.comments__counter a > svg {
  margin-right: 5px;
}

.comments__counter {
  margin-left: 15px;
}

.comments__counter a {
  text-decoration: none;
}

.comments__counter a span {
  font-size: 14px;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 85%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.5s;
}

.comments__counter a:hover span {
  background-size: 100% 1px;
}

article h3 {
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 10px;
  display: inline-block;
}

article h2 {
  font-size: 23px;
  margin: 0 0 10px 0;
  font-weight: 600;
}

article .container p {
  font-size: 18px;
  line-height: 33px;
}

article .container p.short__description {
  font-size: 20px;
  line-height: 30px;
  font-weight: 200;
  margin-bottom: 50px;
}

ul.video__topics > li {
  font-size: 18px;
  line-height: 28px;
  border-bottom: 1px solid #d8d8d8;
  padding: 18px 0;
}

ul.video__topics {
  list-style: none;
}

ul.video__topics > li:before {
  height: 6px;
  width: 6px;
  background-color: #021431;
  border-radius: 50%;
  display: inline-block;
  content: "";
  margin-right: 10px;
  top: -2px;
  position: relative;
}

.dropdown .dropdown-list.profile__autor {
  margin-left: -10px;
  margin-top: 0;
  padding: 15px 0 !important;
  display: block;
  position: absolute;
}

.dropdown .dropdown-list.profile__autor:before {
  left: 0;
  margin: 0 20px;
}

.dropdown .dropdown-list.profile__autor .user__info__container {
  padding: 0 20px 15px 20px;
}

.dropdown .dropdown-list.profile__autor li {
  padding: 15px 20px 5px 20px;
  display: block;
  margin: 0;
}

.profile__btn__content a.profile__btn:after {
  content: "";
  display: block;
  width: 0;
  position: relative;
  bottom: 0;
  right: 0;
  top: 102%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.dropdown .dropdown-list.profile__autor span {
  font-size: 15px;
  line-height: 20px;
  display: block;
}

.dropdown .dropdown-list.profile__autor span.title__autor__detail {
  font-weight: 700;
}

.dropdown .dropdown-list.profile__autor span.text__autor__detail {
  font-size: 17px;
}

.dropdown .dropdown-list.profile__autor a.btn.btn-primary {
  width: 100%;
  margin: 0;
  height: 45px;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
}

.dropdown .dropdown-list.profile__autor a.btn.btn-primary:after {
  display: none;
}

.related__topics {
  border-top: 2px solid #e7eaed;
  margin: 30px 0;
  padding: 30px 0 10px 0;
}

.share__mobile {
  border-top: 2px solid #e7eaed;
  margin: 0 30px;
  padding: 20px 0;
  display: none;
}

.share__mobile a {
  margin: 0 9px;
}

.article__body blockquote {
  width: 80%;
  margin: 0 auto 30px auto;
  font-size: 25px;
  line-height: 31px;
  padding: 20px 0;
  font-weight: 600;
}

.article__body blockquote:after {
  content: "";
  display: block;
  width: 20%;
  border-bottom: 2px solid #021431;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 9px;
}

.article__body blockquote > p {
  font-size: inherit !important;
  line-height: inherit !important;
  font-weight: inherit !important;
}

.article__body video {
  width: 100%;
  height: auto;
}

.article__body .videoplayer {
  width: 100%;
}

.article__body iframe {
  width: 100%;
}

.comment__cta {
  text-align: center;
  border-top: 1px solid #e6e6e6;
}

.comment__cta a {
  padding: 20px 0 10px 0;
  display: block;
  font-size: 17px;
}

.comment__cta a {
  padding: 20px 0 10px 0;
  display: block;
  font-size: 17px;
}

.comment__cta > .comments__toggle {
  display: flex;
  padding: 20px 0 10px 0;
}

.comment__cta > .comments__toggle > span {
  display: flex;
}

.comment__cta > .comments__toggle a {
  padding: 0 5px;
}

span.max__rating {
  font-size: 25px;
  color: #006ee8;
  font-weight: 600;
}

.rate__current {
  height: 30px;
}

.big__rate {
  margin-right: 10px;
}

span.rates {
  font-size: 15px;
}

.rate__article > .max__rating {
  padding-right: 15px;
  border-right: 1px solid #cdcbcb;
}

.rate__this {
  padding-left: 15px;
}

.title__rate {
  padding-right: 10px;
}

.rate__article {
  border-top: 2px solid #e7eaed;
  padding: 30px 0;
}

.rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.rating .rating__item {
  overflow: hidden;
  *zoom: 1;
  position: relative;
  float: left;
  margin-right: 0.3rem;
}

.rating .rating__item input {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

.rating .rating__item label {
  background: url(../_images/ico-star-empty.svg);
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background-position: center;
}

.rating .rating__item input:checked ~ label {
  background: url(../_images/ico-star.svg);
}

.rating .rating__item:hover label,
.rating .rating__item:hover ~ .rating__item label {
  background: url(../_images/ico-star.svg);
}

section.read__also {
  background: #f6f6f6;
}

.recommended__articles h2 {
  font-size: 20px;
  line-height: 23px;
}

.shared__overlay {
  display: none;
}

@media (max-width: 1050px) {
  .article__headline {
    padding: 0 20px;
  }

  .article__body {
    padding-left: 20px;
  }

  .current__article__inner .logo__box {
    display: none;
  }

  .current__article__inner .floating__share__box {
    padding-right: 20px;
  }

  .current__article h2 {
    padding-left: 0;
  }
}

@media (max-width: 990px) {
  .recommended__articles article {
    margin-bottom: 20px;
    border-bottom: 1px solid #d5d5d5;
  }
}

@media (max-width: 770px) {
  .article__body {
    padding: 0;
  }

  figure.article__image__content figcaption {
    padding: 20px;
  }

  article .container p {
    padding: 20px 30px;
    font-size: 17px;
    line-height: 28px;
    margin-bottom: 0;
  }

  article .container p.comment__name {
    padding: 0;
  }

  .related__topics {
    padding: 20px 0;
    margin: 0 20px;
  }

  .rate__article {
    padding: 20px 0;
    margin: 0 20px;
  }

  .comments__box {
    margin: 0 20px;
  }

  .comments__toggle span {
    display: none;
  }
}

@media (max-width: 580px) {
  .agenda__event .floating__share__box {
    flex: auto;
    max-width: initial;
    align-items: center;
    justify-content: center !important;
    text-align: center;
    margin: 10px 0 20px 0;
  }

  .agenda__event .floating__share__box .react-share__ShareButton,
  .agenda__event .floating__share__box .icon.copy {
    display: block;
  }

  .floating__share__box > a,
  .floating__share__box .react-share__ShareButton {
    display: none;
  }

  .floating__share__box > a.others__shares {
    display: block;
  }

  .rate__article > .max__rating {
    padding: 0 0 20px 25px;
    border: none;
  }

  .rate__this {
    padding: 0 0 0px 25px;
    border: none;
  }

  .share__links > a {
    float: none;
    width: 100%;
    display: block;
    margin: 25px 10px;
  }

  .dropdown .share__options span.share__title {
    padding-bottom: 10px;
  }

  .dropdown .share__options {
    position: absolute !important;
    width: 95vw;
    margin: 0 auto;
    right: 0 !important;
    z-index: 5;
  }

  .dropdown .share__options .react-share__ShareButton,
  .dropdown .share__options .icon-links {
    display: block;
    width: 100%;
    text-align: center;
  }

  .dropdown .share__options:before {
    display: none;
  }

  .header__share__box button.close {
    display: block;
  }

  .shared__overlay {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    background: #27262685;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .shared__overlay.active {
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .share__mobile {
    border-top: 2px solid #e7eaed;
    margin: 0 30px;
    padding: 20px 0;
    display: block;
  }

  .article__body iframe {
    height: auto;
  }
}

/*--------------------------------------------------------------- FLOATING ARTICLE TOOLBAR ---------------------------------------------------------------*/

.article__headline a.back__btn.mb-3 {
  display: block !important;
}

.share__toolbar {
  -webkit-box-shadow: -5px 0px 5px 0px rgba(111, 110, 110, 0.32);
  -moz-box-shadow: -5px 0px 5px 0px rgba(111, 110, 110, 0.32);
  box-shadow: -5px 0px 5px 0px rgba(111, 110, 110, 0.32);
  background: #ffffff;
  padding: 16px 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.share__flex {
  max-width: 1050px;
  margin: 0 auto;
}

.share__toolbar__container {
  padding: 0;
  max-width: 720px;
}

.share__toolbar__container .interactions {
  padding: 0 40px 0 0;
}

.share__toolbar__container .interactions .comments__btns {
  padding: 0;
}

.share__toolbar__container .comments__rating {
  padding: 0;
}

.share__toolbar .interactions img {
  max-height: 22px;
}

.share__toolbar .interactions a > span {
  font-size: 14px;
  font-weight: 700;
}

.share__toolbar .share__options {
  bottom: 40px;
  margin: 0 -90px;
  width: 250px;
  padding: 20px 25px 20px 25px;
}

.share__toolbar .share__options:before {
  display: none;
}

.share__toolbar .share__options:after {
  content: "";
  display: block;
  width: 20px;
  position: absolute;
  bottom: -10px;
  right: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ffffff;
  margin: 0 120px;
}

.share__toolbar .share__options a {
  float: none;
  width: 100%;
}

@media (max-width: 730px) {
  .share__toolbar {
    padding: 10px 20px 5px 20px;
  }

  .share__toolbar__container .interactions {
    padding: 0;
    border-top: 1px solid #e7eaed;
  }

  .share__toolbar__container .interactions .comments__btns {
    margin-top: 6px;
  }

  .share__toolbar__container .interactions .reply__btn,
  .share__toolbar__container .interactions .interesting__btn .interesting__btn__alt {
    margin: 0;
  }

  .share__toolbar__container .interactions .reply__btn:last-child {
    display: none;
  }

  .share__toolbar__container .comments__rating .reactions__counter {
    display: flex !important;
  }

  .share__toolbar .share__options {
    margin: 0;
    left: auto !important;
  }

  .share__toolbar .share__options:after {
    content: "";
    margin: 0 30px;
  }
}

@media (max-width: 560px) {
  .share__toolbar .share__options {
    width: 100%;
    padding: 10px;
  }

  .share__toolbar .share__options a {
    margin: 10px;
    border-bottom: 1px solid #e2e2e2;
    padding: 10px 0;
  }

  .share__toolbar .share__options a:last-child {
    border: none;
  }
}

/*--------------------------------------------------------------- VIDEO ARTICLE ---------------------------------------------------------------*/

.next__video__box {
  -webkit-box-shadow: 3px 3px 9px 0px rgba(2, 20, 49, 0.23);
  -moz-box-shadow: 3px 3px 9px 0px rgba(2, 20, 49, 0.23);
  box-shadow: 3px 3px 9px 0px rgba(2, 20, 49, 0.23);
  padding: 15px;
  border-bottom: 1px solid #021431;
  margin-bottom: 35px;
}

.next__video__box figure.article__image__content {
  padding-left: 0;
}

.video__level__box {
  border: 1px solid #021431;
  -webkit-box-shadow: 1px 1px 0px 0px rgba(0, 108, 232, 1);
  -moz-box-shadow: 1px 1px 0px 0px rgba(0, 108, 232, 1);
  box-shadow: 1px 1px 0px 0px rgba(0, 108, 232, 1);
  padding: 9px 26px 5px 26px;
}

.video__level__box img {
  margin: 0 0 6px 6px;
}

.video__level__box span {
  font-weight: 700;
  padding-left: 5px;
}

.video__article h1,
.video__article .autor__container,
.video__article .article__body {
  padding: 0;
}

.next__video__box_player {
  position: relative;
  display: inline-table;
}

.article__information .video__level__box {
  position: absolute;
  top: -20px;
  right: 0;
}

article p.video__description {
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
}

.next__video__box .article__information h2 {
  font-size: 20px;
  margin-bottom: 10px;
  display: block;
}

.next__video__box .article__information p {
  font-size: 15px;
  line-height: 20px;
}

.article__information .autor__container {
  height: auto;
  margin-bottom: 10px;
}

.next__level__txt {
  font-size: 18px;
  margin-bottom: 5px;
}

.next__level__txt span {
  font-weight: 700;
  margin-left: 5px;
}

.next__video__box .article__information {
  margin-top: 30px;
}

.next__video__box.no_level .article__information {
  margin-top: 0;
}

@media (max-width: 1050px) {
  article .article__body h3,
  article .article__body .container p {
    padding: 20px 30px;
  }

  .video__article .article__body {
    padding: 0;
  }
}

@media (max-width: 770px) {
  .next__video__box .article__information p {
    padding: 0;
  }
}

@media (max-width: 570px) {
  .video__article .comments__counter.d-flex {
    display: none !important;
  }

  .autor__container .video__level__box > span {
    display: none;
  }

  .article__information .video__level__box {
    display: inline-block;
    position: relative;
    top: 0;
    margin-top: 20px;
  }

  .next__video__box .article__information {
    margin-top: 10px;
  }

  .next__video__box > article {
    padding: 0;
  }

  .next__video__box_player {
    width: 100%;
  }

  .next__video__box_player a > img {
    width: 100%;
  }

  .next__video__box {
    border-bottom: none;
    padding: 20px 22px 40px 22px;
    margin: 0 22px;
  }

  article .article__body h3 {
    display: block;
    padding: 20px 0 10px 0;
    margin: 20px 22px 0 22px;
  }

  article .article__body h3.topics {
    border-top: 1px solid #021431;
    margin: 30px 15px 0 15px;
    padding: 20px 10px 0 10px;
  }

  ul.video__topics {
    padding: 0 35px;
  }

  article .container p {
    padding: 20px 22px;
  }
}

/*--------------------------------------------------------------- PREMIUM OVERLAY ---------------------------------------------------------------*/

body.limit__reached {
  position: fixed;
}

.overlay__limit {
  padding-top: 98px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: url(../_images/limit-effect.png) repeat-x;
}

.free__limit__overlay {
  background: url(../_images/free-limit-bg.png) center top no-repeat;
}

.limit__info {
  text-align: center;
  width: 50%;
  padding: 2.5vw 8vw;
}

.limit__info > span.limit__info__title {
  font-size: 26px;
  font-weight: 700;
  display: block;
  max-width: 300px;
  line-height: 30px;
  margin: 0 auto 20px auto;
}

.limit__info > span.limit__info__detail {
  font-size: 18px;
  margin-bottom: 20px;
  display: block;
}

.limit__info > button {
  max-width: 260px;
  margin: 0 auto;
  padding: 8px;
}

.limit__info > a {
  font-weight: 700;
  margin-top: 20px;
  display: block;
}

.premium__benefits {
  width: 50%;
  padding: 2.5vw 8vw;
  background: url(../_images/premium-image.png) no-repeat 90% center;
}

.premium__benefits > span.benefits__title {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
}

.premium__benefits > ul {
  list-style: none;
  padding: 0 10vw 0 0;
  margin-top: 15px;
}

.premium__benefits > ul > li {
  color: #ffffff;
  background: url(../_images/ico-check-list.svg) no-repeat left 5px;
  padding-left: 25px;
  line-height: 19px;
  margin-bottom: 10px;
}

@media (max-width: 990px) {
  .premium__benefits {
    background: none;
    padding: 2.5vw 5vw;
  }

  .premium__benefits > ul {
    padding: 0;
  }
}

@media (max-width: 600px) {
  .free__limit__overlay {
    background: url(../_images/free-limit-bg-mobile.png) center top no-repeat;
    display: block !important;
  }

  .limit__info {
    display: block !important;
    width: 100%;
    padding: 30px 20px;
  }

  .premium__benefits {
    display: block !important;
    width: 100%;
    padding: 20px;
  }
}

/*--------------------------------------------------------------- COMMENT BOX COMPONENT ---------------------------------------------------------------*/

.comments__box {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(207, 202, 202, 0.37);
  -moz-box-shadow: 0px 2px 4px 0px rgba(207, 202, 202, 0.37);
  box-shadow: 0px 2px 4px 0px rgba(207, 202, 202, 0.37);
}

.comments__heading__box {
  border: 1px solid #e6e6e6;
  padding: 10px 0;
}

.comments__title__box {
  border-left: 3px solid #006ee8;
  padding: 14px 0 14px 20px;
}

.comments__toggle {
  padding: 14px 30px 14px 0;
}

.comments__container {
  border: 1px solid #e6e6e6;
  padding: 35px 65px 10px 65px;
}

.comments__toggle > a > svg {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin-left: 10px;
}

.new__comment {
  margin-bottom: 20px;
}

.comment__container {
  /* margin-bottom: 20px; */
}
.comment__li ul li .delete__btn {
  padding: 10px 70px;
}


.comments__title__box > span {
  font-size: 20px;
  font-weight: 700;
  color: #141414;
  border-right: 1px solid #dfdfdf;
  padding-right: 15px;
}

.comments__counter__box > img {
  max-width: 20px;
  margin-right: 10px;
}

.comments__counter__box span {
  font-size: 12px;
}

.comments__counter__box {
  margin-left: 15px;
}

.comments__toggle a {
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.comments__toggle a > span {
  color: #006ee8;
}

.comments__toggle.open a > svg {
  transform: rotate(180deg);
}

.form__comment {
  width: 100%;
  margin-left: 10px;
}

.form__comment input {
  background: #ffffff;
  border-top: 1px solid #979ba0;
  border-left: 1px solid #979ba0;
  border-right: none;
  border-bottom: 1px solid #979ba0;
  padding: 10px 0 10px 15px;
  width: 100%;
  -webkit-appearance: none;
}

.form__comment input::-webkit-input-placeholder {
  /* Edge */
  color: #4d4d4d;
}

.form__comment input:-ms-input-placeholder {
  /* Internet Explorer */
  color: #4d4d4d;
}

.form__comment input::placeholder {
  color: #4d4d4d;
}

.form__comment button {
  background: #ffffff;
  border: 1px solid #979ba0;
  padding: 10px 15px;
  margin: 0;
  position: relative;
  width: 120px;
}

.form__comment button:hover {
  background: #f6f9ff;
  border: 1px solid #006ee8;
  color: #006ee8;
}

.interesting__btn a > img {
  margin-right: 5px;
}

.interesting__counter a > img {
  margin-right: 5px;
}

.interesting__btn {
  margin-right: 15px;
}

.interesting__btn span {
  font-size: 13px;
}
.interesting__btn__alt span {
  font-size: 13px;
}

.comments__btns a,
.reactions__counter a {
  text-decoration: none;
  padding: 5px;
}

.comments__btns a:hover {
  background: #e9f3ff;
}

.reactions__counter a span {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 85%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size 0.5s;
}

.reactions__counter a:hover span {
  color: #0056b3;
  background-size: 100% 1px;
}

.comment__counter span {
  top: 1px;
  position: relative;
}

.reply__btn a > img {
  margin-right: 5px;
}

.reply__btn {
  margin-right: 15px;
}

.reply__btn span {
  font-size: 13px;
}
.delete__btn a > img {
  margin-right: 5px;
  height: 22px;
  
}

.delete__btn {
  margin-right: 15px;
}


.delete__btn span {
  font-size: 13px;
}

.comments__list > ul {
  padding: 0;
  list-style: none;
}

.comment__content {
  padding: 15px 20px;
  background: #f2f3f4;
}

.comment__content__inner {
  color: #585a5e;
  font-size: 14px;
  line-height: 18px;
}

.comment__autor.row {
  margin: 0;
}

p.comment__name > a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: #021431;
}

span.comment__autor__separator {
  padding: 0 5px;
  font-size: 10px;
  line-height: 22px;
  color: #006ee8;
  height: 20px;
}

time.comment__hour__tag {
  font-size: 13px;
  color: #565656;
  line-height: 22px;
  position: relative;
}

p.comment__name {
  line-height: 20px !important;
  margin-bottom: 0;
}

.reactions__counter .interesting__btn {
  margin-right: 0;
}

.reactions__counter .interesting__btn .interesting__btn__alt span,
.interesting__counter > a > span,
.comment__counter > a > span {
  font-size: 14px;
}

.comment__container > .profile__image {
  margin-right: 10px;
}

.reactions__functions {
  padding: 10px 20px;
}

.comments__list > ul > li > ul {
  padding-left: 50px;
  list-style: none;
}

.profile__image > img {
  max-width: 36px;
  border-radius: 50px;
  min-width: 36px;
}

.inactive {
  display: none !important;
}

.comment__text {
  width: 100%;
}

.reactions__counter.responsive {
  display: none !important;
}

@media (max-width: 560px) {
  .main__content h1 > a {
    font-size: 30px;
    line-height: 36px;
  }

  .comments__container {
    padding: 35px 20px 10px 20px;
  }

  .reactions__counter {
    display: none !important;
  }

  .reactions__counter.responsive {
    display: flex !important;
    margin-top: 10px;
  }

  .comment__content__inner {
    margin-top: 10px;
  }

  .form__comment input {
    font-size: 14px;
  }

  .form__comment button {
    font-size: 14px;
  }

  .interesting__btn {
    margin: 0;
  }
}

/*--------------------------------------------------------------- HOME EVENTS ---------------------------------------------------------------*/

section.featured__event {
  padding: 3vh 3vw;
}

.featured__event .featured__image {
  height: 350px;
}

.featured__image {
  background: url("../_images/featured-event.jpg");
  -webkit-box-shadow: 0px 3px 0px 0px rgba(2, 20, 49, 1);
  -moz-box-shadow: 0px 3px 0px 0px rgba(2, 20, 49, 1);
  box-shadow: 0px 3px 0px 0px rgba(2, 20, 49, 1);
  background-size: 150%;
  background-position: center right;
  height: 440px;
}

.description__featured {
  background: url(../_images/event-bg.png) no-repeat #006ee8;
  padding: 30px 30px 30px 50px;
  -webkit-box-shadow: 0px 3px 0px 0px rgba(2, 20, 49, 1);
  -moz-box-shadow: 0px 3px 0px 0px rgba(2, 20, 49, 1);
  box-shadow: 0px 3px 0px 0px rgba(2, 20, 49, 1);
  background-size: cover;
}

.description__featured h1 {
  color: #ffffff;
  font-size: 36px;
  line-height: 44px;
}

.description__featured h1:after {
  content: "";
  display: block;
  width: 20%;
  border-bottom: 2px solid #ffffff;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 5px;
}

.event__date {
  display: block;
  padding: 0 5px;
  margin: 5px 0;
}

.event__date span.event__location {
  background: none;
  color: #ffffff;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
}

.event__date span.event__date {
  background: none;
  color: #ffffff;
  padding: 0;
  margin: 0;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
}

.event__cta {
  display: inline-flex;
  padding: 25px 0 15px 0;
}

.event__cta > a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border: 1.5px solid #ffffff;
  padding: 9px 20px;
  margin-right: 15px;
  min-width: 168px;
  text-align: center;
  text-decoration: none;
}

.event__cta > a:hover {
  color: #021431;
  border: 1.5px solid #ffffff;
  background: #ffffff;
}

section.event__search {
  background: #f9f9f9;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

section.event__search span.search__title {
  font-size: 20px;
  color: #006ee8;
  font-weight: 700;
}

section.event__search .form-label-group {
  max-width: 240px;
  width: 100%;
  margin: 0 15px;
}

section.event__search button {
  max-width: 170px;
  width: 100%;
  height: 45px;
  line-height: 16px;
}

section.event__search .form-label-group select {
  height: 45px;
  padding: 0;
}

section.event__search form.d-flex {
  max-width: 700px;
  width: 100%;
}

.event__upcoming h2 {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 0px;
}

.cards__boxed article,
.recents__rows article {
  height: 100%;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  padding: 0;
  -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 110, 232, 1);
  -moz-box-shadow: 0px 2px 0px 0px rgba(0, 110, 232, 1);
  box-shadow: 0px 2px 0px 0px rgba(0, 110, 232, 1);
}

.cards__boxed article figure.article__image__content.event,
.recents__rows article figure.article__image__content.event {
  padding: 0;
}

.cards__boxed article .event__upcoming,
.recents__rows article .event__upcoming {
  padding: 10px 20px;
}

.cards__boxed article .event__upcoming .location__date__container.d-flex,
.recents__rows article .event__upcoming .location__date__container.d-flex {
  background: #f7f7f7;
  padding: 10px 5px;
}

.cards__boxed > div {
  width: 32%;
}

section.recent_events {
  background: #f7f7f7;
  padding: 60px 0;
}

section.upcoming_events {
  padding: 60px 0;
}

section.featured_events,
section.upcoming_events .cards__boxed {
  margin: 0 auto;
}

section.featured_events .horizontal__boxed {
  margin: 0 auto 30px auto;
}

section.upcoming_events .category__title,
section.recent_events .category__title,
section.featured_events .category__title {
  margin: 0 auto 20px auto;
}

.recents__rows {
  padding: 0 15px;
  margin: 0 auto;
}

.recents__rows.row > div {
  width: 32%;
  margin-bottom: 70px;
}

@media (max-width: 990px) {
  .cards__boxed > div,
  .recents__rows.row > div {
    width: 100%;
  }

  .event__cta > a {
    display: block;
    margin-bottom: 10px;
    min-width: 100%;
  }

  .event__cta {
    display: block;
    padding: 25px 0 0 0;
  }

  .featured__event .featured__image {
    height: auto;
  }
}

@media (max-width: 769px) {
  .recents__rows {
    padding: 0;
    margin: 0;
  }

  .featured__event .container {
    padding: 0;
    display: block !important;
  }

  .featured__event .featured__image {
    height: 55vw;
  }

  section.featured__event {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }

  .description__featured {
    padding: 20px 30px 20px 30px;
  }

  section.featured_events {
    padding-top: 0;
  }

  section.featured_events .category__title,
  section.upcoming_events .category__title,
  section.recent_events .category__title {
    font-size: 21px;
    padding: 0 15px;
  }

  section.featured_events .slick-slide {
    margin: 0;
  }

  section.featured_events .slick-slide article.event {
    padding: 0;
  }

  section.featured_events .slick-slide figure.article__image__content.event {
    padding: 0;
  }

  section.featured_events .slick-slide .event__information {
    padding: 20px;
  }

  section.event__search .form-label-group {
    max-width: 100%;
    margin: 0 0 30px 0;
  }

  section.event__search button {
    max-width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 580px) {
  section.event__search * {
    display: block !important;
  }

  section.event__search > .container {
    padding: 20px 30px;
  }

  section.featured_events {
    margin: 0 auto;
  }

  section.upcoming_events .cards__boxed {
    padding: 0;
  }
}

/*--------------------------------------------------------------- EVENT PAGE ---------------------------------------------------------------*/

section.event__content {
  padding: 30px 20px;
}

section.upcoming_events.event__detail {
  background: #f7f7f7;
}

.event__headline .featured__image.col-8 {
  background-size: 100%;
  margin-bottom: 25px;
}

.event__headline .description__featured {
  background: url(../_images/event-detail-bg.svg) no-repeat bottom;
  padding: 30px 30px 60px 30px;
  background-size: cover;
  box-shadow: none;
  height: 465px;
}

span.main__title__event {
  background: #0063d0;
  font-size: 25px;
  color: #ffffff;
  font-weight: 700;
  display: block;
  padding: 3px 10px;
  margin-bottom: 15px;
}

span.event__location {
  background: url(../_images/ico-event-location.svg) no-repeat 10px center;
  color: #ffffff;
  padding-left: 20px;
  margin: 0 15px 0 5px;
  padding: 10px 0 10px 40px;
  display: block;
}

.location__event,
.event__start,
.event__end {
  border-bottom: 1px solid #318aec;
}

.event__contact {
  margin-bottom: 15px;
}

.event__headline .description__featured strong {
  color: #ffffff;
}

span.event__date {
  background: url(../_images/ico-event-date.svg) no-repeat 7px 35%;
  color: #ffffff;
  padding: 5px 0 5px 40px;
  margin: 5px;
}

span.event__mail {
  background: url(../_images/ico-event-mail.svg) no-repeat 5px center;
  color: #ffffff;
  padding-left: 20px;
  margin: 0 15px 0 5px;
  padding: 10px 0 10px 40px;
  display: block;
}

.events__tickets a {
  max-width: 300px;
  width: 95%;
  display: block;
  margin: 0 auto;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border: 1.5px solid #ffffff;
  padding: 8px 20px;
  text-align: center;
}

.event__recomendations {
  background: #f1f8ff;
  padding: 40px 30px;
}

.event__recomendations > span {
  display: block;
}

span.recomentations__title {
  margin: 0 auto 30px auto;
  font-size: 25px;
  line-height: 31px;
  font-weight: 600;
}

span.recomentations__title:after {
  content: "";
  display: block;
  width: 20%;
  border-bottom: 2px solid #021431;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 9px;
}

section.main__content .event__headline .container {
  margin: 15px auto 0 auto;
}

section.main__content .container.event__contain {
  margin: -22px auto 30px auto;
  z-index: 1;
  position: relative;
}

.event__headline {
  z-index: 2;
  position: relative;
}

section.main__content .container.event__contain .article__body {
  padding: 40px 30px;
}

section.main__content .container.event__contain .article__body h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.related__topics.tags a.quick__cats__btn.topics {
  min-width: auto;
  text-transform: uppercase;
}

.related__topics.tags {
  margin: 10px 0;
  border-top: 0;
  padding: 10px 0;
}

.agenda__event > span {
  border-top: 2px solid #021431;
  display: block;
  font-size: 17px;
  font-weight: 800;
}

.agenda__event > span {
  border-top: 2px solid #021431;
  display: block;
  font-size: 17px;
  font-weight: 800;
  padding: 10px;
}

.floating__share__box {
  align-items: center;
}

.agenda__event .floating__share__box .favorite,
.agenda__event .floating__share__box .others__links {
  display: none;
}

.share_download {
  padding: 0 10px;
}

.article__headline .floating__share__box {
  position: relative;
}

.messager-copy {
  position: absolute;
  top: 0;
  left: -70px;
}

.messager-copy span {
  color: #528949;
  font-weight: bold;
}

.floating__share__box .icon.favorite,
.floating__share__box .icon.copy,
.floating__share__box button .icon {
  width: 31px;
  height: 31px;
  background: #021431;
  border-radius: 50%;
  padding: 10px;
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin: 0 3px;
}

.floating__share__box .others__links {
  margin: 0 3px;
}

.floating__share__box button a {
  margin: 0;
  color: #05142f;
}

.floating__share__box .icon.favorite {
  padding: 5px 0 0;
}

.floating__share__box .icon.favorite .mark__border {
  fill: #ffffff;
}

.floating__share__box .selected,
.floating__share__box .icon:hover {
  background: #006ee8;
  color: #ffffff;
}

.floating__share__box a.others__shares,
.floating__share__box a.others__shares2,
.floating__share__box a.others__shares3 {
  width: 31px;
  height: 31px;
  background: #ebf2fa;
  border-radius: 50%;
  padding: 9px;
  color: #021431;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
}

.floating__share__box a.others__shares:hover,
.floating__share__box a.others__shares2:hover,
.floating__share__box a.others__shares3:hover {
  background: #006ee8;
  color: #ffffff;
}

.slick-list figure.event img,
.recent_events figure.event img {
  width: 100%;
}

.slick-list figure.article__image__content,
.recent_events figure.article__image__content {
  width: 100%;
}

button.agenda__btn {
  background: #ffffff;
  border: 1px solid #006ee8;
  color: #006ee8;
  padding: 10px 20px;
}

button.agenda__btn {
  background: #ffffff;
  border: 1.5px solid #006ee8;
  color: #006ee8;
  padding: 10px 20px;
  max-width: 260px;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
}

button.agenda__btn:hover {
  background: #ebf4ff !important;
  border: 1.5px solid #006ee8;
  color: #006ee8 !important;
  border-color: #006ee8 !important;
}

.event__headline h1 {
  max-width: 1050px;
  margin: 20px auto 0 auto;
}

.event__headline h1 > a {
  font-size: 30px;
}

.event__headline .breadcrumbs {
  max-width: 1050px;
  margin: 0 auto;
  font-size: 13px;
  border-bottom: 1px solid #021431;
  padding-bottom: 5px;
}

.event__headline .breadcrumbs a.b__main,
.event__headline .breadcrumbs span.b__separator {
  margin-right: 5px;
}

.category__event > a {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
}

.category__event {
  padding: 0 20px;
  display: none;
}

@media (max-width: 769px) {
  section.main__content.event__content {
    padding: 30px 0;
  }

  .event__headline h1 > a {
    font-size: 28px;
    line-height: 38px;
  }

  .category__event {
    display: block;
  }

  .event__headline h1 {
    padding: 0 20px;
    margin-top: 5px;
  }

  .main__content.event__content .featured__image {
    min-height: 250px;
  }

  section.main__content .container.event__contain .article__body {
    padding: 40px 0 0 0;
  }

  section.main__content .container.event__contain {
    margin-bottom: 0;
  }

  .related__topics.tags {
    padding: 0 25px;
  }

  section.main__content .container.event__contain .article__body h3 {
    font-size: 19px;
    padding: 0 30px;
    margin-bottom: 0;
  }

  .share__event {
    margin: 10px 0 20px 0;
  }

  .share__event .floating__share__box > a {
    display: block;
  }

  section.main__content .container.event__contain .article__right__ads {
    -webkit-box-shadow: 0px 2px 0px 0px rgba(186, 219, 255, 1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(186, 219, 255, 1);
    box-shadow: 0px 2px 0px 0px rgba(186, 219, 255, 1);
    padding: 0;
  }

  .agenda__event {
    padding: 0 30px;
    text-align: center;
  }
}

/*--------------------------------------------------------------- PROFILE DASHBOARD ---------------------------------------------------------------*/

section.profile__page {
  background: #f7f7f7;
  border-bottom: 4px solid #006ee8;
  overflow: hidden;
}

.profile__menu .tabs__container {
  border: none;
}

ul.profile__tabs {
  display: block;
}

ul.profile__tabs a {
  font-size: 15px;
  font-weight: 600;
  text-decoration: none !important;
}

ul.profile__tabs > li {
  padding: 2px 30px;
  border-radius: 20px;
  display: table;
  margin: 16px auto;
  text-align: center;
}

ul.profile__tabs > li .icon-followers svg path {
  fill: black;
}

ul.profile__tabs > li:hover {
  padding: 2px 30px;
  border-radius: 20px;
  display: table;
  margin: 16px auto;
}

ul.profile__tabs > li.active {
  background: #021431;
}

ul.profile__tabs > li.active .icon-followers svg path {
  fill: white;
}

ul.profile__tabs > li.active span,
ul.profile__tabs > li.active *,
ul.profile__tabs > li.active:hover span {
  color: #ffffff;
  font-weight: 700;
}

ul.profile__tabs > li span {
  color: #021431;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

ul.profile__tabs > li:hover span {
  color: #006ee8;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.add__content {
  display: block;
  text-align: center;
  text-decoration: none !important;
}

a.add__content img {
  display: block;
  margin: 0 auto;
}

.profile__menu a.add__content > img {
  max-width: 45px;
}

a.add__content > span {
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.add__content:hover span {
  color: #006ee8;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.add__content > .button__create {
  background: #006ee8;
  width: 45px;
  height: 45px;
  text-align: center;
  margin: 0 auto;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(143, 143, 143, 0.5);
  -moz-box-shadow: 0px 2px 4px 0px rgba(143, 143, 143, 0.5);
  box-shadow: 0px 2px 4px 0px rgba(143, 143, 143, 0.5);
  border-radius: 50%;
  max-width: 45px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.add__content:hover > .button__create {
  background: #0c62c1;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.add__content > .button__create > img {
  position: relative;
  top: 12px;
  max-width: 23px;
  margin-left: 13px;
}

.profile__header {
  border-right: 1px solid #dcdde0;
  border-left: 1px solid #dcdde0;
  border-bottom: 1px solid #dcdde0;
  border-top: 2px solid #006ee8;
  border-radius: 3px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  padding: 30px 45px;
  margin-bottom: 15px;
}

#useAvatar{
  width: 100%;
}
.profile__main__image {
  border-radius: 50%;
  max-width: 145px;
  max-height: 145px;
  width: 100%;
  height: auto;
  padding: 0px;
  overflow: hidden;
  position: relative;
  display: inline-flex;
}

.profile__main__image > .update {
  background: #021431d4;
  height: 50%;
  position: absolute;
  width: 100%;
  bottom: 0;
  opacity: 0;
  text-align: center;
}

.profile__main__image > .update > img {
  margin: 10% auto;
}

.profile__main__image:hover > .update {
  opacity: 1;
}

h1.user__name {
  font-size: 23px;
  margin: 0;
  padding-right: 10px;
  font-weight: 800;
}

h1.user__name span.main__name {
  color: #006ee8;
  background: #dcedfe;
  padding: 0 4px;
}

a.edit > img {
  min-width: 16px;
}

a.edit > img {
  top: 5px;
  left: 8px;
  position: relative;
}

.profile__information {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 30px;
}

.profile__information span.ocuppation {
  margin: 2px 0 5px 0;
  display: block;
}

.profile__main__image > a {
  display: block;
  position: relative;
}

.profile__overlay {
  background: #021431b8;
  width: 100%;
  height: 50%;
  position: absolute;
  border-radius: 0px 0px 80px 80px;
  -moz-border-radius: 0px 0px 80px 80px;
  -webkit-border-radius: 0px 0px 80px 80px;
  border: 0px solid #000000;
  bottom: 0;
  opacity: 0;
}

.profile__main__image > a:hover > .profile__overlay {
  opacity: 1;
}

.profile__overlay > img {
  text-align: center;
  top: 15px;
  left: 65px;
  position: absolute;
}

.profile__overlay > span {
  color: #ffffff;
  text-align: center;
  top: 35px;
  left: 45px;
  position: relative;
}

span.f__counter {
  margin-right: 5px;
  font-weight: 700;
}

.followers {
  margin-right: 15px;
}

.followers > a,
.follows > a {
  color: #021431;
}

h2.dashboard__title {
  font-size: 17px;
  margin-bottom: 30px;
}

h2.dashboard__title:after {
  content: "";
  display: block;
  width: 25px;
  border-bottom: 2px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 5px;
}

.profile__dashboard {
  border: 1px solid #dcdde0;
  border-radius: 3px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  padding: 0;
  margin-bottom: 30px;
}

.articles__counter {
  border-top: 1px solid #d6d9de;
  border-left: 1px solid #d6d9de;
  border-bottom: 1px solid #d6d9de;
  border-radius: 1px;
  -webkit-box-shadow: 1px 0px 4px 0px rgba(214, 217, 222, 0.36);
  -moz-box-shadow: 1px 0px 4px 0px rgba(214, 217, 222, 0.36);
  box-shadow: 1px 0px 4px 0px rgba(214, 217, 222, 0.36);
  padding: 20px;
  margin: 0 !important;
}

.articles__counter.articles {
  width: 12%;
}

.articles__counter.posted {
  width: 13%;
  border-right: 1px solid #d6d9de;
}

.articles__counter span.big__counter {
  display: block;
  font-size: 34px;
  font-weight: 800;
  color: #021431;
}

.articles__counter > a {
  display: block;
  background: url(../_images/ico-see-more.svg) no-repeat 95% 85%;
  font-size: 15px;
  padding: 0;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.articles__counter > a:hover {
  background: url(../_images/ico-see-more.svg) #f6f9fd no-repeat 95% 85%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.articles__counter span {
  color: #006ee8;
}

.details__profile h3 {
  font-size: 17px;
  margin-bottom: 20px;
}

.profile__footer > .languajes > ul {
  list-style: none;
  display: flex;
  padding-left: 30px;
  margin-bottom: 5px;
}

.profile__footer > .languajes > ul > li {
  margin-right: 10px;
}

.profile__footer > .languajes > ul > li > a {
  font-size: 14px;
  color: #858c98;
}

.profile__footer > .languajes > ul > li.current > a {
  color: #021431;
}

.details__profile {
  border-left: 1px solid #d6d9de;
  margin: 30px;
}

.details__profile > div {
  padding-left: 20px;
  margin-bottom: 50px;
}

.details__profile > div:before {
  content: "";
  width: 17px;
  height: 17px;
  background: #d6d9de;
  display: block;
  position: absolute;
  margin-left: -30px;
  margin-top: 0px;
  border-radius: 50%;
  border: 3px solid #ffffff;
}

a.edit {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a.edit:hover {
  background: #f6f9fd;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.detail_header a.edit {
  top: -10px;
  position: relative;
}

.detail_header {
  margin-bottom: 10px;
}

.details__profile a.more > img {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.profile__others__links ul {
  list-style: none;
  display: flex;
  padding-left: 30px;
  margin-bottom: 5px;
}

.profile__others__links ul li:first-child::before {
  content: "";
}

.profile__others__links ul li::before {
  content: "\2022";
  color: #616978;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.profile__others__links ul > li {
  margin-right: 25px;
  font-size: 13px;
}

.profile__others__links ul > li > a {
  color: #616978;
}

span.copyright {
  padding-left: 30px;
  font-size: 13px;
  color: #616978;
}

.user__followers.col-3 {
  padding-right: 0;
}

.profile__page .col-3 .user__followers {
  margin-top: 30px;
}

.profile__page .user__followers.col-3 .profile__tabs > li,
.public__f.col-4 .profile__tabs > li{
  margin: 16px 10px;
  padding: 20px 30px;
}

.profile__page .user__followers.col-3 .profile__tabs > li .followers__title,
.public__f.col-4 .profile__tabs > li .followers__title{
  padding: 0;
}

h3.followers__title {
  font-size: 19px;
  font-weight: 700;
  clear: both;
  padding-left: 25px;
}

h3.followers__title > span {
  font-size: 14px;
  font-weight: 600;
  color: #006ee8;
  padding-left: 5px;
}

a.more__followers {
  background: url(../_images/ico-see-all.svg) no-repeat right 60%;
  padding-right: 20px;
  float: right;
  margin: 10px 30px 30px 0;
  font-size: 14px;
}

.following__content {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0 20px 20px;
  display: flex;
}

.following__content .profile__btn.user {
  background-color: transparent;
}

.following__content .article__autor {
  display: none;
}

.following__content > span {
  margin: 5px calc(10% - 29px);
  display: inline-block;
  border: 2px solid #f7f7f7;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.following__content > span:hover {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.following__content > span > img {
  max-width: 40px;
}

footer.profile__footer {
  border: none;
  height: initial;
  margin-top: 20px;
  text-align: left;
}

.articles {
  margin: 30px 25px;
}

.details__profile p {
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 5px;
}

.details__profile a.more {
  font-size: 15px;
  text-decoration: underline;
  font-weight: 600;
}

.certification_detail {
  background: #fdfdfd;
  border: 1px solid #eff1f3;
  padding: 12px;
  margin-bottom: 8px;
}

.modal-dialog.shared .modal-content.share__options {
  padding: 80px 30px 2vw 30px;
}

.share__card {
  text-align: left;
  background: url(../_images/ico-share-card.svg) #ffffff no-repeat;
  background-position: 95% center;
  border-right: 1px solid #dcdde0;
  border-left: 1px solid #dcdde0;
  border-bottom: 1px solid #dcdde0;
  border-top: 2px solid #006ee8;
  border-radius: 3px;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  padding: 20px 45px;
  margin-bottom: 35px;
}

.share__card > a:hover {
  text-decoration: none !important;
}

.share__card:hover {
  background: url(../_images/ico-share-card.svg) #f6f9ff no-repeat;
  background-position: 95% center;
  border-right: 1px solid #006ee8;
  border-left: 1px solid #006ee8;
  border-bottom: 1px solid #006ee8;
}

.share__card h2.highlighted > img {
  margin-right: 10px;
}

.share__card p {
  padding-right: 2.2rem;
  font-size: 17px;
  line-height: 22px;
}

@media (max-width: 800px) {
  ul.profile__tabs > li {
    display: inline-block;
  }

  ul.profile__tabs > li {
    padding: 0 !important;
    margin: 9px 0 !important;
    display: inline-block !important;
  }

  .profile__tabs.draggable.nav > li > a {
    padding: 5px 13px !important;
  }

  .tabs__container .draggable.nav > li > a {
    padding: 10px 13px;
  }

  .profile__menu,
  .profile__body {
    display: block;
    max-width: 100%;
  }

  section.profile__page .container {
    display: block !important;
  }

  .add__container {
    display: none;
  }

  ul.profile__tabs.nav.community__tabs > li {
    width: 32%;
  }

  ul.profile__tabs.nav.community__tabs {
    display: block ruby;
    white-space: nowrap;
  }

  .modal-dialog.shared .modal-content.share__options {
    border-top: none;
    padding: 0;
  }

  .modal-dialog.shared .modal-content.share__options .share__options {
    padding: 10vh 30px 0 30px;
  }
}

@media (max-width: 500px) {
  .profile__dashboard {
    padding: 0 0 30px 0;
  }

  .profile__header {
    margin-top: 80px;
    height: 210px;
  }

  .detail__header {
    margin-top: 80px;
    height: 210px;
  }
 

  .profile__main__image {
    text-align: center;
    margin-top: -90px;
    position: relative;
    width: 115px;
    height: 115px;
    margin-bottom: 25px;
  }

  .profile__main__image > img {
    max-width: 115px;
  }

  .profile__information {
    padding: 0;
    text-align: center;
    margin-top: 0;
    position: relative;
  }

  .profile__name,
  .user__follows {
    justify-content: center;
  }

  section.profile__page .container {
    padding: 0;
  }

  .profile__menu {
    padding: 0;
  }

  ul.profile__tabs {
    display: block ruby;
    white-space: nowrap;
  }

  ul.profile__tabs > li:first-child {
    margin-left: 15px !important;
  }

  ul.profile__tabs > li:last-child {
    margin-right: 15px !important;
  }

  .profile__body {
    padding: 0;
  }

  .profile__header {
    margin: 60px 10px 15px 10px;
    height: 200px;
    display: block !important;
    text-align: center;
  }

  .detail__header {
    margin: 60px 2px 15px 2px;
    height: 200px;
    display: block !important;
    text-align: center;
    
  }

  .articles__counter > a {
    font-size: 14px;
    line-height: 15px;
    padding-right: 10px;
    background-position: 90% 85%;
  }

  .articles__counter span.big__counter {
    font-size: 27px;
    height: 28px;
    display: block;
  }

  .interest a.quick__cats__btn.topics {
    min-width: auto;
  }

  .profile__others__links ul {
    display: inline-block;
  }

  .profile__others__links ul > li {
    float: left;
  }

  .articles__counter {
    text-align: center;
    padding: 30px 0 10px 0;
    width: 33.33%;
  }

  .articles__counter span {
    display: block;
    max-width: 120px;
    margin: 0 auto;
  }

  .share__card > h2.highlighted {
    font-size: 22px;
    line-height: 36px;
  }

  .share__card > p {
    font-size: 14px;
    line-height: 19px;
  }

  .share__card {
    padding: 20px 0 20px 30px;
  }

  .share__card h2.highlighted {
    font-size: 24px;
  }
}

/*--------------------------------------------------------------- PROFILE NO PUBLISHED CONTENT ---------------------------------------------------------------*/

.profile__header.no__published > img {
  max-width: 250px;
  margin-bottom: 30px;
}

.add__container.no__published {
  background: #f1f8ff;
  border: 1px dashed #b5b9be;
  padding: 20px 0;
}

section.profile__page.empty {
  height: 100vh;
}

.profile__header.no__published {
  padding: 30px 6vw 6vw 6vw;
}

.add__container.no__published a.add__content .button__create {
  max-width: 65px;
  width: 65px;
  height: 65px;
}

.add__container.no__published a.add__content .button__create img {
  top: 18px;
  max-width: 30px;
}

.add__container.no__published a.add__content span {
  font-size: 19px;
  font-weight: 600;
  margin-top: 5px;
  display: block;
}

.add__container.no__published a.add__content .button__create img {
  max-width: 35px;
  width: 100%;
  top: 15px;
  margin: 0 18px;
}

.profile__header span.no__published {
  text-align: center;
  font-size: 22px;
  font-weight: 800;
  margin-bottom: 30px;
}

.profile__header > img {
  margin: 0 auto;
  display: block;
  margin-bottom: 50px;
}

@media (max-width: 799px) {
  .profile__body.no__content {
    padding: 0 20px;
  }

  .profile__header.no__published {
    width: 100%;
    margin: 0;
    height: auto;
    padding: 30px 7vw 15vw 7vw;
  }

  .profile__header span.no__published {
    max-width: 80%;
    margin: 0 auto 30px auto;
    line-height: 28px;
  }

  .profile__header > img {
    max-width: 70%;
    margin-bottom: 30px;
  }

  .add__container.no__published {
    display: block;
  }
}

/*--------------------------------------------------------------- FLOATING PROFILE MENU ---------------------------------------------------------------*/

.floating__profile__nav {
  display: none !important;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(85, 107, 143, 0.35);
  -moz-box-shadow: 0px 0px 11px 0px rgba(85, 107, 143, 0.35);
  box-shadow: 0px 0px 11px 0px rgba(85, 107, 143, 0.35);
}

.floating__nav__item > a {
  display: block;
  text-align: center;
  padding-top: 10px;
}

.floating__nav__item > a > img {
  display: block;
  margin: 0 auto;
  min-height: 18px;
}

.floating__nav__item > a > span {
  color: #021431;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  bottom: 0;
}

.floating__nav__item {
  width: 25%;
  margin-bottom: 5px;
}

.floating__nav__item.current > a > span {
  color: #006ee8;
  font-weight: 600;
}

.floating__nav__item.current > a > img {
  opacity: 1;
}

.floating__nav__item > a:before {
  content: "";
  display: block;
  width: 30px;
  border-bottom: 2px solid #ffffff;
  position: relative;
  top: -10px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.floating__nav__item.current > a:before {
  border-bottom: 2px solid #006ee8;
}

@media (max-width: 660px) {
  .floating__profile__nav {
    display: flex !important;
  }

  .floating__nav__item > a {
    max-height: 55px;
  }
}

/*--------------------------------------------------------------- PUBLIC PROFILE ---------------------------------------------------------------*/

section.public__profile {
  background: #f7f7f7;
  padding-bottom: 0;
}

section.public__profile .profile__header {
  border-top: 1px solid #dcdde0;
  border-bottom: 2px solid #006ee8;
  border-radius: 3px;
  padding: 15px 45px 20px 45px;
  margin: 0 15px;
}

a.back__btn {
  color: #2b2b2b;
  text-decoration: none;
  display: block;
}

a.back__btn img {
  margin-right: 10px;
}

a.back__btn > svg {
  margin-right: 8px;
  top: -2px;
  position: relative;
}

a.back__btn:hover {
  color: #006ee8;
}

a.back__btn:hover path {
  fill: #006ee8;
}

.public__actions a.public__follow {
  height: 35px;
  width: 100%;
  max-width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.public__actions a.public__contact {
  height: 35px;
  width: 100%;
  max-width: 165px;
  margin: 0 10px;
}

.public__profile__box {
  margin-top: 10px;
}

.profile__main__image > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.public__f .following__content > span {
  margin: 5px calc(10% - 30px);
}

@media (max-width: 850px) {
  .public__profile__box {
    display: block !important;
  }

  .public__profile__box > div {
    margin: 0 auto;
    max-width: 100% !important;
    justify-content: center !important;
  }

  .public__profile__box > .public__actions {
    margin-top: 20px;
  }
}

@media (max-width: 500px) {
  a.back__btn {
    display: none;
  }

  section.public__profile .container {
    padding: 0;
  }

  section.public__profile {
    padding: 0;
  }

  section.profile__page {
    padding: 10px 0 30px 0;
  }

  .profile__menu .tabs__container {
    margin-bottom: 10px;
    overflow-x: auto;
  }

  section.public__profile .profile__header {
    padding: 0px 0 20px 0;
    top: 0;
    position: relative;
    height: 305px;
    margin: 0;
  }

  .public__profile__box > div {
    display: block !important;
    text-align: center;
  }

  .public__profile__box .profile__main__image {
    text-align: center;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .public__profile__box .profile__information {
    padding: 0;
    text-align: center;
  }

  .public__profile__box h1.user__name {
    margin: 0 auto;
  }

  .public__profile__box .user__follows {
    justify-content: center;
  }

  .public__profile__box > .public__actions {
    position: relative;
  }

  .public__actions a.public__follow,
  .public__actions a.public__contact {
    width: 45%;
  }
}

@media (max-width: 385px) {
  .public__actions a.public__follow,
  .public__actions a.public__contact {
    width: 45%;
    margin: 0 auto;
  }
}

/*--------------------------------------------------------------- FOLLOWS MODALS ---------------------------------------------------------------*/

.modal-dialog.follows {
  width: 100%;
  max-width: 680px;
}

.modal-dialog.follows .modal-content {
  border-top: solid 3px #006ee8;
  -webkit-box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
  -moz-box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
  box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
}

.follow__modal__tabs > a {
  color: #586472;
  padding: 40px 40px 20px 40px;
  display: inline-block;
}

.follow__modal__tabs {
  padding: 0 30px;
  border-bottom: 1px solid #d2d2d2;
  min-height: auto !important;
}

.follow__modal__tabs a.active {
  color: #05142f;
  font-weight: 700;
  border-bottom: 2px solid #05142f;
}

.modal-content > div.follows__listing {
  margin-bottom: 50px !important;
}

.follow__item {
  padding: 20px;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(228, 228, 228, 0.5);
  -moz-box-shadow: 0px 1px 0px 0px rgba(228, 228, 228, 0.5);
  box-shadow: 0px 1px 0px 0px rgba(228, 228, 228, 0.5);
  margin: 0 60px;
  border-radius: 1px;
}

span.user {
  display: block;
  color: #5b5b5b;
  font-size: 15px;
}

a.follow__cta {
  float: right;
  display: block;
  border: 1px solid #006ee8;
  color: #006ee8;
  padding: 2px 18px;
  font-size: 14px;
  margin-top: 18px;
  width: 100px;
  text-align: center;
  text-decoration: none !important;
}

a.follow__cta:hover {
  border: 1px solid #006ee8;
  background: #006ee8;
  color: #ffffff;
}

.detail__and__btn {
  width: 50%;
  float: left;
}

span.status {
  font-size: 14px;
  color: #006ee8;
}

.follow__picture {
  padding-right: 10px;
  width: 10%;
}

span.follow__name {
  display: block;
  font-size: 16px !important;
  font-weight: 700;
}

.follow__detail span.follow__description {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.follow__detail {
  width: 90%;
}

.mobile__bar {
  display: none !important;
}

@media (max-width: 800px) {
  .mobile__bar {
    -webkit-box-shadow: 0px 2px 4px 0px rgba(2, 20, 49, 0.26);
    -moz-box-shadow: 0px 2px 4px 0px rgba(2, 20, 49, 0.26);
    box-shadow: 0px 2px 4px 0px rgba(2, 20, 49, 0.26);
    height: 55px;
    padding: 20px;
  }

  .mobile__bar span.title__profile {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    width: 100%;
  }

  .mobile__bar a.back__btn {
    display: block;
    position: absolute;
    font-size: 14px;
  }

  .mobile__bar a.back__btn > img {
    transform: rotate(0deg);
  }
}

@media (max-width: 659px) {
  .mobile__bar {
    display: flex !important;
  }

  .follow__modal__tabs {
    padding: 0;
  }

  .follow__modal__tabs > a {
    padding: 20px;
    font-size: 14px;
    width: 49%;
    text-align: center;
  }
}

@media (max-width: 560px) {
  .follow__item {
    margin: 0;
  }
}

/*--------------------------------------------------------------- FOLLOWS CONTENT ---------------------------------------------------------------*/
.following__content__info {
  padding: 20px 40px;
}

.following__content__info .counter_follows {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 21px;
  color: #4B4B4B;
  opacity: 0.5;
}
.content-general__follower:hover {
  background-color: #006EE8;
}

.content-general__follower {
  display: flex;
  width: 100%;
  height: auto;
  background: #F5F9FF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  align-items: center;
  margin-top: 10px;
  padding: 22px 30px 23px 20px;
  justify-content: space-between;
}

.content-general__follower .user__info__container {
  border-bottom: none;

}
.content-general__follower .user__info__container .profile__btn.user {
  width: 54px;
  height: 55px;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.content-general__follower .user__info__container .user__details .name__follow {
  display: flex;
}
.content-general__follower:hover .user__info__container .user__details span{
  display: block;
}

.content-general__follower:hover .user__info__container .user__details span.user__name,
.content-general__follower:hover .user__info__container .user__details span.user__profile,
.content-general__follower:hover .user__info__container .user__details span {
  color: white;
}

.content-general__follower .user__info__container .user__details span.user__name {
  font-size: 19px;
  margin-bottom: 10px;
  color: #4B4B4B;
}

.content-general__follower .user__info__container .user__details span.user__profile,
.content-general__follower .user__info__container .user__details span {
  font-family: "Nunito Sans";
  font-size: 17px;
  color: #606161;
  margin-bottom: 5px;
}
.content-general__follower .user__info__container .follow__btn__content {
  display: flex;
  position: relative;
}
.content-general__follower .user__info__container .follow__btn__content span{
  position: absolute;
  top: -5px;
  color: #C4C4C4;
  margin-left: 5px;
}
.content-general__follower .user__info__container .follow__btn__content a{
  background-color: transparent !important;
  font-size: 19px;
  font-weight: 600;
  font-style: normal;
  font-family: "Nunito Sans";
  margin-left: 15px;
  color: #021431 !important;
  border: none;
  position: absolute;
  top: -9px;
}
/*--------------------------------------------------------------- Following ---------------------------------------------------------------*/
/*.content-general__follower.content-following{
  display: block;
}

.content-general__follower.content-following .user__info__container {
  align-items: center;
}

.content-general__follower.content-following .user__info__container .profile__btn.user,
.content-general__follower.content-following .user__info__container .profile__btn.user img{
  width: 200px;
  height: 203px;
}

.content-general__follower.content-following .user__info__container .user__details{
  align-items: center;
}

.content-general__follower.content-following .user__info__container .user__details span {
  display: block;
}
.content-general__follower.content-following .user__info__container .user__details .content__message {
  display: flex;
}
.content-general__follower.content-following .user__info__container .user__details .content__message .block__message {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  margin-left: 10px;
}*/
/*--------------------------------------------------------------- MY CONTENTS ---------------------------------------------------------------*/

span.title__counter {
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
}

.profile__dashboard .my__articles__content,
.profile__dashboard h2.dashboard__title {
  margin: 0 30px;
  padding-bottom: 40px;
}

.profile__body__heading {
  margin-bottom: 20px;
  margin-top: 10px;
}

.profile__dashboard.my__content {
  border-top: 2px solid #006ee8;
}

.profile__dashboard h2.dashboard__title {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 700;
  padding-bottom: 0;
  margin-top: 20px;
}

.profile__dashboard h2.dashboard__title:after {
  content: "";
  display: block !important;
  width: 25px;
  border-bottom: 2px solid #006ee8;
  position: relative;
  top: 5px;
}

.profile__dashboard span.profile__onlyfy {
  font-size: 14px;
  margin: 0 30px;
}

.my__articles__content article {
  border-top: 1px solid #ebebeb;
  margin-top: 20px;
  padding-top: 20px;
}

.my__articles__content article > div {
  padding: 0;
}

.profile__dashboard.my__content .share__links > a,
.share__options.dropdown-list .share__links > a {
  float: none;
  width: 100%;
  font-size: 15px;
  color: #05142f;
  font-weight: 600;
  margin: 10px 0;
  display: flex;
}

.profile__dashboard.my__content .share__links > a:hover > span {
  color: #006ee8;
}

.profile__dashboard.my__content .dropdown .share__options {
  width: 232px;
  margin-left: -195px;
  padding: 22px 25px;
}

.profile__dashboard.my__content .dropdown-list:before {
  content: "";
  display: block;
  width: 22px;
  position: absolute;
  top: -12px;
  right: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #ffffff;
  margin: 0 13px;
}

.my__articles__content article h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.my__articles__content .article__information {
  padding-left: 0;
}

.my__articles__content figure.article__image__content {
  padding-right: 0;
  height: fit-content;
}

.article__interactions {
  margin-bottom: 10px;
}

a.hidde__article {
  border-radius: 50%;
  padding: 5px;
  width: 34px;
  height: 34px;
}

a.hidde__article:hover {
  background: #e9f3ff;
}

.article__interactions .others__links {
  border-radius: 50%;
  padding: 5px;
  width: 38px;
  height: 38px;
}

.byme__author .others__links.dropdown .others__shares img {
  width: 24px;
  height: 5px;
  margin: 2px;
}

.article__interactions .others__links:hover {
  background: #e9f3ff;
}

a.hidde__article:hover > img {
  opacity: 0.8;
}

.myarticle__rating img {
  max-width: 15px;
}

.myarticle__rating .rate__numbers {
  position: relative;
  top: 3px;
}

.my__articles__content article .article__feedback > div {
  padding: 0;
}

.my__articles__content article .reactions__counter {
  display: flex !important;
}

.profile__dashboard.my__content h2.dashboard__title span.my__content__title {
  font-size: 20px;
  color: #006ee8;
  background: #dcedfe;
  padding: 0 5px;
}

.profile__dashboard.my__content h2.dashboard__title:after {
  display: none !important;
}

.profile__dashboard.my__content .share__links > a.publish__content > img {
  margin-left: 0;
  margin-right: 8px;
}

.profile__dashboard.my__content .share__links > a.edit__content > img {
  margin-left: 5px;
  margin-right: 13px;
}

.profile__dashboard.my__content .share__links > a.delete__content > img {
  margin-left: 4px;
  margin-right: 11px;
}

@media (max-width: 579px) {
  .profile__dashboard.my__content .dropdown .share__options {
    margin-left: auto;
    margin-right: 5%;
  }
}

@media (max-width: 600px) {
  .my__articles__content article h2 {
    font-size: 21px;
    line-height: 26px;
  }

  .profile__dashboard h2.dashboard__title {
    font-size: 17px;
    margin-top: 20px;
  }

  .profile__dashboard span.profile__onlyfy {
    margin-top: 20px;
  }

  .profile__body__heading {
    margin-top: 0;
  }
}

/*--------------------------------------------------------------- ADD CONTENT ---------------------------------------------------------------*/

h3.new__content {
  font-size: 30px;
  font-weight: 800;
  border-bottom: 1px solid #006ee8;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

h3.new__content > span {
  color: #006ee8;
  background: #dcedfe;
  padding: 0 4px;
  margin-right: 5px;
}

.modal-dialog.add__content__box .modal-content button.close {
  position: relative;
  top: 0;
}

.modal-dialog.add__content__box {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
}

.modal-dialog.add__content__box .modal-content {
  padding: 5vh 2vw;
  height: auto;
}

.mce__buttons .form-label-group {
  width: 100%;
  max-width: 100px;
  margin: 0 10px 0 0;
}

.mce__buttons .form-label-group select {
  padding: 0;
  border: none;
  height: 35px;
}

.mce__buttons .font__style {
  border-left: 1px solid #cecfd2;
  border-right: 1px solid #cecfd2;
  margin: 0 10px;
  padding: 0 10px;
  height: 35px;
}

.mce__buttons .paragraf {
  border-right: 1px solid #cecfd2;
  margin: 0 10px;
  padding: 0 10px;
  height: 35px;
}

.mce__buttons .links {
  margin: 0 10px;
  padding: 0 10px;
  height: 35px;
}

.mce__buttons a > img {
  min-width: 33px;
  margin: 0 10px;
}

.mce__buttons .buttons > a {
  flex: 0 0 100%;
  max-width: 130px;
  margin-left: 10px;
}

.add__image .profile__image__content {
  padding: 5vw;
}

.create__image__txt {
  margin-top: 20px;
  padding: 30px 40px;
  max-width: 950px;
  background: #eff1f3;
}

.box__interactive {
  border-style: dashed;
  border-color: #689fff;
  border-width: 1px;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.box__interactive img.preview {
  width: 100%;
  height: auto;
}

.box__interactive .image-item__btn-wrapper {
  position: absolute;
  top: 0;
}

.text__insert__box {
  padding: 0px 0px 90px 0px;
  border-bottom: 2px solid #d8d8d8;
}

.text__insert__box a > img {
  max-width: 18px;
}

.video__cover {
  padding: 30px;
  border-bottom: 2px solid #d8d8d8;
}

.video__cover span {
  display: block;
  color: #4b4d4f;
  font-size: 15px;
}

.video__cover a {
  margin-top: 20px;
  display: block;
}

input.add__title {
  font-family: "Noto Serif", serif;
  font-weight: 700;
  display: block;
  font-size: 38px;
  margin-bottom: 10px;
}

input.add__title::placeholder {
  color: #7f8790;
}

span.add__txt {
  font-family: "Noto Serif", serif;
  font-weight: 400;
  color: #6c7780;
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
}

.content__details .form-label-group,
.content__details .cat__tags {
  max-width: 520px;
}

.content__details {
  padding: 20px;
  border-bottom: 2px solid #d8d8d8;
}

.content__details .form-label-group select {
  /*
  border: 1px solid #dadada;
  padding: 0 20px;
  */
}

.content__details .form-label-group label {
  position: relative;
  font-size: 17px;
}

.content__details .cat__tags > span {
  font-size: 17px;
  margin-bottom: 5px;
  display: block;
}

.content__details .cat__tags .tags__container {
  border: 1px solid #d4cdcd;
  padding: 10px;
  width: 100%;
}

.content__details
.cat__tags
.tags__container
.WAMuiChipInput-underline-14::before {
  display: none;
}

.content__details
.cat__tags
.tags__container
.WAMuiChipInput-underline-14::after {
  border-bottom: 2px solid #006ee8;
}

/* .content__details .cat__tags .tags__container span {
  background: #eff7ff;
  padding: 8px;
  font-size: 14px;
} */

.content__details .cat__tags .tags__container .MuiButtonBase-root {
  background: #eff7ff;
  padding: 8px;
  font-size: 14px;
  border-radius: 0;
}

.extra__details__tags > span {
  color: #4b4d4f;
  font-size: 14px;
  padding: 5px 10px;
}

.buttons.send__content {
  margin-top: 30px;
  padding: 0 20px;
}

.buttons.send__content > a {
  flex: 0 0 100%;
  max-width: 170px;
  margin-left: 10px;
}

@media (max-width: 1600px) {
  .modal-dialog.add__content__box .modal-content {
    padding: 5vh 8vw;
  }
}

@media (max-width: 560px) {
  .modal-dialog.add__content__box .modal-content {
    padding: 5vh 5vw;
  }
}

/*--------------------------------------------------------------- SHARED MODAL ---------------------------------------------------------------*/

.modal-dialog.shared {
  max-width: 550px;
}

.modal-dialog.shared .modal-content {
  padding: 60px 5vw;
  text-align: center;
  border-top: 3px solid #006ee8;
}

.article__shared > img,
.article__shared > h4 {
  margin-bottom: 20px;
}

.article__shared p.main__message {
  font-size: 19px;
  line-height: 26px;
}

.article__shared p.secondary__message {
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 30px;
}

/*--------------------------------------------------------------- BECOME PREMIUM OFFER OVERLAY ---------------------------------------------------------------*/

.modal-dialog.premium__offer {
  max-width: 650px;
}

.modal-dialog.premium__offer button.close > span {
  color: #ffffff;
}

.premium__offer_top {
  background: url(../_images/become-bg-top.png);
  text-align: center;
  padding: 3vw 5vw;
  min-height: auto !important;
}

.premium__offer_top > img {
  margin: 0 auto;
  display: block;
}

.premium__offer_top > span {
  font-size: 22px;
  color: #ffffff;
  line-height: 29px;
  display: block;
  margin: 20px 0;
}

.premium__offer_top > a {
  background: #021431;
  padding: 10px 20px;
  display: block;
  width: 100%;
  margin: 10px auto;
  max-width: 280px;
  text-decoration: none;
}

.premium__offer_top > a:hover {
  background: #053e9b;
}

.premium__offer_top > a > span {
  color: #ffffff;
  margin-right: 10px;
  font-size: 20px;
  line-height: 20px;
  margin-top: 5px;
  top: 3px;
  position: relative;
  font-weight: 600;
}

.premium__offer_top > a.already {
  background: none;
  color: #ffffff;
  font-weight: 700;
  text-decoration: underline;
  margin: 0 auto;
}

.premium__offer_bottom {
  padding: 3vw 6vw;
  min-height: auto !important;
}

.premium__offer_bottom h2 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
}

.premium__offer_bottom h2 > span {
  color: #006ee8;
  background: #dcedfe;
  padding: 0 5px;
  margin-right: 5px;
}

.premium__offer_bottom > .highlights__box > div {
  width: 100%;
  font-size: 18px;
  margin-bottom: 15px;
}

.premium__offer_bottom > .highlights__box > div img {
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
}

.premium__offer_bottom > .highlights__box > div > .highlight__txt {
  max-width: 100%;
  line-height: 26px;
}

@media (max-width: 660px) {
  .premium__offer button.close > span {
    color: #ffffff;
  }

  .modal-dialog.premium__offer,
  .modal-dialog.premium__offer .modal-content {
    height: auto;
  }

  .premium__offer_top,
  .premium__offer_bottom {
    padding: 5vh 6vw;
  }

  .premium__offer_bottom > .highlights__box > div {
    padding: 10px 0;
    margin: 0;
  }

  .premium__offer_bottom > .highlights__box > div > .highlight__txt {
    font-size: 18px;
  }

  .premium__offer_top > img {
    max-width: 22vw;
  }
}

@media (max-width: 459px) {
  .content__details {
    padding: 0;
  }

  .buttons.send__content > a {
    max-width: 100%;
    margin: 10px 0;
  }
}

/*--------------------------------------------------------------- RESTRICTED CONTENT OVERLAY ---------------------------------------------------------------*/

.modal-dialog.sorry__buddy {
  max-width: 550px;
}

.sorry__buddy > .modal-content {
  min-height: initial;
  width: 100%;
}

.sorry__buddy .premium__alert__top span {
  margin: 20px 0 10px 0;
  display: block;
}

.sorry__buddy .premium__alert__top button {
  max-width: 270px;
}

.sorry__buddy .premium__alert__bottom > .highlights__box > div {
  margin-bottom: 10px;
}

.premium__alert__top {
  text-align: center;
  padding: 4vh 3vw 2vh 3vw;
  border-top: 3px solid #006ee8;
  min-height: initial !important;
}

.premium__alert__bottom {
  background: #006ee8;
  padding: 4vh 5vw 3vh 5vw;
  min-height: initial !important;
}

.premium__alert__top a {
  background: #006ee8;
  padding: 10px 20px;
  display: block;
  width: 100%;
  margin: 10px auto 20px auto;
  max-width: 280px;
}

.premium__alert__top a > span {
  color: #ffffff;
  font-weight: 800;
}

.premium__alert__top a.already {
  background: none;
  font-weight: 700;
  text-decoration: underline;
  margin: 0 auto;
}

.premium__alert__top > span {
  font-size: 19px;
}

.premium__alert__top > h3 {
  font-size: 26px;
  margin: 20px 0 0 0;
}

.premium__alert__bottom > h2 {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
  font-size: 25px;
  background: #0061e4;
  padding: 5px 10px;
  display: block;
  max-width: 220px;
  margin: 0 auto 20px auto;
}

.premium__alert__bottom > .highlights__box > div {
  width: 100%;
  font-size: 18px;
  margin-bottom: 15px;
  padding: 10px 0;
}

.premium__alert__bottom > .highlights__box > div img {
  min-width: 25px;
  max-width: 25px;
}

.premium__alert__bottom > .highlights__box > div > .highlight__txt {
  max-width: 100%;
  line-height: 26px;
  color: #ffffff;
}

@media (max-width: 660px) {
  .sorry__buddy > .modal-content {
    width: 100%;
    height: 100%;
  }

  .premium__alert__top {
    height: 50%;
    padding: 6vh 8vw 0 8vw;
  }

  .premium__alert__bottom {
    height: 50%;
    padding: 5vh 8vw 0 8vw;
  }

  .premium__alert__bottom > .highlights__box > div > .highlight__txt {
    font-size: 18px;
  }

  .sorry__buddy .premium__alert__bottom > .highlights__box > div {
    padding: 5px 0;
  }
}

/*--------------------------------------------------------------- CHECKOUT ---------------------------------------------------------------*/

section.premium__checkout .container {
  max-width: 960px;
}

.premium__checkout h1 {
  text-align: center;
  font-size: 33px;
  font-weight: 800;
  margin-bottom: 5px;
}

.premium__checkout span {
  font-size: 20px;
  text-align: center;
  display: block;
  line-height: 24px;
}

.premium__checkout span.get__access {
  margin: 20px 0;
}

.pl__premium > div > img {
  margin: 0 auto;
  display: block;
  max-width: 100px;
}

.pl__premium {
  background: url(../_images/premium-box-bg.svg) no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 2.5vw 2vw 4vw 2vw;
}

.price__premium > span.premium__decription {
  font-size: 22px;
  font-weight: 800;
  color: #ffffff;
  margin: 10px 0;
}

.price__premium > span.premium__cost {
  font-size: 33px;
  font-weight: 800;
  color: #ffffff;
  margin: 10px 0;
}

.pl__premium > .premium__list > ul {
  list-style: none;
  margin-top: 25px;
  padding-left: 2.5vw;
}

.pl__premium > .premium__list > ul > li {
  color: #ffffff;
  font-size: 19px;
  background: url(../_images/ico-yellow-checklist.svg) no-repeat left center;
  padding-left: 29px;
  line-height: 22px;
  margin-bottom: 10px;
}

.premium__list {
  border-left: 1px solid #4a98ef;
  width: 55%;
}

.price__premium {
  width: 45%;
}

.alert.alert-danger {
  color: #c20018;
  border-radius: 0;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #ffecec no-repeat;
  background-position: 10px center;
  background-size: 20px;
  border-color: #ffdade;
  padding: 9px 0 9px 38px;
  font-size: 14px;
}

.premium__form .payment__info {
  width: 55%;
  padding: 20px 30px;
}

.premium__form .resume {
  width: 45%;
  padding: 25px;
  border: 1px solid #e1e1e1;
}

.premium__form .payment__info h4 {
  font-size: 26px;
}

.premium__form .payment__info > span {
  font-size: 18px;
  text-align: left;
  margin-bottom: 30px;
}

.premium__form .payment__info > span:after {
  content: "";
  display: block;
  width: 20%;
  border-bottom: 2px solid #021431;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 9px;
}

.payment__method {
  background: #f6f6f6;
  padding: 10px 20px;
}

.payment__method > span.choose {
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0;
  text-align: left;
}

.premium__form input#ccnumber {
  background: url(../_images/ico-secure-cc.svg) no-repeat 96% center;
}

.payment__info .form-label-group.ccdate {
  float: left;
  width: 46%;
}

.payment__info .form-label-group.cccvc {
  float: right;
  width: 46%;
}

.premium__form .resume > span {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.premium__form .resume p.description__purchase {
  font-size: 15px;
}

.premium__form .resume p.value__purchase {
  font-size: 15px;
  font-weight: 700;
}

.premium__form .resume .detailed__resume {
  padding: 0 10px;
  margin-top: 10px;
}

.premium__form .resume .subtotal {
  background: #eef6ff;
  padding: 10px;
}

.premium__form .resume .subtotal p.subtotal__title {
  font-size: 16px;
  font-weight: 700;
  color: #006ee8;
  margin: 0;
}

.premium__form .resume .subtotal p.subtotal__value {
  font-size: 20px;
  font-weight: 700;
  color: #006ee8;
  text-align: right;
  margin: 0;
}

.premium__form .resume .subtotal p.subtotal__value > span {
  font-size: 12px;
  font-weight: 700;
  color: #006ee8;
  margin-top: -10px;
}

.premium__form .resume .renewal__information {
  margin: 10px 0;
}

.premium__form .resume .renewal__information > span {
  font-size: 15px;
  text-align: left;
  font-weight: 700;
}

.premium__form .resume .renewal__information > p {
  font-size: 12px;
  line-height: 16px;
}

.premium__form .resume > button {
  max-width: 100%;
  padding: 8px;
}

.premium__form .resume > span.secure__transaction {
  font-size: 14px;
  line-height: 20px;
  padding-left: 20px;
  background: url(../_images/ico-ssl.svg) no-repeat left 20%;
  margin-top: 12px;
}

.payment__method .cc_payment {
  width: 50%;
}

label.option > img {
  margin-right: 8px;
}

.payment__method input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.payment__method label.option {
  border: 1px solid #05142f;
  font-size: 15px;
  border-radius: 2px;
  padding: 8px;
  font-weight: 700;
  color: #05142f;
  background: #ffffff;
  height: 45px;
  width: 95%;
  text-align: center;
}

input:checked ~ .option {
  border: 2px solid #006ee8;
  color: #006ee8;
}

@media (max-width: 740px) {
  .premium__checkout span.get__access {
    padding: 0 40px;
  }

  .pl__premium {
    display: block !important;
    margin-top: 30px;
    background: none;
    padding: 0;
  }

  .price__premium {
    width: 100%;
    padding: 20px 10px;
    background: url(../_images/premium-box-bg.svg) no-repeat;
    background-size: cover;
    background-position: bottom center;
    padding-bottom: 40px;
  }

  .premium__list {
    width: 100%;
    border-left: none;
    border-top: none;
    padding: 10px 10px 30px 10px;
  }

  .pl__premium > .premium__list > ul {
    max-width: 80%;
    margin: 30px auto;
  }

  .pl__premium > .premium__list > ul > li {
    color: #05142f;
    font-size: 18px;
    background: url(../_images/ico-check-list-blue.svg) no-repeat left center;
    background-size: 15px;
  }

  .premium__form > form.d-flex {
    display: block !important;
  }

  .alert.alert-danger {
    max-width: 82%;
    margin: 0 auto 30px auto;
  }

  .payment__method {
    margin-bottom: 30px;
    border-top: 2px solid #e1e1e1;
    padding: 10px 30px;
  }

  .premium__form .payment__info h4 {
    padding: 0 30px;
  }

  .premium__form .payment__info > span {
    padding: 0 30px;
  }

  .payment__container {
    padding: 0 30px;
  }

  .premium__form .payment__info {
    width: 100%;
    padding: 0;
  }

  .premium__form .resume {
    width: 90%;
    border: 1px solid #05142f;
    margin: 0 auto;
    -webkit-box-shadow: 1px 1px 0px 2px rgba(5, 20, 47, 1);
    -moz-box-shadow: 1px 1px 0px 2px rgba(5, 20, 47, 1);
    box-shadow: 1px 1px 0px 2px rgba(5, 20, 47, 1);
  }

  section.premium__checkout .container {
    padding: 0;
  }
}

@media (max-width: 440px) {
  .payment__method > .d-flex {
    display: block !important;
  }

  .payment__method .cc_payment {
    width: 100%;
  }

  .payment__method label.option {
    width: 100%;
  }
}

/*--------------------------------------------------------------- PAYMENT ACCEPTED OVERLAY ---------------------------------------------------------------*/

.payment__accepted img {
  margin: 0 auto;
  width: 72px;
  height: 57px;
  display: block;
}

.payment__accepted h2 {
  font-size: 24px;
  display: block;
  width: 100%;
  text-align: center;
}

.payment__accepted p {
  text-align: center;
  font-size: 19px;
  width: 50%;
  margin: 0 auto;
  line-height: 26px;
}

.payment__accepted button {
  height: 45px;
  padding: 12px 0 14px 0;
}

.payment__accepted {
  padding: 60px 0 100px 0;
  display: block;
}

/*--------------------------------------------------------------- EDIT PROFILE OVERLAY ---------------------------------------------------------------*/

.edit__profile .modal-content {
  padding: 30px 30px 50px 30px;
}

.modal-dialog.edit__profile {
  max-width: 700px;
}

.profile__camps h3 {
  font-size: 30px;
  font-weight: 800;
  border-bottom: 1px solid #006ee8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.profile__camps h3 > span {
  color: #006ee8;
  background: #dcedfe;
  padding: 0 4px;
  margin-right: 5px;
}

.profile__camps .formedit__camps .form-label-group:first-child {
  padding-left: 0;
}

.profile__camps .formedit__camps .form-label-group:last-child {
  padding-right: 0;
}

.profile__camps .formedit__camps .form-label-group:last-child > label {
  padding-left: 15px;
}

.modal-content .profile__camps button {
  max-width: 120px;
  width: 100%;
  float: right;
}

.formedit__camps {
  margin-bottom: 30px;
  padding-top: 20px;
}

@media (max-width: 769px) {
  .edit__profile .modal-content {
    height: 100%;
    padding: 0;
  }

  .edit__profile .modal-content .profile__camps {
    padding: 40px 20px;
  }

  .formedit__camps * {
    padding: 0;
  }

  .profile__camps .formedit__camps .form-label-group > label {
    padding: 0 !important;
  }

  .profile__camps .formedit__camps .form-label-group {
    margin-bottom: 30px;
  }

  .modal-content .profile__camps button {
    max-width: 100%;
  }
}

@media (max-width: 659px) {
  .edit__profile .modal-content button.close {
    display: none;
  }
}

/*--------------------------------------------------------------- EDIT PROFILE DESCRIPTION OVERLAY ---------------------------------------------------------------*/

.profile__camps .formedit__camps .form-label-group.description {
  width: 100%;
  margin: 0;
}

.profile__camps .formedit__camps .form-label-group.description > label {
  padding: 0;
}

.profile__camps
.formedit__camps
.form-label-group.description
> textarea#inputDescription {
  border: 1px solid #006ee8;
  padding: 10px 20px;
  min-height: 140px;
  font-size: 15px;
  line-height: 23px;
  color: #021431;
  resize: vertical;
}

@media (max-width: 600px) {
  .profile__camps
  .formedit__camps
  .form-label-group.description
  > textarea#inputDescription {
    min-height: 50vh;
  }
}

/*--------------------------------------------------------------- EDIT CERTIFICATIONS OVERLAY ---------------------------------------------------------------*/

.modal-dialog.edit__profile > .modal-content {
  border-top: 3px solid #006ee8;
}

.certification__box > input {
  width: 95%;
  border: 1px solid #d1d4d7;
  padding: 17px 20px;
  margin-right: 15px;
}
.edit-img-profile {
  text-align: center;
}
.edit-img-profile > input {
  height: auto;
  margin: 15px auto;
}
.edit-img-profile .img-file{
  max-width: 150px;
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow: hidden;
}

.edit-img-profile div[class*="-control"]{
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  margin-top: 7px;
}

.certification__box #addInput {
  min-height: 54px;
  width: 100%; 
  padding: 15px 20px 19px;
  color: #05142f;
}

.certification__box #addInput.form-control{
  background-position: 99.5% center;
}

.certification__box img {
  width: 99%;
}

.certification__box > a {
  width: 25px;
  height: 25px;
}

.certification__box {
  margin-bottom: 10px;
}

.certification__box > input:focus {
  border: 1px solid #006ee8;
}

.modal-add-certification.modal.show .modal-dialog{
  max-width: 655px;
}
.modal-add-certification  .certification__box input[type="text"]{
  margin-bottom: 15px;
}
.modal-add-certification  .certification__box input[type="file"]{
  height: auto;
  margin-bottom: 15px;
}
.modal-add-certification  .certification__box .img-file{
  max-width: 400px;
}
.certification-main .certification__box.card-imgtext{
  width: 100%;
  margin-right: 10px;
}
.certification-main .certification__box.card-imgtext input[type="text"]{
  width: 100%;
  margin-right: 0px;
  margin-bottom: 15px;
}
.certification-main .certification__box.card-imgtext .animate__fadeIn{
  max-width: none;
  max-height: 100px;
  overflow: hidden;
}
.certification-main a{
  margin-top: 13px;
}
#modal-add-interest .certification__box #addInput{
  margin-top: 23px;
}

a.add__certification {
  text-align: center;
  display: block;
  width: 100%;
  padding: 19px;
  border: 1px dashed #006ee8;
  background: #f3f8ff;
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
}

a.add__certification:hover {
  text-decoration: none;
  opacity: 0.6;
}

a.add__certification > img {
  margin-right: 10px;
}

.certifications {
  margin-bottom: 25px;
  padding: 0 10px;
}

span.certification__copy {
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
  margin-bottom: 10px;
}

/*--------------------------------------------------------------- EDIT INTERESTS OVERLAY ---------------------------------------------------------------*/

@media (max-width: 769px) {
  .formedit__camps .search__column > label.checklist {
    padding-left: 30px;
  }
}

/*--------------------------------------------------------------- ABOUT US PAGE ---------------------------------------------------------------*/

section.body__text.about {
  background: url(../_images/about-bg.png) no-repeat right top;
  max-width: 100%;
}

section.body__text.about .container {
  padding-right: 25vw;
  padding-left: 12vw;
}

.comment__style__box .inner {
  padding: 15px 20px;
  color: #fff;
  background: #006ee8;
  display: table;
}

.comment__style__box.present .inner {
  background: #021431;
  padding: 40px;
}

.comment__style__box.present .inner p {
  color: #ffffff;
  margin: 0;
  font-size: 19px;
  font-weight: 700;
  text-align: left;
}

.triangle {
  position: relative;
  float: left;
  width: 0;
  height: 0;
  border-style: solid;
}

.comment__style__box {
  padding: 20px;
  margin: 0 0 25px 0;
}

.comment__style__box h1 {
  font-size: 40px;
  color: #ffffff;
  margin: 0;
}

.comment__style__box .triangle {
  position: relative;
  left: 3%;
  border-width: 12px 20px 0 0;
  border-color: #006ee8 transparent transparent transparent;
}

.comment__style__box.present .triangle {
  left: 10%;
  border-width: 15px 18px 0 18px;
  border-color: #021431 transparent transparent transparent;
}

.body__text .container > p {
  font-size: 19px;
  line-height: 33px;
  padding: 0 20px;
}

.body__text .container > blockquote {
  background: url(../_images/about-quote.png) no-repeat left top;
  padding: 0 60px;
  max-width: 90%;
  margin: 40px auto;
  font-size: 20px;
  font-weight: 600;
  line-height: 35px;
}

section.body__text.about .container h2 {
  font-size: 30px;
  padding: 0 20px;
  margin-bottom: 30px;
}

.frenetic__diference > h2 {
  color: #006ee8;
}

.frenetic__diference ul {
  list-style: none;
  margin: 35px 0;
  padding-left: 20px;
}

.frenetic__diference ul > li {
  background: url(../_images/ico-check-list-blue.svg) no-repeat left 5px;
  padding-left: 35px;
  line-height: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  height: 33px;
}

.frenetic__diference a {
  margin-left: 20px;
  text-decoration: none !important;
  text-align: center;
}

.frenetic__diference {
  padding: 0 25px;
}

@media (max-width: 840px) {
  section.body__text.about .container {
    padding: 0;
  }

  section.body__text.about {
    background: url(../_images/about-bg-mobile.png) no-repeat right top;
    background-size: 60%;
  }

  .comment__style__box h1 {
    font-size: 25px;
  }

  .comment__style__box .inner {
    display: block;
  }

  .comment__style__box {
    text-align: center;
  }

  .body__text .container > p {
    font-size: 17px;
    line-height: 27px;
  }

  .body__text .container > blockquote {
    padding: 70px 35px 40px 35px;
    font-size: 17px;
    line-height: 28px;
    margin: 30px 0;
    max-width: 100%;
    background: url(../_images/about-quote.png) no-repeat left top #e1eefd;
    background-size: 35px;
    background-position: 30px 30px;
  }

  .body__text hr {
    width: 92%;
    margin: 40px auto;
  }

  .comment__style__box.present .inner p {
    line-height: 28px;
    font-size: 17px;
  }

  .frenetic__diference > h2 {
    text-align: center;
  }

  .frenetic__diference ul {
    max-width: 95%;
    margin: 35px auto;
  }

  .frenetic__diference button {
    margin: 0 auto;
  }
}

/*--------------------------------------------------------------- CONTACT US ---------------------------------------------------------------*/

section.contact > .container {
  padding: 0 40px;
}

.contact__info__box {
  background: url(../_images/contac-info-bg.png) #006ee8 no-repeat top center;
  background-size: contain;
  padding: 50px 75px;
  height: 50%;
}

img.contact__deco {
  position: absolute;
  left: -40px;
  bottom: -5px;
}

.contact__form > h1 {
  font-size: 43px;
  margin-top: 5px;
}

.contact__info__box h2 {
  font-size: 33px;
  color: #006ee8;
  background: #dcedfe;
  display: table;
  padding: 0 10px;
  margin-bottom: 20px;
}

.contact__info__box span {
  display: block;
  color: #ffffff !important;
  font-size: 17px;
  margin-bottom: 2px;
}

.contact__info__box span > img {
  margin-right: 5px;
}

hr {
  border-top: 1px solid #7fbcff;
  margin: 15px 0;
}

.contact__info__box span strong {
  color: #ffffff;
  font-size: 17px;
}

.contact__form > span.copy__contact {
  font-size: 18px;
  margin-bottom: 50px;
  display: block;
}

.contact__form > span.copy__contact:after {
  content: "";
  display: block;
  width: 5%;
  border-bottom: 2px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 9px;
}

.contact__form {
  padding-left: 70px;
  max-width: 560px;
}

.contact__form .form-label-group {
  margin-bottom: 2.2rem;
}

.contact__container button {
  margin: 0;
}

@media (max-width: 900px) {
  .contact__info__box {
    padding: 40px 30px;
  }
}

@media (max-width: 769px) {
  section.contact > .container,
  section.contact {
    padding: 0;
  }

  .contact__form {
    padding: 40px 30px;
  }

  img.contact__deco {
    display: none;
  }

  .contact__form button {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------- RESET PASSWORD ---------------------------------------------------------------*/

.recovery__process {
  padding: 14vh 10vw;
}

.recovery__process.sended {
  padding: 24vh 12vw;
}

.back__login {
  text-align: center;
  margin-top: 30px;
}

form.form-signin.password > .form-label-group {
  margin: 50px 0;
}

.image__reco {
  margin-right: 12px;
}

.details__reco {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 20px;
  max-width: 400px;
}

.recovery__process.ready {
  text-align: center;
  padding: 24vh 12vw;
}

.recovery__process.ready span.copy__ready {
  font-size: 20px;
  margin-bottom: 30px;
  display: block;
}

@media (max-width: 770px) {
  .recovery__process {
    background: url(../_images/sign-mobile-bg.svg) #ffffff no-repeat top;
    background-position: center -2vw;
    padding: 18vh 12vw;
    height: 100vh;
  }

  .recovery__process button.close span {
    color: #ffffff;
  }
}

/*--------------------------------------------------------------- FAQS ---------------------------------------------------------------*/

section.faqs {
  margin: 0 auto;
}

div.accordion {
  border-top: 3px solid #006ee8;
  border-radius: 3px;
}

div.accordion .card {
  border: 1px solid #e6e6e6;
}

div.accordion > .card > .card-header {
  border-bottom: none;
  background: #ffffff;
}

div.accordion > .card > .card-header button {
  font-size: 17px;
  font-weight: 600;
  color: #021431;
}

div.accordion .card-body {
  font-size: 15px;
  color: #434446;
  line-height: 23px;
  padding: 10px 30px;
  margin-bottom: 20px;
}

div.accordion {
  border-top: 3px solid #006ee8;
  border-radius: 3px;
}

div.accordion .card {
  border: 1px solid #e6e6e6;
}

div.accordion > .card > .card-header {
  border-bottom: none;
  background: #ffffff;
}

div.accordion > .card > .card-header button {
  font-size: 17px;
  font-weight: 600;
  color: #021431;
  padding-right: 0;
  text-align: left;
}

div.accordion .card-body {
  font-size: 16px;
  color: #434446;
  line-height: 24px;
  padding: 10px 30px;
  margin-bottom: 20px;
}

div.accordion > .card > .card-header button.collapsed svg.acc__toggle {
  position: relative;
  transform: rotate(0deg);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin-left: 10px;
  min-width: 12px;
}

div.accordion > .card > .card-header button svg.acc__toggle {
  position: relative;
  transform: rotate(180deg);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media (max-width: 600px) {
  div.accordion > .card > .card-header button {
    font-size: 15px;
  }

  div.accordion .card-body {
    font-size: 14px;
    line-height: 22px;
  }
}

/*--------------------------------------------------------------- NEWSLETTER OVERLAY ---------------------------------------------------------------*/

.newsletter__overlay {
  max-width: 700px;
  width: 700px;
}

.newsletter__overlay > .modal-content {
  background: #ffffff;
  padding: 8vh 5vw;
  border-top: 3px solid #006ee8;
  height: auto;
  width: 700px;
  box-shadow: -6px 6px 10px 0 rgba(16, 29, 49, 0.54);
  margin: 0 -100px;
}

.newsletter__overlay .modal-content h2 {
  text-align: center;
  font-size: 36px;
  background: #006ee8;
  display: block;
  max-width: 340px;
  margin: 10px auto;
}

.newsletter__overlay .modal-content h2 > span {
  color: #ffffff;
  padding: 0 5px;
}

.newsletter__overlay .modal-content span.newsletter__sub {
  font-size: 19px;
  text-align: center;
  font-weight: 600;
}

.newsletter__overlay .modal-content span.topics {
  font-size: 19px;
  text-align: center;
  margin-bottom: 19px;
}

.newsletter__overlay .modal-content .topics__container.row {
  padding: 20px;
  max-width: 420px;
  margin: 0 auto;
}

.newsletter__overlay .modal-content span.topics:after {
  content: "";
  display: block;
  width: 50px;
  border-bottom: 2px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 9px;
  left: 0;
  margin: 0 auto;
}

.newsletter__overlay .modal-content .email__content {
  max-width: 390px;
  margin: 0 auto !important;
  display: block;
  position: relative;
}

.newsletter__overlay .modal-content .captcha {
  text-align: center;
  margin: 20px !important;
  display: block;
}

.newsletter__overlay .modal-content span.newsletter__complete {
  font-size: 19px;
  text-align: center;
  font-weight: 600;
  margin: 50px 0;
}

.modal-dialog.newsletter__overlay.complete .modal-content {
  padding: 10vh 5vw;
}

@media (max-width: 560px) {
  .modal {
    background: #ffffff;
  }

  .modal-content {
    box-shadow: none;
  }

  .newsletter__overlay > .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
  }

  .modal-dialog > .modal-content > .modal-dialog > .modal-content {
    box-shadow: none;
  }

  .newsletter__overlay .modal-content .topics__container.row {
    padding: 20px 0;
  }

  .modal-dialog.newsletter__overlay.complete .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }

  .newsletter__overlay .modal-content > img {
    max-width: 15vw;
    margin: 0 auto;
  }

  .newsletter__overlay .modal-content h2 {
    font-size: 26px;
  }

  .newsletter__overlay .modal-content span.newsletter__sub {
    font-size: 16px;
    line-height: 20px;
    max-width: 80%;
    margin: 0 auto;
  }

  .newsletter__overlay .modal-content span.topics {
    font-size: 16px;
    margin-top: 10px;
  }
}

/*--------------------------------------------------------------- PUSH NOTIFICATIONS ---------------------------------------------------------------*/

.modal-dialog.push {
  margin-top: 0;
}

.notifications__buttons {
  margin-top: 20px;
}

.push__notifications {
  padding: 20px 30px;
}

.push__notifications * {
  margin: 0;
}

.alert__message p {
  width: 75%;
  font-size: 16px;
  line-height: 19px;
}

.alert__message img {
  margin-right: 20px;
}

.push__notifications button.btn-primary {
  max-width: 116px;
  margin: 0 0 0 15px;
  font-size: 15px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  
}

.push__notifications button.btn-nocolor {
  background: none;
  -webkit-appearance: none;
  border: none;
  color: #05142f;
  font-size: 15px;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.push__notifications button.btn-nocolor:hover {
  color: #006ee8;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/*--------------------------------------------------------------- LEGALS ---------------------------------------------------------------*/

section.body__text {
  max-width: 65%;
  margin: 0 auto;
}

span.last__update {
  color: #006ee8;
  font-size: 16px;
  font-weight: 700;
}

section.body__text .container h1.new__content {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
}

section.body__text .container h1.new__content > span {
  color: #006ee8;
  background: #dcedfe;
  padding: 0 4px;
  margin-right: 4px;
}

section.body__text ol {
  list-style: none;
  counter-reset: item;
  padding: 0;
}

section.body__text ol > li {
  counter-increment: item;
  margin: 20px 0 50px 0;
}

section.body__text ol > li:before {
  margin-right: 10px;
  content: counter(item) ". ";
  color: #021431;
  text-align: center;
  display: inline-block;
  position: absolute;
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}

section.body__text ol > li h2 {
  padding-left: 28px;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 30px;
}

section.body__text ol > li h2:after {
  content: "";
  display: block !important;
  width: 60px;
  border-bottom: 2px solid #006ee8;
  position: relative;
  top: 5px;
  left: -30px;
}

section.body__text p {
  font-size: 17px;
  line-height: 26px;
}

section.body__text .container a {
  text-decoration: underline;
}

@media (max-width: 960px) {
  section.body__text {
    max-width: 92%;
  }
}

/*--------------------------------------------------------------- HOME COMMUNITY ---------------------------------------------------------------*/

body.community__area header {
  position: relative;
}

body.community__area main {
  margin-top: 0;
}

.profile__header.community {
  padding: 0 20px;
  margin: 0 0 15px 0;
}

.profile__header.community .create__content li {
  width: 33.33%;
  text-align: center;
  border-bottom: 1px solid #d6d9de;
}

.profile__header.community .create__content li.active {
  border-bottom: 1px solid #021431;
}

.profile__header.community .create__content li a {
  font-size: 15px;
  padding: 15px 5px 15px 15px;
  display: block;
  color: #021431;
  line-height: 16px;
}

.profile__header.community .create__content li.active > a {
  font-weight: 700;
}

.profile__header.community .create__content li a.video__c {
  background: url(../_images/post-video.svg) no-repeat 10% center;
  background-size: 22px;
}

.profile__header.community .create__content li a.article__c {
  background: url(../_images/c-article.svg) no-repeat 10% center;
  background-size: 15px;
}

.tabs__container.create__content > .nav-tabs {
  border-bottom: none;
}

.update__textinput {
  padding: 0px 0 15px 0px;
}

.update__textinput .text__update {
  width: 100%;
}

.update__textinput .text__update > p {
  margin: 0 0 0 10px;
  text-align: left;
}

.update__textinput .text__update textarea {
  padding: 0;
  border-radius: 0;
  border: none;
  resize: none;
  width: 100%;
  font-size: 1rem;
}

.update__textinput a.profile__btn.user {
  border: 3px solid #ffffff;
}

.update__textinput a.profile__btn.user::after {
  display: none;
}

.feeds__community {
  padding: 0;
}

.feed > ul#shared__list {
  bottom: 40px;
  margin: 0 0 0 -90px;
  width: 250px;
  padding: 10px 20px 10px 20px;
}

.feed .share__options a {
  float: none;
  width: 100%;
  margin: 10px 0;
}

.feeds__community .dropdown .share__options .share__links > a.share__link > i {
  margin: 0 2px;
}

.feeds__community .dropdown .share__options .share__links > button {
  text-align: left;
}

.feeds__community .dropdown .share__options .share__links > button > p {
  margin: 10px 0;
}

.feeds__community
.dropdown
.share__options
.share__links
> button
> p
> i.fab.fa-facebook-f {
  margin-right: 19px;
}

.feeds__community
.dropdown
.share__options
.share__links
> button
> p
> i.fab.fa-whatsapp {
  margin-right: 15px;
}

.feeds__community
.dropdown
.share__options
.share__links
> button
> p
> i.fab.fa-linkedin-in {
  margin-right: 15px;
}

.feeds__community
.dropdown
.share__options
.share__links
> button
> p
> i.fab.fa-twitter {
  margin-right: 13px;
}

.feeds__community
.dropdown
.share__options
.share__links
> a.share__facebook
> i {
  margin: 0 5px;
}

.feeds__community
.dropdown
.share__options
.share__links
> a.share__linkedin
> i {
  margin: 0 3px;
}

.feeds__community
.dropdown
.share__options
.share__links
> a.share__post
> img {
  margin-right: 10px;
}

.feed .share__options:before {
  display: none;
}

.feed .share__options:after {
  content: "";
  display: block;
  width: 20px;
  position: absolute;
  bottom: -10px;
  right: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ffffff;
  margin: 0 120px;
}

.feeds__community > article:hover > .articles__activearea {
  background: #f6f9ff;
}

.feeds__community .profile__btn__content:hover a.profile__btn:after {
  display: none;
}

.name__author a {
  font-size: 16px;
  color: #05142f;
  font-weight: 700;
}

.name__author > p {
  margin: 0;
}

.name__author .details__autor p,
.name__author .details__autor time {
  margin: 0;
  font-size: 14px;
  color: #585858;
  line-height: 16px;
}

.name__author .details__autor span.comment__autor__separator {
  line-height: 17px;
}

.feeds__community .author__box {
  padding: 20px 20px 15px 20px;
}

.feeds__community p.post__text {
  padding: 0px 25px;
  font-size: 14px;
  line-height: 19px;
}

.feeds__community h2 > a {
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  background-size: 0% 1px;
}

.feeds__community .article__information {
  padding: 10px 25px 5px 25px;
  border-bottom: 1px solid #efefef;
}

.feeds__community .feed__actions {
  padding: 0;
}

.feeds__community .feed__actions > .reactions__counter {
  border-bottom: 1px solid #efefef;
  padding: 5px 20px;
}

.feeds__community .feed__actions > .comments__btns {
  padding: 5px 20px;
}

.feeds__community h2 {
  margin: 0;
  padding-bottom: 5px;
}

.feeds__community > article {
  border-bottom: 1px solid #021431;
}

.feeds__community h2 {
  margin: 0;
  padding-bottom: 5px;
  line-height: 0;
}

.feeds__community > article {
  margin: 0 0 15px 0;
  border-right: 1px solid #dcdde0;
  border-left: 1px solid #dcdde0;
  border-bottom: 1px solid #021431;
  border-radius: 3px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
}

.feed__actions .comments__container {
  padding: 20px;
}

.comment__cta.community {
  border: none;
  padding: 0 40px;
}

a.btn.btn-secondary.load__more {
  width: 200px;
  font-size: 15px;
  margin: 0 4px;
  height: 36px;
  padding: 5px;
  background: #ffffff !important;
  border: 1px solid #021431;
  color: #021431;
  line-height: 25px;
}

a.btn.btn-secondary.load__more:hover {
  border: 1px solid #006ee8;
  color: #006ee8;
}

.feeds__community article h2 > a:hover {
  background-size: 100% 1px;
}

.feeds__community figure.article__image__content {
  margin: 0;
}

.feeds__community p.feed__source {
  margin-bottom: 5px;
  font-size: 14px;
}

.feeds__community p.feed__source > a {
  color: #05142f;
  text-decoration: none;
}

.feeds__community .dropdown .share__options {
  width: 232px;
  margin-left: -195px;
  padding: 12px 25px;
  margin-top: 22px;
}

.byme__author .others__links.dropdown .share__options:after {
  content: "";
  display: block;
  width: 22px;
  position: absolute;
  top: -10px;
  right: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  margin: 0 14px;
  border-top: 0;
  bottom: auto;
}

.byme__author .others__links.dropdown .share__options a {
  margin: 15px 0;
}

.feeds__community .dropdown .share__options .share__links > a {
  float: none;
  width: 100%;
}

.feeds__community
.dropdown
.share__options
.share__links
> a.publish__content
> img {
  margin-left: 0;
  margin-right: 8px;
}

.feeds__community
.dropdown
.share__options
.share__links
> a.edit__content
> img {
  margin-left: 5px;
  margin-right: 13px;
}

.feeds__community
.dropdown
.share__options
.share__links
> a.delete__content
> img {
  margin-left: 4px;
  margin-right: 11px;
}

.button__delete {
  border-radius: 5px;
  padding: 3px;
}

.button__delete:hover {
  text-decoration: none;
  background: #F9AFB9;
}

.byme__author {
  background: #ffffff !important;
  padding: 10px 5px 5px 25px;
}

.byme__author > p {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.byme__author .others__links.dropdown {
  border-radius: 50%;
  padding: 5px;
  width: 38px;
  height: 38px;
}

.byme__author .others__links.dropdown:hover {
  background: #e9f3ff;
}

.feeds__community .feed__byme h2 > a {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
}

.feeds__community p.post__text.big__status {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 1rem;
  line-height: 28px;
  overflow-wrap: break-word;
}

.feeds__community .share__by {
  padding: 20px 25px 10px 25px;
  border-bottom: 1px solid #efefef;
}

.feeds__community .share__by p {
  margin: 0;
  font-size: 14px;
}

.feeds__community .share__by p > a {
  margin-right: 5px;
  color: #05142f;
  font-weight: 700;
}

.community__right__box {
  padding-right: 0;
}

.groups__maylike {
  width: 100%;
  border-right: 1px solid #dcdde0;
  border-left: 1px solid #dcdde0;
  border-top: 1px solid #dcdde0;
  border-bottom: 1px solid #006ee8;
  border-radius: 3px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  padding: 20px 0;
  display: inline-table !important;
}

.groups__like__box .group__img img {
  max-width: 55px;
}

.groups__like__box {
  padding: 15px 25px;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(222, 222, 222, 0.5);
  -moz-box-shadow: 0px 1px 0px 0px rgba(222, 222, 222, 0.5);
  box-shadow: 0px 1px 0px 0px rgba(222, 222, 222, 0.5);
}

.groups__details > a {
  font-size: 16px;
  font-weight: 700;
  color: #021431;
  line-height: 18px;
  display: block;
  margin-bottom: 5px;
}

.groups__details > p {
  font-size: 14px;
  line-height: 18px;
  color: #717171;
  margin: 0;
}

.group__img {
  margin-right: 15px;
  padding: 0;
  max-width: 60px;
  width: 100%;
  height: 60px;
  overflow: hidden;
  border-radius: 80px;
}
.group__img a {
  padding: 0;
  max-width: 75px;
  width: 100%;
  height: 75px;
  overflow: hidden;
}
.group__img a img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.group__detail * {
  padding: 0;
}

.group__like__cta {
  text-align: center;
}

.group__like__cta > a,
.group__like__cta > button  {
  color: #606e7e;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid #92a0b5;
  padding: 5px 10px;
  border-radius: 1px;
  text-decoration: none;
  max-width: 85px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
}

.group__like__cta > a:hover,
.group__like__cta > button:hover {
  background: #006ee8;
  color: #ffffff;
  border: 1px solid #006ee8;
}



.group__like__cta.joing > a {
  background: url(../_images/ico-joined-group.svg) no-repeat;
  background-position: 10px center;
  padding: 6px 10px 5px 35px;
  color: #006ee8;
}

.group__like__cta.joing > a:hover {
  background: url(../_images/ico-leave-group.svg) no-repeat;
  background-position: 10px center;
  padding: 6px 10px 5px 35px;
  color: #021431;
  border: 1px solid #92a0b5;
}

.group__like__cta.join > a:before {
  content: "Join Group";
}

.group__like__cta.joing > a:before {
  content: "Joined";
}

.group__like__cta.joing > a:hover:before {
  content: "Leave";
}

.group__like__cta > a > img {
  margin-right: 5px;
}

.groups__maylike h3.followers__title {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 10px;
}

a.more__groups {
  background: url(../_images/ico-see-all.svg) no-repeat right 60%;
  padding-right: 20px;
  font-size: 16px;
  margin: 20px auto 0 auto;
  display: table;
  font-weight: 600;
}

.ads__container {
  margin: 20px 0;
  text-align: center;
  padding: 20px 0;
  background: #eeeeee;
}

.community__right__box ul {
  padding: 0 !important;
  display: -webkit-box;
}

.community__right__box ul > li {
  margin-right: 18px;
}

.community__right__box span.copyright {
  padding: 0;
}
.joined{
  padding: 0 5px;
  font-weight: 700;
}

@media (max-width: 990px) {
  .groups__maylike,
  .ads__container {
    display: none !important;
  }

  .community__right__box {
    left: 25%;
  }
}

@media (max-width: 800px) {
  .community__right__box {
    left: 0;
    max-width: 100% !important;
  }
}

@media (max-width: 600px) {
  .feeds__community .feed__actions > .comments__btns {
    justify-content: space-between;
  }
}

@media (max-width: 580px) {
  .feed > ul#shared__list {
    margin: 0 0 0 -170px;
    position: absolute !important;
  }

  .feed .share__options:after {
    content: "";
    margin: 0 20px;
  }

  .profile__header.community .create__content li:last-child > a,
  .profile__header.community .create__content li:nth-child(2) > a {
    display: none;
  }

  .profile__header.community .create__content li.active {
    width: 40%;
  }

  .profile__header.community .create__content li {
    width: 30%;
  }

  .others__links.dropdown.footer__dropdown .share__options {
    margin: 0;
    position: absolute !important;
    right: 40px !important;
    left: auto !important;
    bottom: 60px;
  }

  .profile__btn.user {
    width: 42px;
    height: 42px;
  }

  .feeds__community p.post__text {
    font-size: 15px;
    line-height: 20px;
  }

  .group__like__cta > a {
    margin: 0;
  }
}

@media (max-width: 560px) {
  .profile__header.community {
    padding: 0 0 20px 0;
    height: auto;
  }

  .update__textinput {
    padding: 0 20px;
  }

  .profile__header.community .create__content li a.video__c,
  .profile__header.community .create__content li a.article__c {
    padding-left: 35px;
  }

  .profile__header.community .create__content li a {
    font-size: 14px;
    padding: 12px 5px 8px 5px;
  }

  .profile__header.community > .tabs__container.create__content {
    margin-bottom: 15px;
  }

  .profile__header.community .create__content li a.video__c {
    background-size: 22px;
    background-position: 16% center;
  }

  .profile__header.community .create__content li a.article__c {
    background-size: 16px;
    background-position: 16% center;
  }
}

@media (max-width: 500px) {
  .community__right__box ul {
    display: inline-flex;
  }
}

/*--------------------------------------------------------------- CREATE A POST OVERLAY ---------------------------------------------------------------*/

.modal-content h3.new__content {
  margin: 25px 0;
}

.modal-dialog .text__update {
  width: 100%;
}

.text__update > textarea {
  font-size: 18px;
  border: none;
  line-height: 25px;
  width: 100%;
}

.post__content__btn {
  padding: 0 20px 20px 20px;
}

.post__content__btn .add__content__post > a {
  width: 43px;
  height: 43px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
}

.post__content__btn .add__content__post > a:hover {
  background: #ebf0fb;
}

.post__content__btn .add__content__post > a > img {
  padding: 12px 0;
}

.bt__post > a.btn.btn-primary {
  width: 120px;
  font-size: 18px;
  font-weight: 700;
  height: 35px;
  line-height: 22px;
}

.image__post__preview > img {
  max-width: 365px;
  width: 100%;
}

.image__post__preview > a.delete__post__image {
  background: url(../_images/delete-post-image.svg) no-repeat;
  width: 26px;
  height: 26px;
  display: block;
  position: absolute;
  right: 12px;
  top: 12px;
}

.image__post__preview {
  max-width: 365px;
  position: relative;
  display: inline-table;
}

.image__post__preview > a.delete__post__image:hover {
  opacity: 0.8;
}

/*--------------------------------------------------------------- GROUPS MAY YOU LIKE ---------------------------------------------------------------*/

.profile__menu .tabs__container > .nav-tabs {
  border: none;
}

.groups__options h1 {
  font-size: 20px;
  line-height: 36px;
  font-weight: 800;
  padding: 0 30px;
}

.group__cards__container {
  padding: 0 15px;
  justify-content: space-between;
}
.group__cards__container::after{
  content: "";
  display: block;
  position: relative;
  max-width: 290px;
  width: 100%;
  height: auto;
}

.group__cards__container .group__card,
.group__cards__container > .horizontal__boxed {
  padding: 0;
  max-width: 280px;
  margin-bottom: 11px;
}

.group__cards__container ul.slick-dots {
  bottom: -15px;
}

.group__cards__container > .horizontal__boxed .slick-slide {
  border: none;
}

.groups__options {
  border-right: 1px solid #dcdde0;
  border-left: 1px solid #dcdde0;
  border-bottom: 1px solid #dcdde0;
  border-top: 2px solid #006ee8;
  border-radius: 3px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  padding: 0;
  margin-bottom: 15px;
}

.list-members{
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.list-members li{
  list-style: none;
  margin-right: 10px;
  font-size: 13px;
  color: #8b8b8f;
}

.are__in {
  margin: 15px 50px 0 50px;
  border-bottom: 4px solid #eff1f3;
}

.are_in__title {
  display: flex;
  align-items: baseline;
}

.are__in h1 {
  padding: 0 5px 0 0;
}

.are_in__btn > a.btn {
  width: 130px;
  height: 30px;
  line-height: 18px;
  font-size: 14px;
  font-weight: 700;
}

.tabs__container.groups__tabs li a {
  text-align: center;
}

.tabs__container.groups__tabs .nav-tabs {
  max-width: 100%;
}

a.join__group,
button.join__group {
  width: 100%;
  display: block;
  font-size: 14px;
  background-color: transparent;
}

.group__activity > p {
  margin: 0;
  color: #000000;
  margin-bottom: 10px;
}

p.groups__members {
  font-size: 12px;
  font-weight: 700;
}

p.groups__post {
  font-size: 11px;
  color: #333333;
  font-weight: 400;
}

.group__info > h2 {
  font-size: 16px;
  color: #2b2b2b;
  line-height: 18px;
  margin-bottom: 10px;
}

.group__info > h2 > a {
  color: #021431;
}

.g__card {
  border: 1px solid #d8d4d4;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.g__card figure.article__image__content {
  padding: 0;
}

.g__card figure.article__image__content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px 2px 0px 0px;
  -moz-border-radius: 2px 2px 0px 0px;
  -webkit-border-radius: 2px 2px 0px 0px;
}

.detail_info__group * {
  padding: 0;
}

.detail_info__group a.join__group,
.detail_info__group button.join__group {
  max-width: 90px;
  margin-right: 0;
}

.group__detail button.join__group {
  max-width: 90px;
  margin-right: 0;
}

.group__info {
  padding: 5px 23px 15px 23px;
}

.groups__like__container > .groups__like__box {
  box-shadow: none !important;
}

.groups__like__container > .groups__like__box > .group__detail {
  -webkit-box-shadow: 0px 1px 0px 0px rgba(222, 222, 222, 0.5);
  -moz-box-shadow: 0px 1px 0px 0px rgba(222, 222, 222, 0.5);
  box-shadow: 0px 1px 0px 0px rgba(222, 222, 222, 0.5);
  padding: 15px 10px;
}

.groups__like__container > .groups__like__box > .group__detail .group__img {
  text-align: center;
  margin: 0;
}

.groups__like__container > .groups__like__box > .group__detail img {
  max-width: 60px;
}

.groups__like__container {
  padding: 20px 30px;
}
div.groups__like__container > div:nth-child(1),
div.groups__like__container > div:nth-child(2),
div.groups__like__container > div:nth-child(3){
  display: none;
}
.groups__details {
  padding-left: 15px;
}

@media (max-width: 989px) {
  .groups__like__container .group__like__cta {
    margin-top: 15px;
    margin-left: 8%;
  }

  .group__cards__container .group__like__cta {
    margin-top: 15px;
  }
}

@media (max-width: 800px) {
  .groups__like__container .group__like__cta {
    margin-left: 0;
  }

  .groups__tabs__block {
    min-width: 340px;
  }

  .groups__tabs__block > li {
    width: 33.33%;
  }
}

@media (max-width: 766px) {
  .groups__like__container .group__like__cta {
    margin-top: 0;
  }

  .group__cards__container .group__card {
    margin-bottom: 20px;
  }

  .group__cards__container .group__like__cta {
    margin-top: 0;
  }

  .group__cards__container {
    padding: 0 15px;
    border-bottom: 1px solid #d5d5d5;
  }
}

@media (max-width: 600px) {
  .groups__details {
    padding-left: 0;
  }

  .groups__like__container .group__like__cta {
    margin-top: 5px;
    text-align: left;
    margin-left: 25%;
  }

  .groups__like__container > .groups__like__box > .group__detail {
    padding: 25px 0.5rem 30px 0.5rem !important;
  }

  .groups__like__container {
    padding: 5px 15px 20px 15px;
  }

  .group__cards__container .group__card {
    border-bottom: 2px solid #006ee8;
  }
}

@media (max-width: 500px) {
  .are__in {
    margin: 0 20px;
  }
}

@media (max-width: 400px) {
  .groups__details {
    padding-left: 15px;
  }

  .groups__like__container .group__like__cta {
    margin-left: 31%;
  }

  .are__in {
    display: block !important;
    text-align: center;
  }

  .are_in__title {
    justify-content: center;
  }

  .are_in__btn {
    margin-bottom: 20px;
  }

  .are_in__btn > a.btn {
    width: 100%;
    height: 40px;
    line-height: 26px;
    font-size: 16px;
    max-width: 240px;
  }
}

/*--------------------------------------------------------------- GROUP DETAILS ---------------------------------------------------------------*/

.profile__header.group__details {
  padding: 20px 45px;
}

.profile__header.group__details .profile__main__image {
  display: block;
}

h1.group__name {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 27px;
  color: #2b2b2b;
  margin-bottom: 0;
  margin-top: 0;
}

.group__members .group__like__cta.joing > a {
  border: 1px solid #92a0b5;
  border-radius: 1px;
  text-align: center;
  max-width: 115px;
  height: 35px;
  display: block;
  padding: 5px 5px 5px 25px;
  font-size: 16px;
  font-weight: 600;
  background-position: 20px center;
  background-size: 19px;
  margin: 0;
}

.group__members .group__like__cta.join > a {
  border: 1px solid #006ee8;
  border-radius: 1px;
  text-align: center;
  max-width: 115px;
  height: 35px;
  display: block;
  padding: 5px;
  font-size: 16px;
  font-weight: 600;
  background: #006ee8;
  color: #ffffff;
}

.group__members .group__like__cta.join > a:hover {
  background: #005fc6 !important;
  border: 1px solid #006ee8;
}

.profile__information .group__members > .group__like__cta {
  padding: 0;
  max-width: 115px;
  width: 100%;
  margin-right: 20px;
}

.members__counter {
  font-weight: 700;
}
#edit_about_members .modal-dialog{
  max-width: 540px;
}
.members-modal{
  display: flex;
  flex-direction: column;
  justify-content: left;
  max-height: 610px;
  overflow: auto;
}
.members-modal h2{
  font-size: 20px;
  color: #000000;
  margin-bottom: 30px;
}
.card-members{
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 100%;
  border-bottom: 1px solid #B1B1B1;
}
.card-members .card-img{
  max-width: 75px;
    height: 75px;
    width: 100%;
    border-radius: 50px;
    margin-right: 10px;
}
.card-members .card-img a{
  width: 100%;
  height: 75px;
  position: relative;
  bottom: 5px;
}
.card-members .card-img a img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: gray;
}
.card-members .card-info h3{
  font-size: 16px;
  color: #000000;
}
.card-members .card-info p{
  font-size: 14px;
  color: #333333;
  margin-bottom: 0px;
}

/*  botones join */

.general-button {
  font-size: 18px;
  font-weight: bold;
  margin: 40px 0 20px;
  padding: 8px 50px;
  box-shadow: 0px 0px 0px;
}
.button-blue {
  background: #0569D8;
  border-color: #0569D8;
  color: #FFFFFF;
  border: none;
  padding: 4px;
}
.button-gray{
  background: #B6B6B6;
  border-color: #B6B6B6;
  cursor: default !important;
}
.button-gray-light {
  background: #FFFFFF;
  border-color: #747474;
  color: #747474;
}
.button-blue-light{
  background: #FFFFFF;
  border-color: #0569D8;
  color: #0569D8;
}
.button-group-send{
  max-width: 355px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}



.profile__information span.group__description {
  font-size: 17px;
  margin: 5px 0 5px 0;
  display: block;
  color: #2b2b2b;
}

.profile__information .group__members {
  margin-top: 20px;
}

h1.group__name a.edit > img {
  top: 0;
}

h3.members__title {
  font-size: 19px;
  font-weight: 700;
  clear: both;
  padding-left: 25px;
  padding-bottom: 5px;
}

h3.members__title > span {
  font-size: 14px;
  font-weight: 600;
  color: #006ee8;
  padding-left: 5px;
}

.members__content {
  padding: 20px 20px 0 20px;
  border-top: 1px solid #e9e9e9;
}

.members__content > span {
  margin: 5px calc(10% - 25px);
}

.members__content a.profile__btn.user,
.following__content a.profile__btn.user {
  margin: 0;
}

.members__content a.profile__btn.user img {
  background: gray;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.groups__description__box {
  width: 80%;
  margin: 10px auto;
  border-top: 4px solid #eff1f3;
  padding: 25px 0 0 15px;
  border-bottom: 4px solid #eff1f3;
}

.groups__description__box .profile__main__image > img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.groups__description__box .profile__information h2 {
  font-size: 17px;
  margin: 0;
}

.groups__description__box > .d-flex {
  margin-bottom: 15px;
}

.groups__description__box .profile__main__image {
  max-width: 50px;
  height: 50px;
  width: 100%;
  margin-right: 10px;
}

.groups__description__box .profile__information {
  padding-left: 0;
  max-width: 80%;
}

.groups__description__txt > p {
  font-size: 15px;
  color: #555d69;
  font-weight: 600;
  line-height: 23px;
}

.group__rules__box {
  width: 80%;
  margin: 10px auto;
  padding: 25px 0 0 15px;
}

.community__right__box .group__rules__box ul {
  display: block;
  padding-left: 30px !important;
}

.group__rules__box ul > li {
  color: #555d69;
  font-size: 15px;
  line-height: 22px;
}

.group__rules__box > h2 {
  font-size: 17px;
}

@media (max-width: 955px) {
  .profile__body {
    padding: 0;
  }
}

@media (max-width: 500px) {
  h1.group__name {
    font-size: 23px;
    line-height: 25px;
  }

  .group__details {
    padding: 30px 25px;
    height: auto;
  }

  .profile__header.group__details {
    padding: 30px 25px;
  }
}

@media (max-width: 360px) {
  .public__profile__box {
    padding: 0;
  }
}

/*--------------------------------------------------------------- CREATE GROUP ---------------------------------------------------------------*/

section.no__groups {
  height: 92.8vh;
}

.create__group__content {
  margin-top: 40px;
}

.creation__group__form .form-label-group {
  margin-bottom: 50px;
}

.groups__tabs {
  margin-bottom: 0;
}

.creation__group__form .form-label-group.labeled > label {
  position: relative;
  color: #4d4d4d;
  font-weight: 700;
  font-size: 14px;
  height: 30px;
}

.creation__group__form .form-label-group > label,
.creation__group__form .form-label-group > input {
  font-size: 14px;
  color: #838383;
}

.form-label-group.nofloating > label {
  position: relative;
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 700;
  height: 35px;
}

.form-label-group.nofloating > input {
  padding: 0;
}

.form-label-group.nofloating > input::-webkit-input-placeholder {
  color: #838383;
}

.form-label-group.nofloating > input:-ms-input-placeholder {
  color: #838383;
}

.form-label-group.nofloating > input::-ms-input-placeholder {
  color: #838383;
}

.form-label-group.nofloating > input::-moz-placeholder {
  color: #838383;
}

.form-label-group.nofloating > input::placeholder {
  color: #838383;
}

h3.new__content.group {
  text-align: center;
  border: none;
}

.create__group__content > p {
  text-align: center;
  font-size: 16px;
  color: #2d2f31;
}

.create__group__content .create__image__txt {
  background: none;
  padding: 30px 40px 0 40px;
}

.creation__group__form {
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 50px;
}

.creation__group__form.max{
  max-width: 930px;
  justify-content: space-between;
}
.creation__group__form.max .group__card{
  max-width: 290px;
  margin-bottom: 10px;
}

.create__group__content a.btn.btn-primary {
  max-width: 315px;
  height: 50px;
  width: 100%;
  font-size: 18px;
  padding: 12px;
  font-weight: 700;
  border-radius: 3px !important;
}

.creation__group__form .buttons {
  padding: 40px 0;
}

.groups__options .profile__header.no__published {
  border: none;
  box-shadow: none;
}

.creation__group__form .profile__image__content > .image__profile {
  max-width: 63px;
  max-height: 63px;
  background-size: 27px;
}

.creation__group__form .profile__image__content > a.add {
  max-width: 21px;
  top: -22px;
}

.mobile__group__details {
  display: none;
}

.feeds__community > article .discussion__title {
  font-size: 14px;
  color: #021431;
  border-bottom: 1px solid #d6d9de;
  margin: 0 10px;
  padding: 20px 10px 15px 10px;
}

.feeds__community > .discussion__title.mobile {
  font-weight: 600;
  font-size: 14px;
  color: #05142f;
  padding: 0 20px 10px 20px;
}

@media (max-width: 800px) {
  section.no__groups {
    padding: 0 0 30px 0;
  }

  main.no__top {
    margin-top: 0;
  }

  section.profile__page.group {
    padding: 0 0 30px 0;
  }

  section.profile__page.no__groups {
    height: 100vh;
  }

  .group__menu__lv1 {
    display: none;
  }

  .mobile__bar {
    background: #ffffff;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(20, 27, 38, 0.15);
    -moz-box-shadow: 0px 2px 4px 0px rgba(20, 27, 38, 0.15);
    box-shadow: 0px 2px 4px 0px rgba(20, 27, 38, 0.15);
    position: relative;
    z-index: 2;
    display: flex !important;
    max-height: 55px;
  }

  section.profile__page.group .profile__body .groups__options {
    border-top: 0;
  }
}

@media (max-width: 500px) {
  h3.new__content.group,
  .create__group__content {
    padding: 0 30px;
  }

  .group__members .group__like__cta.joing > a {
    background-position: 40% center;
  }

  .group__body .profile__header.group__details .profile__main__image {
    margin-top: -115px;
  }

  .group__body .profile__header.group__details .profile__main__image > img {
    max-width: 115px;
    margin: 0 auto;
  }

  .profile__information .group__members > .group__like__cta {
    margin: 10px 0 0 0;
    max-width: 80%;
  }

  .profile__information span.group__description {
    display: none;
  }

  .group__details .public__profile__box .profile__information {
    margin: 0 10px;
    width: 95%;
  }

  .group__members > .group__like__cta > a {
    max-width: 100% !important;
    width: 100%;
    margin: 0 auto;
  }

  .group__details .public__profile__box {
    padding: 0;
  }

  .group__body {
    padding: 0;
  }

  .group__body .profile__header.group__details {
    padding: 30px 0 0 0;
    margin: 80px 0 15px 0;
  }

  .mobile__group__details a {
    font-weight: 600;
  }

  .mobile__group__details * {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
  }

  .profile__information .group__members {
    border-bottom: 1px solid #eff1f3;
    padding-bottom: 15px;
  }

  .mobile__group__details .groups__description__box,
  .mobile__group__details .group__rules__box {
    padding: 20px 20px 20px 10px;
    border-bottom: 1px solid #eff1f3;
    margin: 0 10px;
    display: block !important;
    width: 94%;
    border-top: none;
  }

  .mobile__group__details {
    display: block;
  }

  .mobile__group__details h2 {
    font-size: 17px;
    line-height: 21px;
    margin-bottom: 5px;
  }

  .mobile__group__details ul {
    margin-left: 20px;
  }
}

@media (max-width: 310px) {
  .group__members .group__like__cta.joing > a {
    background-position: 35% center;
  }
}

/*--------------------------------------------------------------- NO GROUPS ---------------------------------------------------------------*/

.creation__group__form.no__groups img {
  max-width: 260px;
  width: 100%;
}

/*--------------------------------------------------------------- GROUPS ---------------------------------------------------------------*/

.creation__group__form.group-exist .container-img{
 position: relative; 
}
.creation__group__form.group-exist .article__image__content {
  height: 240px;
  background: gray;
}
.creation__group__form.group-exist .article__image__content a{
  height: 240px;
  overflow: hidden;
}
.creation__group__form.group-exist .article__image__content a img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.creation__group__form.group-exist .type-group{
  position: absolute;
  max-width: 135px;
  min-height: 25px;
  background: #CCE4FF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  left: 10px;
  bottom: 10px;
  border-radius: 4px;
}

.creation__group__form.group-exist .type-group p {
  margin: 0px;
  font-size: 12px;
  color: #0569D8;
}

.creation__group__form.group-exist .type-group .icon-group{
  max-width: 15px;
  margin-right: 5px;
}

.creation__group__form.group-exist .group__info h2{
  font-weight: 700;
  color: #0569D8;
  font-family: 'Nunito Sans';
}

/*--------------------------------------------------------------- PROFILE TRENDS ---------------------------------------------------------------*/

.groups__options.trends {
  padding: 30px 40px;
}

.data__trends__container > img {
  width: 100%;
}

.trends__title * {
  margin: 0;
}

.trends__title {
  margin-top: 15px;
}

.trends__title > h2.new__content {
  color: #006ee8;
  background: #dcedfe;
  padding: 0 4px;
  margin-right: 4px;
  font-weight: 800;
  font-size: 30px;
}

.data__trends__container {
  margin-bottom: 40px;
}

.viewers {
  margin-top: 10px;
}

.viewers > h3 {
  font-size: 20px;
  color: #006ee8;
  font-weight: 700;
  margin-right: 8px;
}

.viewers * {
  margin: 0;
}

.viewers__container > h2 {
  font-size: 21px;
  font-weight: 700;
  border-bottom: 1px solid #021431;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.viewers__container > h2 > span {
  font-weight: 600;
  margin-left: 5px;
}

.trends__title > p {
  font-size: 17px;
  font-weight: 700;
}

.viewers__cards > img {
  width: 55px;
  height: 55px;
  margin: 0 auto;
}

.viewers__cards {
  -webkit-box-shadow: 2px 2px 6px 0px rgba(2, 20, 49, 0.1);
  -moz-box-shadow: 2px 2px 6px 0px rgba(2, 20, 49, 0.1);
  box-shadow: 2px 2px 6px 0px rgba(2, 20, 49, 0.1);
  border: 1px solid #e3e3e3;
  padding: 30px;
}

.viewers__cards > span.viewer__name {
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
}

.viewers__cards > span.viewer__position {
  font-size: 15px;
  line-height: 21px;
  margin-bottom: 15px;
}

.viewers__cards > a.btn {
  width: 100%;
  max-width: 200px;
  height: 40px;
  padding: 7px;
  margin: 0 auto;
}

.viewers__cards > a.btn.btn-secondary {
  background: #ffffff !important;
  border: 1px solid #006ee8;
  color: #006ee8;
}

.viewers__cards > a.btn.btn-secondary:hover,
.viewers__cards > a.btn.btn-secondary:active {
  background: #daeafd !important;
}

@media (max-width: 500px) {
  .groups__options.trends > a.back__btn {
    display: block;
  }
}

/*--------------------------------------------------------------- PROFILE EDIT MY ACCOUNT ---------------------------------------------------------------*/

.form__nofloating > label {
  padding: 0;
  width: 100%;
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 700;
  margin: 10px 0 25px 0;
  text-align: left;
}

.form__nofloating input,
.form__nofloating select {
  height: 2.8rem;
  padding: 0;
  border-radius: 0;
  border-bottom: 1px solid #a1a1a1;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.profile__header.my__account h2.new__content {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 20px;
  padding: 5px;
}

.profile__header.my__account .trends__title > p {
  font-size: 18px;
  font-weight: 700;
}

.profile__header.my__account .profile__form {
  border-top: 1px solid #021431;
  padding: 0 50px;
}

.profile__header.my__account .profile__form--password {
  border: 0;
  padding: 0;
}

.profile__header.my__account > .trends__title {
  margin-bottom: 10px;
}

.profile__header.my__account .password__form,
.profile__header.my__account .suscription__form {
  border-top: 1px solid #021431;
  padding: 50px;
}

.profile__header.my__account .password__form .alert {
  background-color: #F5F9FF;
  display: flex;
  gap: 15px;
  max-width: 439px;
  margin: 24px 0;
}

.profile__header.my__account .password__form .alert p {
  margin: 0;
}

.profile__header.my__account h4.account__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.profile__header.my__account span {
  font-size: 14px;
}

.last__p__change > button.btn.btn-secondary {
  margin-left: 20px;
  height: 35px;
  font-size: 14px;
  color: #006ee8;
  font-weight: 600;
  width: 180px;
  border: 1px solid #006ee8;
}

.last__p__change > button.btn.btn-secondary:hover {
  color: #ffffff;
}

.profile__header.my__account .profile__form .buttons > a,
.profile__header.my__account .profile__form .buttons > button {
  font-size: 14px;
  width: 125px;
  margin-right: 10px;
  padding: 10px 0 12px 0;
}

.profile__header.my__account .profile__form .buttons {
  margin: 15px 0 30px 0;
  display: flex;
}

.profile__header.my__account
.profile__form
.buttons
> button.btn.btn-secondary {
  border: 1px solid #006ee8;
  color: #006ee8;
}

.profile__header.my__account
.profile__form
.buttons
> a.btn.btn-secondary:hover {
  color: #ffffff;
}

.profile__header.my__account .form-label-group > label {
  position: relative;
  padding: 0;
  width: 100%;
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 700;
  margin: 0;
  height: 30px;
}

.profile__header.my__account .form-label-group > input {
  padding: 0;
}

.profile__header.my__account .new__password.confirmation1,
.profile__header.my__account .new__password.confirmation2 {
  float: none;
  width: 50%;
}

.profile__header.my__account .password__toggle {
  top: auto;
  bottom: 5px;
}

@media (max-width: 900px) {
  .profile__header.my__account > .profile__form {
    padding: 0 30px;
  }

  .profile__header.my__account > .password__form,
  .profile__header.my__account > .suscription__form {
    padding: 30px;
  }
}

@media (max-width: 590px) {
  .profile__header.my__account > .profile__form {
    padding: 0;
  }

  .profile__header.my__account > .password__form,
  .profile__header.my__account > .suscription__form {
    padding: 30px 0;
    text-align: left;
  }

  .last__p__change > a.btn.btn-secondary,
  .last__p__change > button.btn.btn-secondary {
    margin: 20px 0 0 0;
    width: 100%;
    margin-bottom: 15px;
    height: 45px;
    line-height: 30px;
  }
}

@media (max-width: 500px) {
  .profile__header.my__account {
    height: auto;
    margin: 0;
  }

  .form-label-group.profession,
  .half-l,
  .form-label-group.country,
  .half-r {
    float: none;
    width: 100%;
  }

  .form__nofloating > label {
    margin: 10px 0 15px 0;
  }
  .form__nofloatingAlt > label {
    margin: 10px 0 15px 0;
  }

  .profile__header.my__account .profile__form .buttons > a,
  .profile__header.my__account .profile__form .buttons > button {
    width: 100%;
    margin-bottom: 15px;
    height: 45px;
    line-height: 26px;
  }

  .profile__header.my__account > .profile__form .buttons {
    margin: 15px 0;
  }

  .profile__header.my__account .new__password.confirmation1,
  .profile__header.my__account .new__password.confirmation2 {
    width: 100%;
  }
}

/*--------------------------------------------------------------- PROFILE MY ACTIVITY ---------------------------------------------------------------*/

.feeds__community.my__acivity {
  border-top: 2px solid #006ee8;
}

.my__acivity__title {
  padding: 10px 25px 0 25px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  z-index: 2;
  position: relative;
}

h1.activity__title {
  display: inline-block;
  margin-bottom: 12px;
  font-size: 20px;
  padding: 5px;
  color: #006ee8;
  background: #dcedfe;
  margin-right: 4px;
  font-weight: 800;
  margin-top: 5px;
}

.activity__detail {
  background: #f7f7f7;
  height: 30px;
  padding: 0;
  border-bottom: 1px solid #e8e8e8;
  margin-top: 10px;
}

.activity__detail > p {
  font-size: 13px;
  color: #2b2b2b;
  line-height: 32px;
  font-weight: 600;
}

@media (max-width: 500px) {
  .activity__detail > p {
    padding-left: 10px;
  }
}

/*--------------------------------------------------------------- UPCOMING MEETUPS ---------------------------------------------------------------*/

p.meetups__txt {
  max-width: 650px;
  margin: 0 auto;
  font-size: 19px;
  line-height: 27px;
  color: #2d2f31;
  margin-bottom: 30px;
}

.meetups__listing a.btn.btn-primary {
  margin: 0 auto;
  display: block;
  max-width: 240px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 3px !important;
}

.meetup__card {
  border: 1px solid #d6d9de;
  border-radius: 1px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(239, 241, 243, 1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(239, 241, 243, 1);
  box-shadow: 0px 2px 4px 0px rgba(239, 241, 243, 1);
  max-width: 800px;
  margin: 5px auto;
}

.meetup__card.link {
  cursor: pointer;
}

.meetup__card:hover {
  max-width: 844px;
  background: #f4f7fc;
}

.meetup__card:hover * {
  text-decoration: none;
}

.meets__counter {
  margin: 15px auto;
  font-size: 17px;
  color: #2d2f31;
  max-width: 800px;
}

.meetup__date {
  border-right: 1px solid #eff1f3;
  padding: 18px 25px 18px 0;
  margin: 15px 20px;
  text-align: right;
}

.meetup_action {
  margin-top: 60px;
}

span.meet__month {
  display: block;
  font-size: 21px;
  color: #006ee8;
  font-weight: 700;
  text-transform: uppercase;
  height: 28px;
}

span.meet__day {
  text-transform: uppercase;
  color: #021431;
  font-weight: 400;
}

.meetup__detail {
  padding: 8px;
  margin: 22px 0px;
  max-width: 78%;
}

.meetup__detail > h2 {
  font-size: 21px;
  line-height: 25px;
  color: #021431;
}

.meetup__detail .profile__btn.user {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.meetup__detail .meet__author {
  font-size: 14px;
  color: #006ee8;
  font-weight: 600;
  margin: 0 10px 0 0;
}

.meetup__detail .meet__author > span {
  color: #006ee8;
  text-decoration: underline;
}

.meetup__detail span.article__cat__separator {
  display: block;
  padding: 0;
  margin-right: 5px;
}

span.meet__location {
  background: url(../_images/ico-location.svg) no-repeat;
  padding-left: 15px;
  background-size: 9px;
  background-position: left;
  margin-left: 5px;
  font-size: 15px;
  color: #656a72;
  line-height: 19px;
}

span.meet__date {
  margin-right: 5px;
  font-size: 15px;
  color: #656a72;
}

.form-signin small {
  font-size: 12px;
}

.profile__form.create__meetup a.btn.btn-back {
  margin: 10px auto;
  display: block;
  max-width: 240px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 3px !important;
}

a.btn.btn-back > img {
  margin-right: 7px;
}

@media (max-width: 980px) {
  .meetup__date {
    padding: 18px 15px 18px 0;
  }
}

@media (max-width: 769px) {
  .profile__header.meetups__listing {
    padding: 30px 20px 60px 20px;
  }

  .meetup__card {
    margin: 20px auto;
  }

  p.meetups__txt {
    max-width: 85%;
  }

  .meetup__card > a {
    padding: 0 30px;
  }

  .meetup__detail > h2 {
    margin-bottom: 0;
  }

  .meetup__date {
    text-align: center;
    border-right: none;
    border-bottom: 2px solid #eff1f3;
    width: 100%;
    margin: 0;
  }

  span.meet__month {
    margin-right: 5px;
  }

  span.meet__day {
    font-size: 13px;
  }

  .meetup__detail {
    text-align: left;
    margin: 5px 0;
    padding: 5px 0;
    max-width: 100%;
  }
}

@media (max-width: 569px) {
  .meets__counter {
    margin: 30px 0 10px 0;
  }

  .meetup__detail .profile__btn.user {
    margin: 10px 10px 10px 0;
    width: 27px;
    height: 27px;
  }

  .meetup__detail span.article__cat__separator {
    font-size: 16px;
  }

  .meetup__detail p.meet__author {
    margin: 2px 0;
  }

  span.meet__date {
    margin: 5px 10px 5px 0;
  }

  span.meet__location {
    margin: 0;
  }
}

@media (max-width: 500px) {
  .profile__header.meetups__listing {
    height: auto;
    margin: 0;
  }

  .meets__counter {
    margin: 35px 0 10px 0;
    text-align: left;
    font-size: 20px;
  }
}

/*--------------------------------------------------------------- HOST MEETUP ---------------------------------------------------------------*/

.form__nofloating.last__meetup {
  border-bottom: 4px solid #eff1f3;
  padding-bottom: 25px !important;
  margin-bottom: 40px;
}

.profile__form.create__meetup a.btn.btn-primary {
  max-width: 315px;
  width: 100%;
  height: 50px;
  font-size: 18px;
  line-height: 40px;
}

.profile__header.meetups__listing {
  padding-bottom: 60px;
}

.profile__form.create__meetup {
  max-width: 495px;
  margin: 0 auto;
}

.profile__form.create__meetup .form__nofloating .form__nofloatingAlt{
  padding: 0 10px;
}

.modal-content.auto.meetup__created p.main__message {
  margin: 20px auto 50px auto;
}

@media (max-width: 500px) {
  .tabs__container.full__tabs ul.profile__tabs {
    max-width: 100%;
  }

  .tabs__container.full__tabs ul.profile__tabs > li {
    width: 33.33%;
    margin: 0 !important;
  }
}

/*--------------------------------------------------------------- SEARCH RESULTS PAGE ---------------------------------------------------------------*/

section.search__results__box .search__results {
  margin-bottom: 20px;
  font-size: 32px;
  color: #006ee8;
  padding: 10px 20px 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.filter__ap {
  font-family: "Nunito", sans-serif;
  margin-left: 20px;
  font-weight: 700;
  display: inline-block;
}

.details__filtering {
  margin-bottom: 30px;
}

section.search__results__box a.filter {
  background: #f6f6f7;
  padding: 10px 15px;
  max-width: 140px;
  min-width: 140px;
  margin: 20px 0 !important;
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
}

section.search__results__box a.filter:hover {
  background: #eef6ff;
}

section.search__results__box a.filter > img {
  max-width: 25px;
  margin-right: 10px;
}

section.search__results__box > .container article {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 50px;
  padding-bottom: 10px;
}

section.search__results__box > .container article .row__article__left {
  padding-right: 30px;
}

section.search__results__box > .container .search__results__body__l {
  padding-left: 0;
  padding-right: 45px;
  border-right: 1px solid #e0e0e0;
}

section.search__results__box > .container .article__right__ads {
  padding-left: 30px;
  text-align: center;
  padding-right: 0;
}

@media (max-width: 770px) {
  section.search__results__box > .container .search__results__body__l {
    padding: 0;
    border: none;
  }

  section.search__results__box > .container .article__right__ads {
    display: none;
  }

  section.search__results__box
  > .container
  article
  .row__article__left
  .boton__date__cta
  a.card__url {
    justify-content: flex-start;
    margin-top: 10px;
  }

  section.search__results__box > .container article {
    margin-bottom: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
  }

  section.search__results__box > .container article:first-child {
    border-top: 1px solid #e0e0e0;
    padding-top: 30px;
    margin-top: 0;
  }
}

/*--------------------------------------------------------------- SEARCH PARTNER OVERLAY ---------------------------------------------------------------*/

.vendor__search__modal section.partners__search__results {
  padding: 6vw 0;
}

/*--------------------------------------------------------------- PARTNERS PAGE ---------------------------------------------------------------*/

section.partners__search__results {
  padding: 0 0 30px 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

section.partners__search__box {
  background: #006fe8;
  padding: 55px 0;
  text-align: center;
}

section.partners__search__box * {
  color: #ffffff;
}

section.partners__search__box .partners__search__form {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

section.partners__search__box .ps-camp {
  position: relative;
  background: #ffffff;
  padding: 15px 20px;
  height: 70px;
  margin-right: 3px;
  width: 100%;
  border-radius: 1px;
  -webkit-box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  -moz-box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
}

section.partners__search__results .ps-camp {
  position: relative;
  background: #ffffff;
  padding: 10px 20px;
  height: 60px;
  margin-right: 3px;
  width: 100%;
  border-radius: 1px;
  -webkit-box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  -moz-box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
}

.filter__box {
  text-align: left;
}

.filter__box > button.btn-primary.collapsed {
  background-size: 10px 6px !important;
  border-bottom: none;
}

.filter__box:last-child > button.btn-primary.collapsed {
  border-bottom: 1px solid #05142f;
}

.filter__box > button.btn-primary {
  background-color: transparent !important;
  color: #006ee8 !important;
  text-align: left;
  border: 1px solid #05142f;
  border-left: none;
  border-right: none;
  margin: 0;
  height: 45px;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  max-width: 100%;
  outline-style: none;
  box-shadow: none;
}

.filter__box > span {
  color: #021431;
  text-align: left;
  border: 1px solid #05142f;
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0;
  height: 45px;
  padding: 0;
  font-size: 17px;
  font-weight: 700;
  max-width: 100%;
  width: 100%;
}

.filter__box > button.btn-primary > img {
  width: 10px;
  margin-right: 10px;
  transform: rotate(180deg);
}

.filter__box > button.btn-primary.collapsed > img {
  transform: rotate(0deg);
}

.filter__box .vendor_filter_body {
  margin: 35px 8px 25px 8px;
}

.filter__box .vendor_filter_body.brands {
  overflow: auto;
  max-height: 300px;
  margin: 35px 8px 5px 8px;
}

.filter__box .vendor_filter_body.brands #more {
  display: none;
}

div#vendor__brand button#brands {
  margin-bottom: 15px;
  border: none;
  background: none;
  color: #006ee8;
  font-size: 14px;
  font-weight: 600;
  margin-left: 3px;
}

/* SCROLLBAR FIREFOX */
.filter__box .vendor_filter_body.brands {
  scrollbar-width: thin;
  scrollbar-color: #e9e8e8 white;
}

/* SCROLLBAR CHROME */
.filter__box .vendor_filter_body.brands::-webkit-scrollbar {
  width: 12px;
}

.filter__box .vendor_filter_body.brands::-webkit-scrollbar-track {
  background: white;
}

.filter__box .vendor_filter_body.brands::-webkit-scrollbar-thumb {
  background-color: #e9e8e8;
  border-radius: 20px;
  border: 3px solid white;
}

section.partners__search__box .ps-camp:first-child,
section.partners__search__results .ps-camp:first-child {
  max-width: 360px;
}

section.partners__search__box .ps-camp:nth-child(2),
section.partners__search__results .ps-camp:nth-child(2) {
  max-width: 300px;
}

section.partners__search__box a.search__p {
  max-width: 150px;
  background: #021431;
  height: 70px;
  width: 100%;
  -webkit-box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  -moz-box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  font-size: 18px;
  font-weight: 600;
}

section.partners__search__box a.search__p > img {
  margin-right: 10px;
}

section.partners__search__box .ps-camp * {
  color: #021431;
  font-size: 15px;
}

section.partners__search__box .ps-camp > label,
section.partners__search__results .ps-camp > label {
  text-align: left;
  padding: 0;
  font-weight: 700;
  margin-bottom: 3px;
}

section.partners__search__box .ps-camp > input,
section.partners__search__box .ps-camp > select,
section.partners__search__results .ps-camp > input,
section.partners__search__results .ps-camp > select {
  border: none;
  font-size: 15px;
  -webkit-appearance: none;
}

section.partners__search__box .partners__search__form h1 {
  font-size: 35px;
  line-height: 37px;
  margin-bottom: 15px;
}

section.partners__search__box .partners__search__form h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 40px;
}

section.category__cards__partners {
  text-align: center;
  padding-top: 60px;
}

section.category__cards__partners .col-3 {
  padding-right: 0;
}

section.category__cards__partners .vendors__filtering > .modal {
  position: relative;
  opacity: 1;
  display: block;
  overflow: initial;
}

section.category__cards__partners .vendors__filtering > .modal .modal-content {
  padding: 0;
}

section.category__cards__partners
.vendors__filtering
> .modal
> .modal-dialog.vendor__contact__modal {
  transform: none;
  margin-top: 5px;
}

section.category__cards__partners
.vendors__filtering
> .modal
> .modal-dialog.vendor__contact__modal
> .modal-content {
  box-shadow: none;
  border: none;
}

section.category__cards__partners
.vendors__listing
> .search__filters__partners {
  padding: 0 15px;
  margin-bottom: 20px;
}

section.category__cards__partners.partners__results
.partners__cards
> div
> article
> .partner__information__box
> .partner__img
> a.parthern__image {
  width: 60px;
  height: 60px;
}

section.category__cards__partners.partners__results
.partners__cards
> div
> article
> .partner__information__box
h2
> a {
  font-size: 19px;
  font-weight: 700;
}

section.category__cards__partners .title__home {
  font-weight: 800;
}

section.category__cards__partners p {
  color: #4e4e4d;
  font-size: 16px;
  max-width: 960px;
  margin: 20px auto;
}

section.category__cards__partners .quick__cats {
  max-width: 800px;
  margin: 0 auto;
}

section.category__cards__partners .quick__cats > a.quick__cats__btn {
  font-size: 16px;
  padding: 7px 30px;
  border: 1px solid #006ee8;
  background: #ffffff;
  margin: 8px;
}

section.category__cards__partners .quick__cats > a.quick__cats__btn.active,
section.category__cards__partners .quick__cats > a.quick__cats__btn:hover {
  background: #f0f7ff;
}

section.vendor__names {
  background: #f7f7f7;
  padding: 60px 0;
  text-align: center;
}

section.vendor__names .title__home {
  font-weight: 800;
  margin-bottom: 30px;
}

section.vendor__names p {
  font-size: 18px;
  color: #4f4f4f;
  line-height: 28px;
}

section.vendor__names .vendor__names__btns {
  margin: 40px auto 0 auto;
  padding: 0 30px;
  overflow-x: auto;
  white-space: nowrap;
}

section.vendor__names > .container > a.more__followers {
  margin: 45px 30px 0 30px;
  float: none;
  display: inline-block;
  font-size: 15px;
}

section.vendor__names .column__vendors {
  width: 140px;
  display: inline-block;
  float: none;
  vertical-align: top;
}

section.vendor__names .column__vendors > a {
  display: block;
  text-align: left;
  font-size: 17px;
  line-height: 23px;
  margin-bottom: 25px;
  color: #007aff;
  white-space: break-spaces;
}

section.featured__partners {
  padding: 80px 0;
}

section.featured__partners > .container {
  text-align: center;
}

section.featured__partners > .container > a.more__followers {
  margin: 45px 30px 0 30px;
  float: none;
  display: inline-block;
  font-size: 16px;
}

.carrousel__container .navigation__featured {
  margin-top: 35px;
}

.buttons__featured > a,
.buttons__featured > a.disable {
  border: none !important;
  margin: 0;
  text-align: center;
}

.buttons__featured > a:hover {
  background: #f0f7ff;
}

.partners__cards > div > article {
  text-align: left;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(185, 183, 183, 0.61);
  -moz-box-shadow: 0px 2px 8px 0px rgba(185, 183, 183, 0.61);
  box-shadow: 0px 2px 8px 0px rgba(185, 183, 183, 0.61);
  padding: 30px 25px;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.partners__cards
> div
> article
> .partner__information__box
> .partner__img
> a.type__parther {
  font-size: 14px;
  font-weight: bold;
}

.partners__cards
> div
> article
> .partner__information__box
> .partner__img
> a.parthern__image {
  width: 69px;
  height: 69px;
  background: #ffffff;
  border: 0.62px solid #dcdcdc;
  border-radius: 50px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(231, 231, 231, 1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(231, 231, 231, 1);
  box-shadow: 0px 2px 4px 0px rgba(231, 231, 231, 1);
  display: inline-table;
}

.partners__cards
> div
> article
> .partner__information__box
> .partner__img
> a.parthern__image
> img {
  border-radius: 50px;
}

.partners__cards > div > article > .partner__img > h2 {
  font-size: 25px;
  line-height: 26px;
  margin-left: 20px;
}

.partners__cards > div > article > .partner__img > h2 > a {
  font-weight: 700;
}

.partners__cards > div > article > .partner__information__box > .partner__img {
  margin-bottom: 5px;
}

.p__grade {
  width: 98px;
  height: 36px;
  position: absolute;
  top: 20px;
  left: 15px;
  padding: 5px 0 5px 0;
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.public__profile__box .p__grade {
  left: -25px;
  top: -40px;
}
.public__profile__box .d-flex{
  width: 100%;
}

.p__grade.gold {
  background: url(../_images/label-gold.svg) no-repeat;
}

.p__grade.silver {
  background: url(../_images/label-silver.svg) no-repeat;
}

.p__grade.platinum {
  background: url(../_images/label-platinum.svg) no-repeat;
}

.p__grade.vendor {
  background: url(../_images/label-vendor.svg) no-repeat;
}

.partners__cards > div > article > .partner__information {
  color: #303236;
  line-height: 22px;
}

.home__page .container .partner__information > h2 > a {
  font-size: 22px;
  font-weight: bold;
}

.partners__cards
> div
> article
> .partner__information__box
> .partner__information
> p {
  font-size: 14px;
  line-height: 20px;
  color: #303236;
  padding-bottom: 10px;
}

.partners__cards
> div
> article
> .partner__information__box
> .partner__information
> p:after {
  content: "";
  display: block;
  width: 70px;
  border-bottom: 2px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 12px;
}

.partners__cards
> div
> article
> .partner__information__box
> .partner__categories
> span {
  font-size: 14px;
  font-weight: 700;
}

.partners__cards
> div
> article
> .partner__information__box
> .partner__categories
> .partner__categories__boxes {
  margin: 0 0 15px 0;
}

.partners__cards
> div
> article
> .partner__information__box
> .partner__categories
> .partner__categories__boxes
> span {
  font-size: 14px;
  margin-right: 5px;
}

.partners__cards
> div
> article
> .partner__categories
> .partner__categories__boxes
> a {
  font-size: 14px;
  color: #021431;
  text-decoration: underline;
}

.partners__cards > div > article > .partner__location {
  border-top: 1px solid #e5e6e8;
  border-bottom: 1px solid #e5e6e8;
  padding: 10px 0;
  background: url(../_images/ico-location.svg) no-repeat;
  background-size: 9px;
  background-position: 0 13px;
  padding-left: 18px;
  text-align: left;
}

.partners__cards > div > article > .partner__location > span {
  display: block;
}

.partners__cards
> div
> article
> .partner__location
> span.partner__location__tlt {
  font-size: 15px;
  font-weight: 600;
  color: #383a3c;
}

.partners__cards
> div
> article
> .partner__location
> span.partner__location__cities {
  font-size: 14px;
  color: #555d69;
  line-height: 18px;
}

.partners__cards > div > article > a.btn {
  margin: 0 auto;
  position: relative;
  display: block;
  max-width: 192px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  color: #006ee8;
  border-color: #006ee8;
}

.partners__cards > div > article > a.btn:hover {
  background: #006ee8 !important;
  color: #ffffff !important;
}

.partner__rank {
  margin: 15px 0;
}

.partner__rank > form.rating > label {
  width: 20px;
  height: 15px;
  text-align: left;
}

.partner__rank > form.rating > label::before {
  background-size: 15px;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.partner__rank > span {
  font-size: 16px;
  font-weight: 600;
}

.partners__cards > div > article > a.btn-primary {
  max-width: 205px;
  width: 100%;
  height: 40px;
  display: block;
  text-align: center;
  padding: 8px 0;
  font-weight: 700;
  margin: 0 auto;
  text-decoration: none;
}

section.category__partners {
  background: #f7f7f7;
  padding: 60px 0;
}

section.category__partners > .container {
  text-align: center;
}

section.category__partners h3.title__home,
section.featured__partners h3.title__home {
  font-weight: 800;
  text-align: center;
  font-size: 30px;
  margin-bottom: 50px;
}

section.category__partners > .container > a.more__followers {
  margin: 45px 30px 0 30px;
  float: none;
  display: inline-block;
  font-size: 16px;
}

section.category__partners .partners__cards:nth-child(3) {
  border-bottom: 1px solid #e7e7e8;
  padding-bottom: 20px;
}

.partners__cards a.more__followers {
  float: none;
  margin: 20px auto;
  display: table;
  font-size: 15px;
  font-weight: 600;
}

section.partners__search__box a.form__partner__trigger {
  display: none;
  background: #ffffff;
  height: 55px;
  border-radius: 3px;
  padding: 17px 0;
  -webkit-box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  -moz-box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
  box-shadow: 2px 4px 8px 0px rgba(33, 38, 45, 0.3);
}

section.partners__search__box a.form__partner__trigger > span {
  color: #021431;
  font-size: 16px;
  font-weight: 600;
}

section.partners__search__box a.form__partner__trigger > span {
  font-size: 16px;
  color: #021431;
  font-weight: 600;
  line-height: 22px;
}

section.partners__search__box a.form__partner__trigger > img {
  width: 21px;
  margin-right: 5px;
  top: -2px;
  position: relative;
}

.vendors__filtering button.close {
  display: none;
}

.vendors__listing > button.filter {
  width: 100%;
  max-width: 100%;
  height: 40px;
  line-height: 10px;
  margin-bottom: 20px;
  display: none;
}

button.clear__filter {
  width: 100%;
  height: 40px;
  line-height: 12px;
  font-size: 15px;
  max-width: 90%;
}

@media (max-width: 800px) {
  main.vendors__page {
    margin: 0;
  }
}

@media (max-width: 769px) {
  section.category__cards__partners .vendors__filtering > .modal {
    opacity: 0;
    position: fixed;
    display: none;
    overflow-x: hidden;
    overflow-y: auto;
    background: #ffffff;
  }

  section.category__cards__partners .vendors__filtering > .modal.show {
    opacity: 1;
  }

  .vendors__listing > button.filter {
    width: 100%;
    max-width: 100%;
    height: 40px;
    line-height: 10px;
    margin-bottom: 20px;
  }

  section.category__cards__partners
  .vendors__filtering
  > .modal
  > .modal-dialog.vendor__contact__modal
  > .modal-content {
    background: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    -webkit-box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
    -moz-box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
    box-shadow: -6px 6px 10px 0px rgba(16, 29, 49, 0.54);
    min-height: 80vh;
    padding: 10px 7vw;
  }

  section.category__cards__partners
  .vendors__listing
  > .search__filters__partners {
    padding: 0;
  }

  .search__results {
    margin-bottom: 20px;
  }

  .vendors__filtering button.close,
  .vendors__listing > button.filter {
    display: block;
  }
}

@media (max-width: 580px) {
  section.featured__partners {
    padding: 50px 0;
  }

  section.category__cards__partners .quick__cats > a.quick__cats__btn {
    font-size: 13px;
    padding: 8px 15px 6px 15px;
  }

  section.partners__search__box .partners__search__form h2 {
    font-size: 20px;
    line-height: 23px;
    margin-top: 30px;
  }

  section.partners__search__box .ps-camp,
  section.partners__search__box a.search__p {
    display: none !important;
  }

  section.partners__search__box a.form__partner__trigger {
    display: block;
  }

  section.category__partners h3.title__home,
  section.featured__partners h3.title__home {
    font-size: 23px;
    margin-bottom: 35px;
  }

  section.featured__partners > .container,
  section.category__partners > .container {
    padding: 0 20px;
  }

  section.featured__partners > .container .partners__cards > div,
  section.category__partners > .container .partners__cards > div {
    padding: 0;
    margin-bottom: 30px;
    display: none;
  }

  section.category__partners > .container .partners__cards > div {
    padding: 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #d6d9de;
  }

  .carrousel__container .navigation__featured {
    display: flex !important;
    margin-top: 0;
  }

  section.featured__partners > .container .partners__cards > div:first-child,
  section.category__partners > .container .partners__cards > div:first-child {
    display: block;
  }

  section.category__partners > .container > a.more__followers,
  section.featured__partners > .container > a.more__followers {
    margin: 0 30px 40px 30px;
  }

  .partners__cards .p__grade {
    left: 0;
  }
}

/*---------------------------------------------------------------VENDOR CONTACT OVERLAY ---------------------------------------------------------------*/

.vendor__contact__modal > .modal-content {
  padding: 70px 7vw;
  text-align: left;
  border-top: 3px solid #006ee8;
}

.vendor__contact__modal {
  max-width: 700px;
}

.vendor__contact__modal
> .modal-content
> .vendor__contact
> .partner__img
> a {
  width: 90px;
  height: 90px;
  background: #ffffff;
  border: 0.62px solid #dcdcdc;
  border-radius: 50px;
  display: inline-table;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(231, 231, 231, 1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(231, 231, 231, 1);
  box-shadow: 0px 2px 4px 0px rgba(231, 231, 231, 1);
  margin-right: 20px;
}

.vendor__contact__modal
> .modal-content
> .vendor__contact
> .partner__img
> a
> img {
  border-radius: 50px;
}

.vendor__contact__modal .vendor__contact > .partner__img > h2 {
  margin: 0;
  font-size: 25px;
}

.vendor__contact__modal > .modal-content > .vendor__contact > p.main__message {
  font-size: 18px;
  line-height: 26px;
  color: #1c1c1d;
}

.vendor__contact__modal
> .modal-content
> .vendor__contact
> p.main__message:after {
  content: "";
  display: block;
  width: 40px;
  border-bottom: 3px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 9px;
  border-radius: 90px;
}

.vendor__contact__modal
> .modal-content
> .vendor__contact
> p.main__message:after {
  content: "";
  display: block;
  width: 40px;
  border-bottom: 3px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 9px;
  border-radius: 90px;
}

.vendor__contact .partner__img {
  margin-bottom: 20px;
}

.vendor__contact a.btn-primary {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 40px;
}

.vendor__contact > form > a {
  text-align: center;
  display: block;
  margin-top: 20px;
  font-weight: 700;
}

/*--------------------------------------------------------------- PARTNERS SEARCH RESULTS ---------------------------------------------------------------*/

section.partners__search__results h2.title__home {
  font-size: 29px;
  margin-bottom: 15px;
}

section.partners__search__results .ps-camp > label {
  font-size: 12px;
  font-weight: 600;
}

.breadcrumbs {
  font-size: 13px;
  margin-bottom: 20px;
}

.breadcrumbs span.b__separator {
  margin: 0 5px;
}

.breadcrumbs span.b__current {
  color: #555d69;
}

section.partners__search__results .ps-camp {
  box-shadow: none;
  border: 1px solid #dcdde0;
}

section.partners__search__results a.search__p {
  max-width: 150px;
  background: #021431;
  height: 60px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

section.partners__search__results a.search__p > img {
  display: none;
}

section.partners__search__results .ps-camp > a.clear__form {
  position: absolute;
  right: 10px;
  top: 24px;
  opacity: 0.34;
  width: 20px;
  height: 20px;
}

section.partners__search__results .ps-camp > a.clear__form:hover {
  opacity: 1;
}

.search__filters__partners .order__by > select {
  -webkit-appearance: none;
  padding: 0 10px;
  width: 215px;
  right: 0;
  position: relative;
  height: 40px;
  border-radius: 0;
  font-size: 14px;
  background-position: 95% center;
  top: 0;
  outline-style: none;
  box-shadow: none;
}

.search__filters__partners .tabs__container {
  position: relative;
  padding-top: 10px;
}

section.category__cards__partners.partners__results
.partners__cards
> div
> article
> a.btn {
  font-size: 14px;
  width: 90%;
}

section.partners__search__results .partners__search__form {
  padding: 0;
}

.search__filters__partners .search__results span.sr__1 {
  font-size: 20px;
  font-weight: 600;
  margin-right: 10px;
}

.search__filters__partners .search__results span.sr__2 {
  font-size: 18px;
  color: #006ee8;
  font-weight: 600;
}

section.category__cards__partners.partners__results .partners__cards {
  margin-bottom: 30px;
}

section.category__cards__partners.partners__results
.partners__cards
> .small__column__articles {
  margin-bottom: 20px;
}

section.search__filters__partners {
  padding-top: 0;
}

section.search__filters__partners > .container {
  position: relative;
}

section.search__filters__partners > .container > .order__by {
  position: relative;
  top: -50px;
  z-index: 2;
}

@media (max-width: 800px) {
  .breadcrumbs {
    display: none;
  }

  section.search__filters__partners > .container {
    padding: 0;
  }

  section.search__filters__partners > .container .search__results {
    justify-content: center !important;
    margin-bottom: 15px;
  }

  section.search__filters__partners .groups__tabs__block {
    min-width: 600px;
  }

  section.search__filters__partners .groups__tabs__block > li {
    width: auto;
  }

  section.search__filters__partners > .container > .order__by {
    position: relative;
    top: 20px;
    width: 90%;
    display: block;
    margin: 0 auto;
  }

  section.search__filters__partners > .container > .order__by > select#orderby {
    width: 100%;
    background-position: 98% center;
  }

  section.search__filters__partners {
    margin-bottom: 40px;
  }

  .vendors__listing {
    padding-left: 10px;
    padding-right: 10px;
  }

  section.category__cards__partners > .container {
    padding: 0 20px;
  }

  section.search__filters__partners .groups__tabs__block > li {
    width: auto;
  }

  section.search__filters__partners > .container > .order__by {
    position: relative;
    top: 20px;
    width: 90%;
    display: block;
    margin: 0 auto;
  }

  section.search__filters__partners > .container > .order__by > select#orderby {
    width: 100%;
    background-position: 98% center;
  }

  section.search__filters__partners {
    margin-bottom: 40px;
  }

  section.category__cards__partners > .container {
    padding: 0 20px;
  }

  section.category__cards__partners.partners__results .partners__cards > div {
    padding: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 780px) {
  section.partners__search__results .ps-camp {
    max-width: 100% !important;
    margin-bottom: 15px;
    height: 58px;
    padding: 10px 20px;
  }

  section.partners__search__results .ps-camp > a.clear__form {
    top: 17px;
  }

  section.partners__search__results a.search__p {
    height: 55px;
    max-width: 100%;
  }

  .search__filters__partners .order__by > select {
    width: 100%;
  }
}

@media (max-width: 580px) {
  section.partners__search__results h2.title__home {
    font-size: 24px;
    text-align: center;
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------- PARTNERS DETAILS ---------------------------------------------------------------*/

section.vendor__profile {
  background: #f7f7f7;
  padding-bottom: 5px;
}

section.vendor__profile .profile__header {
  margin: 0;
  padding: 25px;
}

.vendor__profile .public__profile__box {
  padding-left: 0;
}

section.profile__page.vendor {
  padding-top: 16px;
}

.profile__information > .profile__name > a.type__parther {
  position: absolute;
  font-size: 13px;
  top: -5px;
}

.profile__information > .profile__name > .user__name {
  margin-top: 15px;
}

.profile__information > .vendor__details.row {
  padding-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.profile__information .p__grade {
  left: -25px;
  top: -40px;
}

.vendor__profile .public__profile__box .profile__main__image > img {
  width: 133px;
  height: 133px;
  border-radius: 50%;
  border: 1.36px solid #f0f0f0;
  min-width: 133px;
  -webkit-box-shadow: 2px 2px 7px 0px rgba(218, 218, 218, 1);
  -moz-box-shadow: 2px 2px 7px 0px rgba(218, 218, 218, 1);
  box-shadow: 2px 2px 7px 0px rgba(218, 218, 218, 1);
}

.vendor__profile .public__profile__box h1.user__name {
  font-size: 25px;
  font-weight: 700;
}

.vendor__profile .public__profile__box span.ocuppation {
  font-size: 17px;
  color: #303236;
  line-height: 22px;
}

.vendor__profile .public__profile__box .partner__rank span {
  position: relative;
  top: 2px;
}

.vendor__details > .partner__location {
  padding: 2px 0 0 15px;
  background: url(../_images/ico-location.svg) no-repeat;
  background-size: 10px;
  background-position: 0 6px;
  margin-left: 20px;
}

.vendor__details > .partner__location > span {
  display: block;
}

.vendor__details > .partner__location > span.partner__location__tlt {
  font-size: 15px;
  font-weight: 600;
  color: #006ee8;
  margin-right: 10px;
}

.vendor__details > .partner__location > span.partner__location__cities {
  font-size: 14px;
  color: #555d69;
  line-height: 18px;
  position: relative;
  top: -1px;
}

.details__vendor {
  padding: 30px 30px 50px 30px;
}

.vendor__description h3,
section.profile__page.vendor .vendor__certifications > h3 {
  font-size: 17px;
  margin-bottom: 25px;
}

.vendor__description h3:after,
section.profile__page.vendor .vendor__certifications > h3:after {
  content: "";
  display: block;
  width: 25px;
  border-bottom: 2px solid #006ee8;
  position: relative;
  bottom: 25%;
  right: 0;
  top: 5px;
}

.vendor__description p {
  font-size: 15px;
  line-height: 22px;
  color: #555d69;
}

.vendor__description {
  margin-bottom: 30px;
}

section.profile__page.vendor .details__vendor a.btn-primary {
  width: 100%;
  max-width: 230px;
  display: block;
  text-align: center;
  height: 40px;
  padding: 8px 0;
  text-decoration: none !important;
  margin: 40px 0 0 20px;
  font-size: 15px;
}

.vendor__details .website > img {
  margin-right: 15px;
  width: 23px;
  height: 23px;
}

.vendor__details .website > a {
  font-size: 15px;
  color: #2d2f31;
  text-decoration: underline;
}

.vendor__details .website > a:hover {
  color: #006ee8;
}

section.vendor__profile .vendor__details > a.btn-primary {
  width: 230px;
  display: block;
  height: 40px;
  text-align: center;
  padding: 8px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  margin: 0;
}

.vendor__details .social__networks > a:hover {
  color: #006ee8;
}

.vendor__details .social__networks > a.fa-linkedin-in {
  font-size: 19px;
}

.vendor__details .social__networks > a.fa-facebook-f {
  font-size: 17px;
}

.vendor__details .social__networks > a.fa-twitter {
  font-size: 18px;
}

.vendor__details .social__networks {
  margin: 15px 0 15px 45px;
}

section.vendor__profile .vendor__details > a.btn-primary {
  width: 230px;
  display: block;
  height: 40px;
  text-align: center;
  padding: 8px;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

section.profile__page.vendor .profile__body {
  padding-left: 0;
}

section.profile__page.vendor .vendor__complements {
  padding-right: 0;
}

section.profile__page.vendor .vendor__certifications > .certifications {
  padding: 0;
}

section.profile__page.vendor .vendor__certifications,
section.profile__page.vendor .vendor__testimonials {
  border-right: 1px solid #dcdde0;
  border-left: 1px solid #dcdde0;
  border-top: 1px solid #dcdde0;
  border-bottom: 1px solid #006ee8;
  border-radius: 3px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  -moz-box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  box-shadow: 2px 2px 4px 0px rgba(210, 210, 210, 0.5);
  padding: 25px;
  margin-bottom: 15px;
}

section.profile__page.vendor .vendor__certifications .certification__box {
  background: #fdfdfd;
  display: block;
  border: 1px solid #eff1f3;
  padding: 20px 15px;
  color: #323232;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
}

section.profile__page.vendor .vendor__certifications > .vendor__categories {
  margin-bottom: 30px;
}

section.profile__page.vendor
.vendor__certifications
> .vendor__categories:last-child {
  margin: 0;
}

section.profile__page.vendor
.vendor__certifications
> .vendor__categories
> .vendor__categories__labels {
  margin-bottom: 20px;
}

section.profile__page.vendor
.vendor__certifications
.vendor__categories
> span.vendor__categories__tlt {
  font-size: 15px;
  font-weight: 700;
}

section.profile__page.vendor
.vendor__certifications
.vendor__categories
> .vendor__categories__labels
> span {
  font-size: 14px;
  margin-right: 10px;
}

section.profile__page.vendor .vendor__testimonials h3.title__home {
  font-size: 23px;
  font-weight: 800;
}

section.profile__page.vendor .vendor__certifications > a.btn {
  margin: 0 auto;
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  font-size: 14px;
  color: #006ee8;
  border-color: #006ee8;
  margin-top: 40px;
}

section.profile__page.vendor .vendor__certifications > a.btn:hover {
  background: #006ee8 !important;
  color: #ffffff !important;
}

.distributor__listing .more__cta {
  float: left;
  display: block;
  border: 1px solid #006ee8;
  color: #006ee8;
  padding: 2px 18px;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none !important;
}

.distributor__listing .more__cta:hover {
  background: #006ee8 !important;
  color: #ffffff !important;
}

.modal-content > .distributor__listing {
  margin-top: 60px;
  min-height: auto;
  margin-bottom: 40px;
}

.modal-content > .distributor__listing .follow__picture {
  width: 15%;
  border: 1px solid #d6d6d6;
  padding: 0;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
  height: 78px;
}

.modal-content > .distributor__listing .follow__detail {
  width: 80%;
}

.modal-content > .distributor__listing .follow__picture > img {
  width: 75px;
  height: 75px;
  margin: 0 auto;
}

.v__testimonial {
  border-top: 1px solid #006ee8;
  border-left: 1px solid #006ee8;
  border-right: 1px solid #006ee8;
  padding: 20px 20px 10px 20px;
}

.v__testimonials__container:after {
  content: "";
  display: block;
  background: url(../_images/testimonial-box.svg) no-repeat;
  width: 100%;
  height: 30px;
  position: relative;
  top: 0px;
}

@media (max-width: 990px) {
  section.profile__page.vendor .profile__body {
    padding: 0;
  }

  section.profile__page.vendor .vendor__complements {
    padding-left: 0;
  }
}

@media (max-width: 925px) {
  .vendor__details > .partner__location {
    margin: 10px 0 0 2px;
  }
}

@media (max-width: 780px) {
  section.profile__page.vendor .details__vendor a.btn-primary {
    max-width: 100%;
    margin: 0;
  }

  .profile__information > .profile__name > .user__name {
    padding: 0;
  }

  .vendor__details > .partner__location {
    text-align: center !important;
    margin-top: 20px;
    justify-content: center !important;
    background-position: 5px 16px;
    padding: 12px 0;
    width: 220px;
    margin: 0 auto;
  }

  .vendor__profile .vendor__details {
    text-align: center !important;
    margin-top: 20px;
    justify-content: center !important;
  }

  section.vendor__profile .vendor__details > a.btn-primary {
    width: 100%;
  }
}

@media (max-width: 780px) {
  section.profile__page.vendor .details__vendor a.btn-primary {
    max-width: 100%;
    margin: 0;
  }

  .vendor__details > .partner__location {
    text-align: center !important;
    margin-top: 20px;
    justify-content: center !important;
    padding: 2px 0 0 0;
    width: 220px;
    margin: 0 0 0 20px;
  }

  .profile__information > .vendor__details.row {
    margin: 20px 0;
    padding: 0;
  }

  section.vendor__profile .vendor__details > a.btn-primary {
    width: 100%;
  }

  .details__vendor {
    padding: 30px 30px 0 30px;
  }

  section.vendor__profile .profile__header > .vendor__details {
    justify-content: center !important;
    margin: 25px 0 5px 0;
  }

  .modal-content > .distributor__listing .follow__detail {
    width: 60%;
  }
}

@media (max-width: 670px) {
  .vendor__details > .partner__location {
    margin: 10px 40px 0 2px;
  }
}

@media (max-width: 500px) {
  section.vendor__profile > .container {
    padding: 0;
  }

  section.vendor__profile .profile__header {
    height: auto;
    padding: 0 15px 25px 15px;
  }

  .vendor__profile .public__profile__box {
    padding: 0;
    margin-top: 30px;
  }

  .vendor__profile .public__profile__box .partner__rank {
    justify-content: center;
  }

  .vendor__profile .public__profile__box .profile__main__image {
    width: 120px;
    height: 120px;
  }

  .vendor__profile .public__profile__box .profile__main__image > img {
    width: 120px !important;
    height: 120px !important;
    display: block !important;
    min-width: 120px;
  }

  .profile__information .p__grade {
    left: -32px;
    top: -185px;
  }

  .profile__information > .profile__name > a.type__parther {
    position: relative;
    top: 0;
  }
}

@media (max-width: 350px) {
  .vendor__details > .partner__location {
    margin: 10px 0 0 2px;
  }
}

/*--------------------------------------------------------------- NOTIFICATIONS ---------------------------------------------------------------*/

section.notifications {
  background: #f7f7f7;
  padding-bottom: 5px;
  height: 100vh;
  
  
}

.notifications {
  display: block;
  text-align: right;
  margin: 0 auto;
  padding: 20px 20px 40px 20px;
  width: 100%;
  
}

.notifications__header a.allread {
  font-size: 14px;
  color: #05142f;
  font-weight: 600;
  text-decoration: none;
  
  
}

.notifications__header a.allread:hover {
  color: #006ee8;
}

.notifications > img {
  margin-bottom: 30px;
}

.notifications > span {
  display: block;
  font-size: 20px;
  color: #05142f;
}

.notifications h2.title__home {
  display: block;
  text-align: left;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 20px;
}

.notifications.no__nots {
  padding-bottom: 60px;
  text-align: center;
}

.notifications.no__nots h2.title__home {
  text-align: center;
  margin: 0 auto 20px auto;
}

.notification__profile {
  width: 45px;
  height: 45px;
  margin-right: 15px;
  min-width: 45px;
  
}



.notifications .notification__profile > img {
  display: block;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}


.notification__detail span.not__content {
  display: block;
  font-size: 15px;
}

.notification__detail span.not__date {
  font-size: 13px;
}

.notifications__container a {
  display: block;
  padding: 12px 20px;
  text-decoration: none !important;
  -webkit-box-shadow: 0px 1px 0px 0px rgba(228, 228, 228, 0.51);
  -moz-box-shadow: 0px 1px 0px 0px rgba(228, 228, 228, 0.51);
  box-shadow: 0px 1px 0px 0px rgba(228, 228, 228, 0.51);
  margin-bottom: 3px;
  
}

.notifications__container a.read {
  text-align: left;
  background: #ffffff;
  
  width: 100%;
}

.notifications__container a.unread {
  background: #eff1f3;
}

@media (max-width: 500px) {
  section.notifications {
    height: auto;
  }

  section.notifications.no__not {
    height: 100vh;
  }

  section.notifications .profile__header {
    margin: 0;
    height: auto;
    padding: 10px;
  }

  section.notifications .container {
    padding: 0;
  }
}

@media (max-width: 460px) {
  .notifications {
    padding: 30px 20px 40px 20px;
  }
}

/*---------------------------------------------------------- MODAL STYLES GENERAL ----------------------------------------------------------------*/
.modal.show .modal-dialog {
  max-width: 700px;
}

.modal.show .modal-community {
  max-width: 1200px;
}

.modal.show .modal-community .modal-dialog {
  max-width: 100%;
}

/*---------------------------------------------------------- MODAL TEACH CONTENT ----------------------------------------------------------------*/
.mce__buttons .text__editor {
  width: 100%;
  justify-items: flex-end;
  text-align: right;
}

.mce__buttons .text__editor .buttons {
  width: 100%;
  justify-items: flex-end;
  text-align: right;
}

.mce__buttons .text__editor .public__contact {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------------------------------------------------------- ERROR 404 ---------------------------------------------------------------*/

section.notifications.error {
  height: 80vh;
}

section.notifications.error .profile__header {
  text-align: center;
  padding-bottom: 70px;
}

section.notifications.error .profile__header h1.error404 {
  font-size: 55px;
  color: #006ee8;
  font-weight: 700;
  margin: 10px 0;
}

section.notifications.error .profile__header > img {
  margin-bottom: 0;
}

section.notifications.error .profile__header > span {
  font-size: 20px;
  margin-bottom: 30px;
  display: block;
}

section.notifications.error .profile__header > a.btn-primary {
  display: block;
  width: 200px;
  margin: 0 auto;
  height: 50px;
  padding: 12px;
  font-weight: 700;
  text-decoration: none;
}

@media (max-width: 500px) {
  section.notifications.error .profile__header h1.error404 {
    font-size: 40px;
    margin: 20px 0;
  }

  section.notifications.error .profile__header > span {
    width: 70%;
    margin: 0 auto 30px auto;
    font-size: 18px;
    line-height: 22px;
  }
}

/*--------------------------------------------------------------- ALL MEDIA QUERYS ---------------------------------------------------------------*/

@media (max-width: 1300px) {
  .container {
    padding: 0 2vw;
  }

  footer > .container {
    padding: 40px 2vw;
  }
}

@media (max-width: 1150px) {
  main {
    margin-top: 55px;
  }

  .mobile {
    display: block !important;
  }

  .mobile__cat {
    display: flex !important;
  }

  .desktop,
  nav > ul > li > a > svg,
  nav > ul > li:hover > a > svg,
  .desktop__cat {
    display: none !important;
  }

  span.menu__toggle {
    padding-left: 10px;
  }

  .navbar {
    height: 55px;
  }

  nav > ul {
    display: block;
  }

  ul.dropdown-content {
    visibility: visible;
    opacity: 1;
    position: relative;
    width: 100%;
    top: 0;
    display: block;
    box-shadow: none;
    clear: both;
    padding: 0;
    margin-top: 10px;
  }

  nav > ul > li {
    height: auto;
    clear: both;
    border-top: 1px solid #021431;
    padding: 20px 0;
  }

  .big__menu__tittle {
    display: none;
  }

  ul.dropdown-content > li {
    float: left;
    width: 50%;
  }

  ul.dropdown-content a,
  ul.lista__user a {
    border: none;
    font-size: 15px;
    font-weight: 400;
    padding: 5px 0;
  }

  a.tech__icon.mobile {
    margin-bottom: 10px;
  }

  .mobile__options {
    display: flex !important;
    margin-bottom: 15px;
  }

  .mobile__options > a.btn.btn-primary.sign__header {
    width: 100%;
    color: #021431;
    height: 40px;
    padding: 8px;
  }

  .mobile__options > a.btn.btn-secondary.login__header {
    width: 100%;
    height: 40px;
    padding: 8px;
  }

  .mobile__options > a.btn.btn-secondary.login__header:hover {
    color: #006ee8 !important;
  }

  .about__links.mobile > ul {
    list-style: none;
    border-top: 1px solid #021431;
    padding: 15px 0 0 0;
  }

  .about__links.mobile {
    padding: 0 30px;
  }

  .about__links.mobile > ul > li > a {
    padding: 10px 0;
    font-weight: 600;
  }

  .social__container {
    display: flex;
  }

  .social__follow.mobile {
    padding: 15px 0 0 0;
    border-top: 1px solid #d6d9de;
    border-bottom: 1px solid #d6d9de;
    margin: 0 30px;
  }

  .social__follow.mobile:after {
    display: none;
  }
}

@media (max-width: 1060px) {
  .footer__logo {
    display: none;
  }
}

@media (max-width: 1000px) {
  footer {
    height: auto;
  }

  .copyright__mobile {
    display: block;
    order: 3;
    text-align: center;
  }

  footer > .container {
    flex-direction: column;
    padding: 20px 2vw;
  }

  .left__footer {
    justify-content: center;
    order: 2;
  }

  .social__follow {
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  .right__footer {
    order: 1;
    display: flex;
  }

  .right__footer > .copyright {
    display: none;
  }

  .right__footer > .social__follow > span {
    display: none;
  }

  .social__follow:after {
    left: 0;
    margin: 0 auto;
    width: 90%;
  }

  .quick__footer__links,
  .legal__footer__links {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
  }

  .legal__footer__links > .navbar-nav {
    justify-content: center;
    flex-wrap: wrap;
  }

  .legal__footer__links ul.navbar-nav > li > a {
    padding: 0 20px 0 5px;
  }

  .copyright__content {
    justify-content: center;
  }

  .legal__footer__links ul.navbar-nav.first__term > li:last-child > a {
    background: none;
  }

  .quick__footer__links > ul.navbar-nav,
  .legal__footer__links > ul.navbar-nav {
    justify-content: center;
    flex-wrap: wrap;
  }

  .quick__footer__links > ul.navbar-nav a {
    padding: 0 10px;
    font-size: 15px;
  }

  .legal__footer__links ul.navbar-nav > li:last-child > a {
    background: none;
    padding: 0 10px;
    margin: 0;
  }

  .languaje__desktop {
    display: none !important;
  }

  .copyright__content > p {
    margin: 0;
  }

  .languaje__mobile {
    margin: 20px 0;
  }

  .footer__menus__container {
    margin: 0 auto;
  }

  .main__search__box form {
    max-width: 550px;
    margin: 0 auto;
  }

  .search__form__container.small {
    margin: 0 auto;
  }

  .main__search__box {
    height: auto;
  }

  .main__search__box > .container > .search__box__contain {
    padding: 0;
  }

  p.text__about__tfc__p1 {
    font-size: 22px;
    line-height: 21px;
  }

  p.text__about__tfc__p2 {
    font-size: 17px;
  }
}

@media (max-width: 990px) {
  section.three__columns .medium__column__articles,
  section.three__columns .medium__column__articles:first-child,
  section.three__columns .medium__column__articles:last-child {
    border-right: none;
    padding: 0;
  }

  .search__box__contain h1.main__title,
  span.form__title {
    text-align: center;
    display: block;
    padding: 0 0 10px 0;
  }

  span.form__title:after {
    margin: 0 auto;
  }

  .search__filter__tabs.small {
    margin: 20px auto;
  }
}

@media (max-width: 770px) {
  .navbar > .container {
    padding: 0 10px;
  }

  .navbar-light .navbar-brand {
    color: transparent;
  }

  p.text__about__tfc__p1 {
    max-width: 100%;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  p.text__about__tfc__p2 {
    max-width: 100%;
  }

  p.text__about__tfc__p1:after {
    padding-bottom: 10px;
  }

  .text__about__tfc {
    padding-bottom: 100px;
  }

  a.community__tf__cta > span {
    max-width: 100%;
    font-size: 19px;
  }

  a.community__tf__cta {
    padding: 16px 20px;
  }

  .navbar-light .navbar-brand {
    max-width: 87px;
  }

  .search__box__contain {
    padding: 30px 15px 15vw 25px;
  }

  a.btn.btn-primary.sign__header {
    width: 68px;
    font-size: 14px;
    padding: 0;
    height: 24px;
  }

  span.menu__toggle > svg {
    position: relative;
    top: -2px;
  }
}

@media (max-width: 610px) {
  h1.main__title {
    width: 100%;
    height: auto;
  }

  h1.main__title > span.highlight__txt.p2 {
    background: none;
    left: 0;
    padding: 0;
    margin-left: -10px;
  }

  h1.main__title > span.highlight__txt {
    left: -8px;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0 25px;
  }
}

@media (max-width: 570px) {
  h1.main__title {
    background: none;
    padding: 0;
    font-size: 25px;
    line-height: 29px;
  }

  .search__form__container.small input[type="search"] {
    border: 1px solid #021431;
    max-width: 100%;
  }

  .search__form__container.small {
    max-width: 100%;
    padding: 0;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

@media screen and (max-width: 415px) {
  h1.main__title > span.highlight__txt.p2 {
    margin: 0;
  }
}

@media (max-width: 380px) {
  ul.lista__user.dropdown-list {
    width: 240px;
    margin-left: -188px;
  }
}

/*--------------------------------------------------------------- COOKIESPOLICIES ---------------------------------------------------------------*/

.cookies-layer {
  position: fixed;
  bottom: 0;
  background: #ffffff;
  border-top: 2px solid #006ee8;
  width: 100%;
  height: auto;
  padding: 16px;
  text-align: center;
}

.cookies-layer__content {
  margin-bottom: 15px;
  font-size: 12px;
}

.cookies-layer__button {
  font-weight: 400;
  width: 120px;
  font-size: 12px;
  margin: 0 4px;
  height: 30px;
  padding: 3px;
  background: #006ee8 !important;
  border: 1px solid #006ee8;
  color: #ffffff !important;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

/*--------------------------------------------------------------- AUTOCOMPLETE ---------------------------------------------------------------*/

.no-autocomplete {
  color: #999;
  padding: 8px;
}
.autocomplete {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 97.5%;
}
.autocomplete li {
  padding: 8px;
}
.autocomplete > .active,
.autocomplete li:hover {
  background-color: darkgray;
  cursor: pointer;
  font-weight: 700;
}
.autocomplete li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

/*---- Custom css forms ----*/

.MuiFormControl-root {
  height: auto;
}