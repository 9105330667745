.term_style {
  padding: 3rem 14rem;
  justify-content: flex-start;
  font-family: "Nunito Sans", sans-serif;
  font-size: 17px;
  line-height: 26px;
  color: #05142f;
}

@media (max-width: 1200px) {
  .term_style {
    padding: 5rem 0.5rem;
    justify-content: center;
    
  }

 
}
