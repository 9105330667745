.text_content {
  font-size: 18px;
  line-height: 25px;
  color: white;
}

.text_content_alt {
  font-size: 18px;
  line-height: 25px;
  color: black;
}

section.courses__group {
  background: #fed806;
  padding: 60px 0 0;
}

section.featured__courses
  .courses__heading
  .navigation__courses
  > .buttons__courses {
  right: 90px;
  position: relative;
}

.courses__heading {
  margin: 0 15px 40px 15px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.courses__heading img {
  margin-right: 0px !important;
}

.text_content_alt {
  width: 50%;
  margin-bottom: 35px;
}

.text_content-course {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
}

@media (max-width: 570px) {
  .navigation__courses {
    display: none !important;
  }

  .text_content_alt {
    width: 100%;
  }

  .courses_list {
    display: block !important;
  }

  .courses__heading {
    display: block;
  }

  .courses_list-item {
    padding-bottom: 100px;
  }

  section.courses__group {
    padding: 25px 0 0 0;
  }

  .courses__group .text_content-course {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #333333;
    margin: 0 !important;
  }
}

.courses__group .text_content-course {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #333333;
  margin: auto;
}

.nav__counter > img {
  margin: 0 20px;
}

section.featured__courses .courses__carousel {
  position: relative;
}

.courses_list {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}

.courses_list li{
  margin-bottom: 100px;
}

.courses_content img {
  width: 270px;
  height: 170px;
  border-radius: 3px;
}

.courses_content {
  position: relative;
  width: 270px;
  height: 170px;
  display: flex;
  justify-content: center;
}

.courses_content .courses_info {
  background: #ffffff;
  border-radius: 0px 20px;
  position: absolute;
  width: 240px;
  top: 110px;
  height: 115px;
  padding: 20px;
}

.courses_content .courses_info h4{
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 130%;
  color: #006FE8;
  height: 55px;
}



.courses__carousel {
  padding-bottom: 60px;
}

/*---- Internal courses ----- */
section.internal-course {
  width: 100%;
  border: none;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  padding: 0;
}

.internal-container-course {
  background: #ffffff;
  width: 100%;
  min-width: 1140px;
  margin: 30px 150px 0;
  padding: 45px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
  position: relative;
}

.image-course {
  width: 525px;
  height: 330px;
  border-radius: 3px;
}

.container-title {
  background: #f5f9ff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px;
  width: 570px;
  height: 125px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 30px 35px;
  margin-bottom: 25px;
  margin-left: -100px;
}

.title-course {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  color: #006fe8;
}

.description-course {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 132%;
  color: #333333;
}

.container-author {
  display: flex;
  align-items: center;
  gap: 10px;
}

.container-author p {
  margin: 0;
}

section.list-course {
  background: #f5f9ff;
  box-shadow: 0px 1px 4px rgba(0, 112, 232, 0.25);
  margin: 0;
  z-index: 1;
  padding: 50px;
}

section.list-course .courses__heading {
  padding: 0px 100px 60px;
  margin: 0;
}

section.list-course h3 {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #0070e8;
}

section.list-course .courses__heading .navigation__courses p {
  margin: 0;
}

.buttons__courses button,
.detail__container .button-back {
  background: transparent;
  border: none;
  cursor: pointer;
}

.button-back-courses {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 0;
}

.button-back-courses img{
  margin-right: 10px;
}

.detail__container .button-back,
.button-back {
  margin-bottom: 40px;
}

.detail__container h2 {
  margin-top: 10px;
}

.course-item {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
  padding: 0 50px;
}

.course-item .courses_list-item {
  width: 270px;
  height: 170px;
  border-radius: 3px;
  margin-bottom: 60px;
}

.course-item .courses_list-item .course_content div{
  width: 270px !important;
  height: 170px !important;
  padding: 0 !important;
}


.course-item .courses_list-item .course_content div iframe{
  width: 270px !important;
  height: 170px !important;
}


.course-item .courses_list-item .course_content {
  width: 100%;
}

.course-item .courses_list-item .course_content p {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #333333;
  margin-top: 20px;
  cursor: pointer;
}

@media (max-width: 767px) {
  section.internal-course,
  .internal-container-course,
  .container-title {
    display: block;
  }

  .course-item {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }

  section.list-course {
    padding: 30px;
  }

  section.list-course .courses__heading {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  section.list-course h3 {
    font-size: 25px;
    text-align: center;
    justify-content: center;
  }

  section.list-course .courses__heading .navigation__courses {
    display: flex !important;
  }

  .internal-container-course {
    min-width: auto;
    margin: 0;
    padding: 45px 30px;
  }

  .container-title {
    margin: -20px 0 20px;
    width: 100%;
    height: 120px;
    position: relative;
  }

  .image-course {
    width: 100%;
    height: 160px;
  }

  .navigation__courses {
    justify-content: center;
    margin-bottom: 30px;
  }

  .course-item .courses_list-item {
    padding: 0;
    width: 100%;
    height: auto;
    margin-bottom: 25px;
  }
}
