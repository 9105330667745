:root {
  --color-bg: linear-gradient(to right, #485563, #29323c);
  --color-bg-alt: #333;
  --color-bg-active: #7bf74a;
  --color-white: #fff;
  --color-red: rgb(255, 88, 88);
}

.byme__author a {
  position: relative;
  width: 100px;
  height: 35px;
  background: var(--color-red);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

.byme__author a.active {
  background: var(--color-bg-active);
}

.byme__author a span {
  position: absolute;
  left: 18px;
  width: 10px;
  height: 15px;
  display: inline-block;
  background: var(--color-white);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: 0.5s;
}

.byme__author a:hover span {
  transform: scale(1.2) rotate(60deg) translateY(10px);
}

.byme__author a.active span {
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  border-radius: 0;
  width: 20px;
  height: 10px;
  background: transparent;
  border-left: 2px solid var(--color-white);
  border-bottom: 2px solid var(--color-white);
}

.byme__author a span::before {
  content: "";
  position: absolute;
  top: -3px;
  width: 100%;
  height: 2px;
  background: var(--color-white);
  box-shadow: 12px -2px 0 var(--color-bg-alt),
    12px -3px 0 var(--color-bg-alt) 15px -1px 0 var(--color-bg-alt) 6px -2px 0 var(--color-white);
  transition: 0.5s;
}

.byme__author a.active:hover span::before,
.byme__author a.active span::before {
  transform: scale(0);
}

.byme__author a:hover span::before {
  transform: rotate(-90deg) translateX(50%) translateY(-10px);
}

.byme__author a text {
  position: absolute;
  right: 17px;
  color: var(--color-white);
  transition: 0.5s;
}

.byme__author a:hover text,
.byme__author a.active text {
  transform: translateX(-50px) translateY(-5px) scale(0);
}

.button__delete {
  border-radius: 5px !important;
}