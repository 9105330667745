.text-muted {
  color: #d0021b !important;
}

.PhoneInput input {
  height: 38px;
  border-radius: 3px;
  border: 1px solid #cccccc;
  border-top: none;
  border-left: none;
  border-right: none;
}

.text-muted {
  color: #d0021b !important;
}

.button-sms {
  background: linear-gradient(0deg, #ddedfe, #ddedfe);
  width: 210px;
  height: 40px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #006fe8;
}

.block-text_sms {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
}

.sms-block .resend-sms {
  background: #f5f9ff;
  border-radius: 4px;
  height: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #333333;
}

.sms-block .resend-sms-blocked {
  background: #ffe8e8;
  border-radius: 4px;
  height: 40px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
}

.blocked-button-resend {
  opacity: 0.5;
}

.sms-block .blocked-text-code {
  color: red;
}

.block-form_sms {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-form_sms label {
  margin-left: -30px;
}

#recaptcha-container {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 512px) {
  .block-form_sms {
    display: block;
    justify-content: center;
    align-items: center;
    
  }

  .btn-form_sms {
    font-size: 5px;
  }

  
}